import * as React from 'react';
import './index.css';
import { widget } from '../../charting_library';
import { UDFCompatibleDatafeed } from '../../chart-datafeed/udf-compatible-datafeed'
import {SaveLoad} from '../../chart-saveload/save_load'
import {BrokerApi} from '../../chart-brokerapi/broker'
import {ChartBuySell} from '../ChartBuySell/index'
import { Charges,ChargesInstance } from '../Charges';
import {ChartWatchlists} from '../../chart-watchlists/index'
import {layoutSubServices} from "../../sub";
import {remoteConfigGetter} from "../../utils/remoteConfig";
import { messaging } from "../../utils/remoteConfig";
import { getToken,isSupported } from "firebase/messaging";
import {alertsListModel, HandleBasketModel,optionStrategyModel} from "../common/handleSocket";
import { logMessage, setLogging,setLoggingCC } from '../../utils/helpers';
import {getChartTheme} from "../DashBoard/WalkThrough"
import {customIndicator} from "./custom_studies";
import {
	getAddFundsIcon,
	getBasketIcon,
	getChangeTheamIcon,
	getLogOutIcon,
	getUerSettingIcon,
	getOptionStrategyIcon,
	getFeedbackIcon,
	getReferEarnIcon,
	getAlertsIcon
} from "../../comman/iconMaker";
import {DOCK_BASE_URL} from "../../utils/config";
// import { createOrderDialog } from '../../chart-customdialog';
// import {createOrderDialog,
// 	showOrderDialog,
// 	createPositionDialog,
// 	showPositionDialog,
// 	createOptionChainDialog} from '../../chart-customdialog/index.ts'
function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function createDeferredPromise() {
    var resolveFn;
    var rejectFn;

    var promise = new Promise(function(resolve, reject) {
        resolveFn = resolve;
        rejectFn = reject;
    });

    return {
        promise: promise,
        reject: rejectFn,
        resolve: resolveFn,
    };
}

var orderResult = createDeferredPromise();
var positionResult = createDeferredPromise();
var optionChainDialog = createDeferredPromise();

var customOrderDialog = null;
var customPositionDialog = null;
var optionChainDialog = null ;


var onOrderResultCallback = function(result) {
    orderResult.resolve(result);
    if (customOrderDialog !== null) {
        customOrderDialog.style.visibility = 'hidden';
        customOrderDialog.removeAttribute('data-symbol');
    }
};

var onPositionResultCallback = function(result) {
    positionResult.resolve(result);
    if (customPositionDialog !== null) {
        customPositionDialog.style.display = 'none';
        customPositionDialog.removeAttribute('data-symbol');
    }
};

var onOptionChainResultCallback = function(result){
    onOptionChainResultCallback.resolve(result); 
    if(optionChainDialog !== null){
        optionChainDialog.style.visibility = 'hidden';
    }
}


window.isAuthenticated = true 
deleteLocalWatchlists();

function deleteLocalWatchlists(){
    for (var i in localStorage) {
        if (localStorage.hasOwnProperty(i)) {
            if (i.match('tradingview.savedwatch')) {
                localStorage.removeItem(i)
            }
        }
    }
}

export class TVChartContainer extends React.PureComponent {
	
	tvWidget = null;

	 auth_token = null
	 ssoToken  = null
	 datafeed = null
	 user_id = null

	 lsubscription = undefined;

	

	constructor(props) {
		super(props);
		this.auth_token = this.props.data.access_token;
		this.ssoToken = this.props.data.ssoToken;
		this.user_id = this.props.data.user_id;
		this.logout_function = this.props.logoutFunction;
		this.option_chain_function = this.props.OpenOptionModel;
		this.new_option_chain_function = this.props.OpenNewOptionModel;
		this.new_ggt_function = this.props.OpenNewGGTModel;
		this.open_future_chain_function = this.props.OpenFutureModel;
		this.inhouseUrl = remoteConfigGetter("vortex_url_v2").asString()
		// this.inhouseUrl = "http://localhost:5100/"
		this.datafeed = new UDFCompatibleDatafeed(remoteConfigGetter("live_base_url").asString(),this.auth_token,this.inhouseUrl,this.logout_function)
		this.brokerClass = null
		this.user_profile = this.props.data

		this.state = {renderBuySell: false}
		this.ref = React.createRef();
		this.odinUrl = ""
		this.ocToken = this.props.data.others.ocToken
	}
	// CreateOrderDialogContext = React.createContext(this.createOrderDialog);

	componentDidMount = () =>{
		document.addEventListener('freeze', (event) => {
			// logMessage("page is about to be frozen")
			this.brokerClass.socket_disconnect()
			this.datafeed.closeFeedTemporarily();
		});
		  
		document.addEventListener('resume', (event) => {
			// logMessage("frozen page resumed")
			this.brokerClass.socket_initialize(); 
			this.datafeed.connectFeed(this.ocToken,this.user_id);
		});   
		var logging = (process.env.REACT_APP_LOGGING != "false")
		var createOrderDialogFunc = this.createOrderDialog
		this.datafeed.connectFeed(this.ocToken,this.user_id);
		setLogging(logging)
		setLoggingCC(this.props.data.user_id)
		const widgetOptions = {
			custom_css_url: 'css/style.css',
			custom_font_family: "HK Grotesk",
			fullscreen: true,
			debug: false, // uncomment this line to see Library errors and warnings in the console
			// symbol: 'NSE:BANKNIFTY-EQ',
			// interval: (getParameterByName('interval') || '15'),
			timezone: "Asia/Kolkata",
			container: this.ref.current,
			// timeframe: (getParameterByName('timeframe') || '5D'),
			timeframe: '1D',
			datafeed: this.datafeed,
			library_path: "../charting_library/",
			locale: 'en',
			allow_symbol_change: true,
			// disabled_features: [],
			enabled_features: ['tick_resolution','seconds_resolution','dom_widget','hotlists','study_templates','header_compare','move_logo_to_main_pane','show_symbol_logos','show_symbol_logo_in_legend','show_symbol_logo_in_account_manager','show_exchange_logos','custom_resolutions'],
			disabled_features: ['order_panel'],
			// enabled_features: [''],
			charts_storage_url: 'https://saveload.tradingview.com',
			hide_symbol_toolbar: true,
			charts_storage_api_version: "1.1",
			client_id: 'rupeezy.in',
			user_id: this.user_id,
			hotlists: true,
			calendar: true,
			details: true , 
			symbol_search_request_delay: 1000,
			load_last_chart: true,
			auto_save_delay: 5,
			custom_indicators_getter: function(PineJS){
				return customIndicator.getIndicatorTemplates(PineJS)
			},
			widgetbar: {
				details: true,
				news: true,
				watchlist: true,
				datawindow: true,
				watchlist_settings: {
					default_symbols: []
				}
			},
			// datafeed.getNews(symbol,callback)
			news_provider: (symbol, callback) => {
				this.datafeed.getNews(symbol,callback)
			},
			// rss_news_feed: {
			//     "default": [ {
			//         url: "http://localhost:5100/news?symbol={SYMBOL}",\
			//         name: "Yahoo Finance"
			//     } ]
			// },
			broker_factory: (host) => {
				this.brokerClass =  window.brokerClass = new BrokerApi(host, this.datafeed,process.env.REACT_APP_VORTEX_URL,this.auth_token,process.env.REACT_APP_VORTEX_KEY,this.inhouseUrl,this.user_profile);
				// customOrderDialog = window.customOrderDialogue = createOrderDialog(brokerClass, onOrderResultCallback, datafeed);
				// optionChainDialog = window.optionChainDialog = createOptionChainDialog(brokerClass,onOptionChainResultCallback,datafeed); 
				// $( "#custom-order-dialog-id" ).draggable();
				// customPositionDialog = window.customPositionDialogue  = createPositionDialog(brokerClass, onPositionResultCallback,datafeed);
				return brokerClass; 
			},
			broker_config: {
				configFlags: {
					supportBottomWidget: true,
					// supportNativeReversePosition: true,
					// supportReducePosition: true,
					supportClosePosition: true,
					supportPLUpdate: false,
					supportLevel2Data: true,
					// showQuantityInsteadOfAmount: true,
					supportEditAmount: true,
					supportPartialClosePosition: true,
					supportModifyOrderPrice: true,
					supportMargin:true,
					supportReversePosition: true,
					supportNativeReversePosition: true
				},
				durations: [
					{ name: 'DAY', value: 'DAY',supportedOrderTypes: [1,2,3,4]},
					{ name: 'IOC', value: 'IOC' },
				],

				customUI: {
					
					showOrderDialog: function(order, focus) {
						console.log(order)
						ChartBuySell.instance?.ShowOrderDialog(order,onOrderResultCallback);
						return orderResult.promise;
					},
					// showPositionDialog: function(position, brackets, focus) {
					// 	BuySellWIndowInstance.ShowOrderDialog(position);
					// 	// NOTE: This promise should be resolved with true/false only
					// 	return positionResult.promise;
					// },
					// showClosePositionDialog: function(position, focus) {
					// 	BuySellWIndowInstance.ShowOrderDialog(position);
					// 	// NOTE: This promise should be resolved with true/false only
					// 	return positionResult.promise;
					// }
				
				},
			},
			save_load_adapter: (new SaveLoad(this.inhouseUrl,this.auth_token)),
			
		};

		const tvWidgetChart = new widget(widgetOptions);
		this.tvWidget = tvWidgetChart; 
		window.tvWidget = tvWidgetChart
		var logout_function = this.logout_function
		var optionModel = this.option_chain_function
		var newOptionModel = this.new_option_chain_function
		var newGGTModel = this.new_ggt_function
		var futureChainModel =this.open_future_chain_function

		var datafeed = this.datafeed
		tvWidgetChart.subscribe('onAutoSaveNeeded', (_)=>{
			tvWidgetChart.saveChartToServer()
		})

		function OpenBasketModel() {
			HandleBasketModel(true)
		}

		function OpenOptionStrategy(){
			optionStrategyModel(true)
		}

		function openAlertsForm(){
			alertsListModel(true)
		}

		function openFeedbackForm(){
			window.open('https://forms.gle/erJD5a6GNQL8JqiV7', '_blank');
		}

		function openMenuCallBack(key) {
			optionModel(key)
		}
		function callNewOptionChain(key){
			newOptionModel(key)
		}

		function callNewGGT(key){
			newGGTModel(key)
		}

		function callFutureChain(key){
			futureChainModel(key)
		}

		function AddThemeClassToBodyReact(name){
			if(name){
				getChartTheme(name.toString().toLowerCase())
				let element = document.getElementById("themeManage");
				name.toString().toLowerCase() === 'dark' ?  element.classList.add("DarkTheme") :	element.classList.remove("DarkTheme");
			}
		}

		tvWidgetChart.onChartReady(() => {
			this.brokerClass.socket_initialize(); 
			tvWidgetChart.headerReady().then(() => {
				tvWidgetChart.getSavedCharts((chartRecords)=>{
					tvWidgetChart.loadChartFromServer(chartRecords[chartRecords.length-1])
				})
				if (isSupported() && messaging != null && Notification.permission != 'denied' ){
					Notification.requestPermission().then(async (permission) =>{
						try {
							const token = await getToken(messaging,{vapidKey: remoteConfigGetter("vapidkey").asString()})
							if(token) {
								this.brokerClass.subscribeFcmByToken(token)
								return token;
								
							}
							else {
								console.log('No Instance ID token available. Request permission to generate one.');
							}
						} catch (error) {
							const token = await getToken(messaging,{vapidKey: remoteConfigGetter("vapidkey").asString()})
							if(token) {
								this.brokerClass.subscribeFcmByToken(token)
								return token;
							}
							else {
								console.log('No Instance ID token available. Request permission to generate one.');
							}
						}
						
					}).catch((permission)=>{
						console.log(permission)
					})
				}
				
				var button1 = tvWidgetChart.createButton();
				button1.setAttribute('title', 'Add Funds');
				button1.setAttribute('id','add_funds')
				button1.addEventListener('click', () => { window.GtmPusher({
					event: 'tv-add-funds-clicked'
				});
				let doc_url_pay_in = `${DOCK_BASE_URL}dashboard?path=pay-in&sso_auth_token=${this.ssoToken}&version=v2`
				window.open(doc_url_pay_in, '_blank').focus() });
				// button1.textContent = 'Add Funds';
				button1.appendChild(getAddFundsIcon())
				button1.style.pointerEvents = 'all'
		
				var button2 = tvWidgetChart.createButton();
				button2.setAttribute('title', 'Manage Account');
				button2.setAttribute('id','manage_account')
				button2.addEventListener('click', () => { window.GtmPusher({
					event: 'tv-manage-account-clicked'
				})
				let doc_url_profile = `${DOCK_BASE_URL}dashboard?path=profile&sso_auth_token=${this.ssoToken}&version=v2`
				window.open(doc_url_profile, '_blank').focus() });
			// button2.textContent = 'Manage Account';
				button2.appendChild(getUerSettingIcon())
				button2.style.pointerEvents = 'all'

				// var referBtn = tvWidget.createButton();
				// referBtn.setAttribute('title', 'Refer & earn 20% brokerage share');
				// referBtn.setAttribute('id','refer_earn')
				// referBtn.addEventListener('click', () => { window.GtmPusher({
				// 	event: 'tv-refer-link-clicked'
				// })
				// let doc_url_profile = `${DOCK_BASE_URL}dashboard?path=profile/refer-earn-brokerage&sso_auth_token=${this.ssoToken}&version=v2`
				// window.open(doc_url_profile, '_blank').focus() });
				// // button2.textContent = 'Manage Account';
				// referBtn.appendChild(getReferEarnIcon())
				// referBtn.style.pointerEvents = 'all'

				var button3 = tvWidgetChart.createButton();
				button3.setAttribute('title', 'Change Theme');
				button3.setAttribute('id','change_theme')
				button3.addEventListener('click', function() { 
					var theme = tvWidgetChart.getTheme()
					
					if (theme.toLowerCase() == "light"){
						brokerClass.setTheme('Dark').then((_)=>{
							tvWidgetChart.changeTheme('dark').then((_)=>{
								AddThemeClassToBodyReact('dark')
							})	
						})
						
					}else{
						brokerClass.setTheme('Light').then((_)=>{
							tvWidgetChart.changeTheme('light').then((_)=>{
								AddThemeClassToBodyReact('light')
							})	
						})
					}
				});
				// button3.textContent = 'Change Theme';
				button3.appendChild(getChangeTheamIcon())
				button3.style.pointerEvents = 'all'


				var button5 = tvWidgetChart.createButton();
				button5.setAttribute('title', 'Basket Order');
				button5.setAttribute('id','basket_order')
				button5.addEventListener('click', function() {
					OpenBasketModel()
				});
				button5.appendChild(getBasketIcon())
				button5.style.pointerEvents = 'all'
				

				var button5 = tvWidgetChart.createButton();
				button5.setAttribute('title', 'Strategy');
				button5.setAttribute('id','strategy')
				button5.addEventListener('click', function() {
					OpenOptionStrategy()
				});
				button5.appendChild(getOptionStrategyIcon())
				button5.style.pointerEvents = 'all'

				var button5 = tvWidgetChart.createButton();
				button5.setAttribute('title', 'Feedback');
				button5.setAttribute('id','feedback')
				button5.addEventListener('click', function() {
					openFeedbackForm()
				});
				button5.appendChild(getFeedbackIcon())
				button5.style.pointerEvents = 'all'

				// Adding Button For Alerts
				var button5 = tvWidgetChart.createButton();
				button5.setAttribute('title', 'Alerts');
				button5.setAttribute('id','alerts-create')
				button5.addEventListener('click', function() {
					openAlertsForm()
				});
				button5.appendChild(getAlertsIcon())
				button5.style.pointerEvents = 'all'

				var button4 = tvWidgetChart.createButton();
				button4.setAttribute('title', 'Logout');
				button4.setAttribute('id','logout')
				button4.addEventListener('click', function() {
					deleteLocalWatchlists();
					 logout_function()
					// datafeed.closeFeed()
				});
				// button4.textContent = 'Logout';
				button4.appendChild(getLogOutIcon())
				button4.style.pointerEvents = 'all'

			});

			tvWidgetChart.watchList().then((watchlistApi)=>{
				var chartWatchlist = new ChartWatchlists(watchlistApi)
					this.brokerClass.watchlists()
                    .then((watchlists)=>{
                        watchlists.forEach((watchlist,i)=> {
							chartWatchlist.addWatchlist(watchlist)
                        })
                    })

					function updateWatchlist(listId,name,name2){
						chartWatchlist.updateWatchlist(listId)
					}
					function renameWatchList(listId,oldName,newName){
						window.GtmPusher({
							event: 'tv-watchlist-renamed'
						})
						chartWatchlist.renameWatchlist(listId,oldName,newName)
					}
					function createWatchlist(listId, name,symbols){
						window.GtmPusher({
							event: 'tv-watchlist-created'
						})
						chartWatchlist.createWatchlist(listId,name,symbols)
					}
					function deleteWatchlist(listId){
						window.GtmPusher({
							event: 'tv-watchlist-deleted'
						})
						chartWatchlist.deleteWatchlist(listId)
					}
					watchlistApi.onListChanged().subscribe(null,updateWatchlist,false)
					watchlistApi.onListRenamed().subscribe(null,renameWatchList,false)
					watchlistApi.onListAdded().subscribe(null,createWatchlist,false)
					watchlistApi.onListRemoved().subscribe(null,deleteWatchlist,false)
			})

			this.brokerClass._getPreferences().then((response)=>{
				tvWidgetChart.changeTheme(response.chartTheme.toLowerCase())
				AddThemeClassToBodyReact(response.chartTheme.toLowerCase())
			})

			// tvWidget.onContextMenu(function(unixtime, price) {
			// 	return [ {
			// 		position: "bottom",
			// 		text: "Option Chain",
			// 		click: ()  => {
			// 			openMenuCallBack(tvWidget.activeChart().symbol())
			// 		}
			// 	}];
			// })
			tvWidgetChart.onContextMenu(function(unixtime, price) {
				return [ {
					position: "top",
					text: "Option Chain",
					click: ()  => {
						callNewOptionChain(tvWidgetChart.activeChart().symbol())
					}
				}];
			})
			// tvWidgetChart.onContextMenu(function(unixtime, price) {
			// 	return [ {
			// 		position: "top",
			// 		text: "Create GGT",
			// 		click: ()  => {
			// 			callNewGGT(tvWidgetChart.activeChart().symbol())
			// 		}
			// 	}];
			// })
			tvWidgetChart.onContextMenu(function(unixtime, price) {
				return [ {
					position: "top",
					text: "Futures Chain",
					click: ()  => {
						callFutureChain(tvWidgetChart.activeChart().symbol())
					}
				}];
			})
		});

		this.SetLayoutSub();
	}

	SetLayoutSub = () => {
		this.lsubscription = layoutSubServices.getSub().subscribe(values => {
		  if (values !== null) this.changeLayout(values)
		})
	}

	setNewSymbol(index,symbol){
		setTimeout(() => {
			this.tvWidget.chart(index).setSymbol(symbol)
		}, 1000);
	}

	changeLayout(values){
      // console.log(values)
		const chartcount = this.tvWidget.chartsCount();
        const currentLayout = this.tvWidget.layout();


		if(chartcount === 1){
			this.tvWidget.setLayout('2h')
			this.setNewSymbol(1,values.buySellSymbol)
		}

		if(chartcount === 2){
			if (currentLayout === '2h'){
				this.tvWidget.setLayout('3h')
			}else  {
				this.tvWidget.setLayout('3v')
			}
			this.setNewSymbol(2,values.buySellSymbol)
		}

		if(chartcount === 3){
			if (currentLayout === '3h'){
				this.tvWidget.setLayout('4h')
			}else if (currentLayout === '3v') {
				this.tvWidget.setLayout('4v')
			}else if ((currentLayout === '2-1') || (currentLayout === '1-2')) {
				this.tvWidget.setLayout('2-2')
			}else {
				this.tvWidget.setLayout('4')
			}
			this.setNewSymbol(3,values.buySellSymbol)
		}

		if(chartcount === 4){
			if ((currentLayout === '4h') || (currentLayout === '4v') || (currentLayout === '4') || (currentLayout === '4s') || (currentLayout === '2-2')){
				this.tvWidget.setLayout('5s')
			}else if (currentLayout === '1-3') {
				this.tvWidget.setLayout('1-4')
			}else {
				this.tvWidget.setLayout('5s')
			}
			this.setNewSymbol(4,values.buySellSymbol)
		}

		if(chartcount === 5){
			if (currentLayout === '5s'){
				this.tvWidget.setLayout('6c')
			}else {
				this.tvWidget.setLayout('6c')
			}
			this.setNewSymbol(5,values.buySellSymbol)
		}

		if(chartcount === 6){
			if (currentLayout === '6c'){
				this.tvWidget.setLayout('8c')
			}else {
				this.tvWidget.setLayout('8c')
			}
			this.setNewSymbol(6,values.buySellSymbol)
		}

		if(chartcount === 7){
			if (currentLayout === '6c'){
				this.tvWidget.setLayout('8c')
			}else {
				this.tvWidget.setLayout('8c')
			}
			this.setNewSymbol(7,values.buySellSymbol)
		}

		if(chartcount === 8){
			if (currentLayout === '8c'){
				this.tvWidget.setLayout('8c')
			}else {
				this.tvWidget.setLayout('8c')
			}
			this.setNewSymbol(7,values.buySellSymbol)
		}


		// this.tvWidget.activeChart().setSymbol(values.buySellSymbol)

	}


    componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
		if (this.datafeed != null){
			this.datafeed.closeFeed();
		}
		if (this.brokerClass != null){
			this.brokerClass.socket_disconnect()
		}
		this.lsubscription?.unsubscribe();   
	}



	render() {
		return (
			<div
				ref={ this.ref }
				className={ 'TVChartContainer' }
			/>
		);
	}
}

//make tv widget available to option chain
// tvWidget.activeChart.setSymbol(symbol)
