import React, { useState, useEffect, useRef } from "react";
import "./optionStrategyModel.scss";
import { ReactComponent as CloserIcon } from "../../assets/images/close-square2.svg";
import CreateAlerts from "./CreateAlerts";
import AllAlerts from "./ConditionalHeader/AllAlerts";
import CraeteOrEdit from "./ConditionalHeader/CraeteOrEdit";
import AlertSearchList from "./CreateAlerts/AlertSearchList";
import useAlerts, { AlertData } from "../../hooks/api/get/getAlerts";
import { arraySelector } from "../../helper/alert/arraySelector";
import useDeleteAlert from "../../hooks/api/delete/deleteAlerts";
import EditAlertForm from "./EditAlertForm";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { toggleAlertsListModel } from "../../slices/alertSlices";
import BrokerApi from "../../chart-brokerapi/broker";
import "./optionStrategyModel.scss";

export type Alerts = "all-alerts" | "create-alerts" | "edit-alerts";
export type AlertsType = "active" | "inactive" | "all";

const AlertTools: React.FC = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<Alerts>("all-alerts");
  const [currentSelectedAlerts, setCurrentSelectedAlerts] =
    useState<AlertsType>("active");
  const [editAlert, setEditAlert] = useState<Omit<AlertData, "status"> | null>(
    null
  );

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const draggableRef = useRef<HTMLDivElement>(null);
  const isInitialMount = useRef(true);

  const { activeAlerts, inactiveAlerts, refetch, error, loading } = useAlerts(); // Will Give you Alerts Active and Inactive
  const {
    deleteAlert,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteAlert();

  const [arrayToShow, setArrayToShow] = useState<AlertData[]>([]);

  // Initial setup only - runs once
  useEffect(() => {
    const activeLength = activeAlerts?.length ?? 0;
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (activeLength === 0 && activeLength === null) {
        setCurrentTab("create-alerts");
      } else {
        setCurrentTab("all-alerts");
        setCurrentSelectedAlerts("active");
      }
    }
  }, [activeAlerts]);

  // Filter alerts based on the selected alert type
  useEffect(() => {
    if (activeAlerts && inactiveAlerts) {
      const filteredAlerts = arraySelector(
        currentSelectedAlerts,
        activeAlerts,
        inactiveAlerts
      );
      setArrayToShow(filteredAlerts);
    }
  }, [currentSelectedAlerts, activeAlerts, inactiveAlerts]);

  useEffect(() => {
    if (activeAlerts && inactiveAlerts) {
      const filteredAlerts = arraySelector(
        currentSelectedAlerts,
        activeAlerts,
        inactiveAlerts
      );
      refetch();
      setArrayToShow(filteredAlerts);
    }
  }, [currentTab]);

  if (error || deleteError) {
    return <div>Error: {error || deleteError}</div>;
  }

  const handleToggleAlertStatus = (status: AlertsType) => {
    const activeLength = activeAlerts?.length ?? 0;
    const inactiveLength = inactiveAlerts?.length ?? 0;

    if (
      (status === "active" && activeLength > 0) ||
      (status === "inactive" && inactiveLength > 0) ||
      status === "all"
    ) {
      setCurrentSelectedAlerts(status);
    }
  };

  const handleEditAlert = (alert: Omit<AlertData, "status">) => {
    setEditAlert(alert);
    setCurrentTab("edit-alerts");
  };

  const handleAlertsTab = (alerts: Alerts) => {
    setCurrentTab(alerts);
  };

  const onDeleteAlert = async (id: number) => {
    try {
      const isDeleted = await deleteAlert(id);

      if (isDeleted) {
        await refetch({
          forceRefresh: true,
          onSuccess: () => {
            const activeLength = activeAlerts?.length ?? 0;
            const inactiveLength = inactiveAlerts?.length ?? 0;

            if (currentSelectedAlerts === "inactive" && inactiveLength <= 1) {
              if (activeLength > 0) {
                setCurrentSelectedAlerts("active");
              } else {
                setCurrentTab("create-alerts");
              }
            } else if (
              currentSelectedAlerts === "active" &&
              activeLength <= 1
            ) {
              setCurrentTab("create-alerts");
            }

            // toast.success("Alert deleted successfully");
            BrokerApi.instance?._host.showNotification(
              "Alert deleted successfully",
              1
            );
          },
          onError: () => {
            BrokerApi.instance?._host.showNotification(
              "Failed to delete alert",
              0
            );
          },
        });
      }
    } catch (error) {
      console.error("Error deleting alert:", error);
    }
  };

  const activeCount = activeAlerts?.length ?? 0;
  const inactiveCount = inactiveAlerts?.length ?? 0;

  const renderHeaderContent = () => {
    switch (currentTab) {
      case "all-alerts":
        return (
          <AllAlerts
            currentSelectedAlerts={currentSelectedAlerts}
            toggleAlertStatus={handleToggleAlertStatus}
            activeNumberOfAlerts={activeCount}
            inactiveAlerts={inactiveCount}
          />
        );
      case "create-alerts":
      case "edit-alerts":
        return <CraeteOrEdit alerts={currentTab} />;
      default:
        return null;
    }
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case "all-alerts":
        return (
          <AlertSearchList
            currentAlerts={currentSelectedAlerts}
            onEditAlert={handleEditAlert}
            onDeleteAlert={onDeleteAlert}
            handleAlertsTab={handleAlertsTab}
            finalData={arrayToShow}
            numberOfAlerts={
              (activeAlerts?.length ?? 0) + (inactiveAlerts?.length ?? 0)
            }
          />
        );
      case "create-alerts":
        return <CreateAlerts handleAlertsTab={handleAlertsTab} />;
      case "edit-alerts":
        return (
          <EditAlertForm probs={editAlert!} handleAlertsTab={handleAlertsTab} />
        );
      default:
        return null;
    }
  };

  const onModelClose = () => {
    dispatch(toggleAlertsListModel(false));
  };

  const handleDragStop = (_e: any, data: { x: number; y: number }) => {
    setPosition({ x: data.x, y: data.y });
  };

  return (
    <React.Fragment>
      <Draggable
        handle=".handle_b"
        bounds="parent"
        allowAnyClick={true}
        position={position}
        onStop={handleDragStop}
        cancel=".no-drag" 
      >
        <div
          ref={draggableRef}
          className="custom-order-dialog_basket currentBg draggable-border"
        >
          <div className="container optionStartegyHead handle_b">
            {renderHeaderContent()}
            <div className="flex c-pointer no-drag" onClick={onModelClose}>
              <CloserIcon />
            </div>
          </div>
          {renderTabContent()}
        </div>
      </Draggable>
    </React.Fragment>
  );
};

export default AlertTools;
