import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector,shallowEqual} from "react-redux";
import BasketketHeader from '../Header/bacsketHeader';
import {currentUser} from "../../../slices/authSlice";
import flowBackEndServices2 from "../../../services/flowBackendServices";
import { setCurrentBasketState } from '../../../slices/basketOrderSlice';
import Graph from './Graph';
import CustomSpinner from "../../OptionStrategy/CustomSpinner"
import {ReactComponent as Contracticon} from "../../../assets/icons/ContractIcon.svg"
import {ReactComponent as Expandicon} from "../../../assets/icons/ExpansionIcon.svg"
import BasketAnalyseOptions from './BasketAnalyseOptions';
import {PayOffData,Payoff} from "../../../models/strategy"



const BasketAnalyst = () =>{
    const dispatch = useDispatch()
    const [value, setValue] = useState(null);
    const user = useSelector(currentUser);  
    const [payOffGraphData,setPayOffGraphData]=useState<Payoff[]>([])
    const [payOffData,setPayOffData]=useState<PayOffData | null>(null)
    const [lotSize,setLotSize] = useState<number | null>(null)
    const [undividedPayOffData,setUndividedPayOffData]=useState<Payoff[] | null>(null)
    const [expandedGraph,setExpandedGraph]=useState(false)
    const [isLoading,setIsLoading]=useState(true)
    const [scripsList,setScrips]=useState<string[] | null>(null)
    const [diffScripsLegs,setDiffScripsLegs]=useState<any>([])
    const [currentSymbol,setCurrentSymbol]=useState<string | null>(null)

    const public_basket_data = useSelector(((state:any) => state.basketOrder.basket_old_id_data), shallowEqual);
    const public_basket_margin = useSelector(((state:any) => state.basketOrder.public_basket_margin), shallowEqual);
    const current_basket = useSelector(((state:any) => state.basketOrder.current_basket), shallowEqual);

    useEffect(()=>{
        let list:string[] = []
        if(current_basket){
            const groupedData = current_basket.param.basketOrders.reduce((result:any, item:any) => {
                const { symbol } = item.stockData;
                const { token, ...rest } = item;
                if(item.marketSegmentId==2){
                    if (!list.includes(symbol)) {
                        list.push(symbol);
                      }
                    if (!result[symbol]) {
                        result[symbol] = [];
                      }
                      let json ={
                        token:rest.stockData.scripToken,
                        action:rest.buySell==1?"BUY":"SELL",
                        quantity: rest.quantity * rest.stockData.lotSize
                      }
                      result[symbol].push(json);
                }
                return result;
              }, {});
              setScrips(list)
              setDiffScripsLegs(groupedData)
        }
    },[current_basket])

    useEffect(()=>{
        if(diffScripsLegs && scripsList) {
            setCurrentSymbol(scripsList[0])
        }
    },[diffScripsLegs,scripsList])


    useEffect(()=>{
        if(currentSymbol) getPayOff(false,0,currentSymbol,diffScripsLegs[currentSymbol])
    },[currentSymbol])

    useEffect(()=>{
        if(payOffGraphData) getAnalyseGraph()
    },[payOffGraphData])


    function getPayOff(hasInputExpiry:boolean,inpDaysToExpiry:number,symbol:string,legs:any){
        setIsLoading(true)
        var data 
        // const data = {stockType:"NFO",symbol: symbol,hasInpExp: hasInputExpiry,inpDaysToExpiry:parseInt(inpDaysToExpiry, 10),legs:legs}
        if (hasInputExpiry){
            data = {exchange:"NSE_FO",symbol: symbol,days_to_expiry:Number(inpDaysToExpiry),legs: legs}
       }else{
            data = {exchange:"NSE_FO",symbol: symbol,legs: legs}
       }
        flowBackEndServices2.getPayOff(data,String(user?.access_token)).then(value => {
            return value.data
        }).then((data)=>{
            const response:PayOffData=data.data
            if(response.payoffs!=null){
                setPayOffData(response)
                setPayOffGraphData(response.payoffs)
                // formatPayOffData(data.data.payoffs,data.data.leg_greeks[0]['lot_size'])
                setUndividedPayOffData(response.payoffs)
                setLotSize(response.leg_greeks[0]['lot_size'])
                setIsLoading(false)
                setValue(inpDaysToExpiry==0 && hasInputExpiry==false?data.data.min_days_to_expiry:inpDaysToExpiry)
            }
        }).catch(reason => {
            console.log("Error in pay off data",reason);
        })
        setIsLoading(false)
    }

    function getAnalyseGraph(){
        return(
            <Graph payOffData={payOffGraphData} expanded={expandedGraph}/>
        )
    }

    const GraphExpansion =()=>{
        let value = !expandedGraph
        setExpandedGraph(value)
    }

    function handleChange(event:any) {
        setValue(event.target.value);
        getPayOff(true,event.target.value,currentSymbol as string,diffScripsLegs[currentSymbol as string])
      }


      function getPnLnGreeks(){
        return(
            <React.Fragment>
                <div style={{"display":"flex","flexDirection":"row","marginTop":"5px","justifyContent":"space-between"}}>
                <div style={{"textAlign":"left"}}>
                    <div className='detailshead'>Max profit</div>
                    <div className='profitValue'>{payOffData?.infinite_profit?'Unlimited':(payOffData?.max_profit as number).toFixed(2)}</div>
                </div>
                <div style={{"textAlign":"right"}}>
                    <div className='detailshead'>Max loss</div>
                    <div className='lossValue'>{payOffData?.infinite_loss?'Unlimited':(-1*(payOffData?.max_loss as number)).toFixed(2)}</div>
                </div>
            </div>
            <div style={{"display":"flex","flexDirection":"row","marginTop":"15px","justifyContent":"space-between"}}>
                <div style={{"textAlign":"left"}}>
                    <div className='detailshead'>Delta</div>
                    <div className='breakevensValue'>{((payOffData?.combined_greeks.delta) as number).toFixed(2)}</div>
                </div>
                <div style={{"textAlign":"right"}}>
                    <div className='detailshead'>Gamma</div>
                    <div className='breakevensValue'>{(((payOffData?.combined_greeks.gamma) as number)).toFixed(4)}</div>
                </div>
                <div style={{"textAlign":"right"}}>
                    <div className='detailshead'>Theta</div>
                    <div className='breakevensValue'>{((payOffData?.combined_greeks.theta) as number).toFixed(2)}</div>
                </div>
                <div style={{"textAlign":"right"}}>
                    <div className='detailshead'>Vega</div>
                    <div className='breakevensValue'>{((payOffData?.combined_greeks.vega) as number).toFixed(2)}</div>
                </div>
            </div>
        </React.Fragment>
        )
    }

    const getSlider = () =>{


        return(
            <React.Fragment>
                <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                    <div className='dte'>Days To Expiry</div>
                    <div className='daystoexpire'>{value}</div>
                </div>
                <div >
                    <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                        <div className='dte'>{payOffData?.min_days_to_expiry}</div>
                        <div className='dte'>0</div>
                    </div>
                        <input style={{"width":"100%","direction": "rtl"}}
                        type="range"
                        min="0"
                        max={payOffData?.min_days_to_expiry || 0}
                        value={value ?? 0}
                        title={String(value) ?? ""}
                        onChange={handleChange}
                        step={1}
                        />
                </div>
            </React.Fragment>
        )
    }

    const getOptionsSubcribedData = () =>{
        return payOffData?.leg_greeks.map((data,index)=>{
            return(
                <BasketAnalyseOptions key={index} options={data} currentindex={index}/>
            )
        })
    }

    const goback = () =>{
        if(current_basket.url=="publicBasketAnalyse"){
            let state =  {id: 4,param: null,url:'sharedBasketDetails'};
            dispatch(setCurrentBasketState(state))
        }else{
            let state =  {id: 3,param: current_basket.param,url:'basketDetails'};
            dispatch(setCurrentBasketState(state))
        }
    }

    const handleSelectChange = (e:any) =>{
        setCurrentSymbol(e.target.value)
    }
    function formatBreakEvens(data:number[]){
        if(data){
            const modifiedArray = data.map((element) => {
                return (element).toFixed(2);
              });
            return modifiedArray.join(',')
        }else{
            return ''
        }
    }
    const getScripsDropdown = () =>{
        return(
                <form className="d-flex flex-row w-100 justify-content-start mt-1">
                    <div className="custom-select-wrapper">
                        <select className={`form-select form-select-sm scrip_drop`}  onChange={handleSelectChange}>
                        {
                            scripsList?.map(d => {
                                        return(
                                            <option key={d} value={d} className='option'>{d}</option>
                                        )
                                    })
                                }
                        </select>
                        <div style={{"position":"absolute","top":"103px","left":"129px"}}><i className="arrow down"></i></div>
                    </div>
                </form>
        )
    }

    const getBottom = ()=>{
        return (
            <div className="bottom_execute_bar currentBg2">
            <div className=" my-3 w-25 mr-4">
            </div>
            <div className='d-flex flex-row'>
              <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                  <div className="text_grey currentText">Initial Margin</div>
                  <div className="heading2">{public_basket_margin?public_basket_margin.initial_margin.toFixed(2):0}</div>
              </div>
              <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                <div className="text_grey currentText">Final Margin</div>
                <div className="heading2">{public_basket_margin?public_basket_margin.required_margin.toFixed(2):0}</div>
              </div>
            </div>

            </div>
        )
    }
    return(
        <React.Fragment>
            <BasketketHeader>
                <span onClick={goback} className="heading2 no-drag">  <i  className="fa fa-arrow-left c-pointer" aria-hidden="true"/>&nbsp; Analyse</span>
            </BasketketHeader>
            {isLoading && (
                <CustomSpinner />
            )}
            {expandedGraph==false && !isLoading &&  (<React.Fragment>
                    <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"textAlign":"left","paddingLeft":"20px","paddingTop":"13px"}}>
                        <div className='underlying'>Underlying</div>
                        <div className='stratName'>
                            {getScripsDropdown()}
                        </div>
                        <div className='pt-4' style={{"position":"relative","left":"-8px"}}>
                        {/* <HeaderSubscribedData token={current_symbol_details.nToken} segmentId={current_symbol_details.nMarketSegmentId} symbol={current_symbol_details.sSymbol} description={current_symbol_details.sSecurityDesc!=""?current_symbol_details.sSecurityDesc:current_symbol_details.sSymbol+" Index"} exchange={current_symbol_details.sExchange} location={"analysis"}/> */}
                            <div className='c-pointer' style={{"position":"absolute","top":"-3%","right":"0px"}} onClick={()=>GraphExpansion()}><Contracticon/></div>
                            {payOffGraphData && getAnalyseGraph()}
                        </div>
                        {payOffData && getSlider()}
                    </div>
                    <div style={{"textAlign":"left","paddingLeft":"24px","paddingTop":"24px"}}>
                        <div className='basketAnalyseBreakEven p-4'>
                            <div className='detailshead'>Breakeven points</div>
                            <div className='breakevensValue bdr' style={{"paddingBottom":"5px"}}>{payOffData && payOffData.breakevens && formatBreakEvens(payOffData.breakevens)}</div>
                            {payOffData && getPnLnGreeks()}
                        </div>
                        <div className='mt-4 basketOptionsLeg px-4'>
                            <div style={{"height":"90px","overflowY":"scroll"}}>
                                <div className='mt-4'>
                                    {payOffData && getOptionsSubcribedData()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {getBottom()}
        </React.Fragment>)}

        {expandedGraph==true && !isLoading && (
                <React.Fragment>
                    <div style={{"textAlign":"left","paddingLeft":"24px","paddingTop":"13px"}}>
                        <div className='underlying'>Underlying</div>
                        <div className='stratName'>
                            {getScripsDropdown()}
                        </div>
                    </div>
                    <div style={{"height":"313px","overflowY":"scroll"}}>
                        <div style={{"textAlign":"left","paddingLeft":"24px","paddingTop":"13px"}}>
                            <div className='pt-4' style={{"position":"relative","left":"-23px"}}>
                                {/* <HeaderSubscribedData token={current_symbol_details.nToken} segmentId={current_symbol_details.nMarketSegmentId} symbol={current_symbol_details.sSymbol} description={current_symbol_details.sSecurityDesc!=""?current_symbol_details.sSecurityDesc:current_symbol_details.sSymbol+" Index"} exchange={current_symbol_details.sExchange} location={"analysis"}/> */}
                            <div className='c-pointer' style={{"position":"absolute","top":"-3%","right":"0px"}} onClick={()=>GraphExpansion()}><Expandicon/></div>
                                {payOffGraphData && getAnalyseGraph()}
                            </div>
                        </div>
                        <div style={{"display":"flex","flexDirection":"row","paddingTop":"5px"}}>
                            <div style={{"paddingLeft":"24px"}}>
                                <div className='basketAnalyseBreakEven p-4'>
                                    <div className='detailshead'>Breakeven points</div>
                                    <div className='breakevensValue bdr' style={{"paddingBottom":"5px"}}>{payOffData && payOffData.breakevens}</div>
                                    {payOffData && getPnLnGreeks()}
                                </div>
                            </div>
                            <div style={{"marginLeft":"30px"}}>
                                <div style={{"width":"99%"}}>
                                    {payOffData && getSlider()}
                                </div>
                                <div>
                                    <div className='mt-2 mb-4 basketOptionsLeg px-4'>
                                        <div style={{"height":"90px","overflowY":"scroll"}}>
                                            <div className='mt-4'>
                                                {payOffData && getOptionsSubcribedData()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {getBottom()}
                </React.Fragment>
            )}

        </React.Fragment>
        
    )
}

export default BasketAnalyst