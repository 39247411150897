import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import BasketketHeader from "../Header/bacsketHeader";
import {ExecutePublicBasket,setPublicBasketMargin,basketOrderAnalyse,sharedBasketExecute,setBasketDetails, setCurrentBasketState, setOrderListenerData} from "../../../slices/basketOrderSlice";
import {ReactComponent as SearchIcon} from "../../../assets/icons/search-normal.svg";
import './basketDetails.css'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Form from "react-bootstrap/Form";
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import SearchData from "./SearchData";
import EachStock, {OrderTypeModel, productTypeModel, ValidtyTypeModel} from "../eachStock/eachStock";
import AddToBasket from "../AddToBasket/addToBasket";
import {OrderOMSFeedSub} from "../../../sub";
import flowBackEndService from "../../../services/flowBackendApi";
import {toast} from "react-toastify";
import {basketSubServices} from "../../../sub";
import {BrokerApi} from "../../../chart-brokerapi/broker";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import AllBasketOrder from "./AllBasketOrder";
import {logMessage} from "../../../utils/helpers";
import flowBackEndServices2 from "../../../services/flowBackendServices";
import {SegmentExchangeMap} from "../../common/number_color"
import {RootState} from "../../../store"
import { Subscription } from 'rxjs';
import {ChildComponentRef,OrderMarginResponse,AllBaskets,BasketOrder,BasketState,UpdateBasketParam} from "../../../models/basketOrders"
import { TokenSymbol,ScripSearchedData } from '../../../models/symbolData';
import { AllOrders,OrderListener } from "../../../models/orders"
import { MarginInput } from '../../../models/strategy';

export const collectionStatus = {
    collectionAllow: false,
    setCollectionStatus:false
}

export const collectionArray = {
    OrderCollection: [] as AllOrders[],
    IsExcecuted: false,
    basketWhenExce: [] as BasketOrder[],
    setCollectionArray:[] as AllOrders[],
    setCollectionIsExcecuted:false,
    setBasketWhenExce: [] as BasketOrder[] | undefined,
}

// setting property
Object.defineProperty(collectionStatus, "setCollectionStatus", {
    set : function (value) {
        this.collectionAllow = value;
    }
});

Object.defineProperty(collectionArray, "setCollectionArray", {
    set : function (value) {
        this.OrderCollection = value;
    }
});

Object.defineProperty(collectionArray, "setCollectionIsExcecuted", {
    set : function (value) {
        this.IsExcecuted = value;
    }
});

Object.defineProperty(collectionArray, "setBasketWhenExce", {
    set : function (value) {
        this.basketWhenExce = value;
    }
});

interface BasketDetailsProp{
    data:AllBaskets
}

const BasketDetails:React.FC<BasketDetailsProp> = ({data}) => {
    const dispatch = useDispatch();
    const childRef = useRef<ChildComponentRef>(null)

    const [ sData, setSData ] = useState<ScripSearchedData[]>([])
    const [ basketOrders, setbasketOrders ] = useState<BasketOrder[]>([])
    const [ masterOrder, setMasterOrder ] = useState<AllOrders>()
    const [ isExecuted, setIsExecuted ] = useState(data.isExecuted)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [formState, setFormState] = useState({userInput: '', exchange: 'All Exchanges', symbolType: '5'});

    const  listDataMain:AllBaskets[] = useSelector(((state:any) => state.basketOrder.listDataMain),(left,right)=>{
       return JSON.stringify(left) == JSON.stringify(right)
    });
    const currentModification  = useSelector(((state:any) => state.basketOrder.currentModification),shallowEqual);
    const execute_public_basket:boolean  = useSelector(((state:any) => state.basketOrder.execute_public_basket),shallowEqual);
    const user = useSelector(((state:RootState) => state.authUser.user),shallowEqual);

    // const [ ordersNow, setOrdersNow ] = useState(null)
    const [typingTimer, setTypingTimer] = useState<NodeJS.Timeout | null>(null)

    const memData = useMemo(() => data, [data]);
    const memBasketOrders = useMemo(() => basketOrders, [basketOrders]);

    const memMasterOrder = useMemo(() => masterOrder, [masterOrder]);
    const memisExecuted = useMemo(() => isExecuted, [isExecuted]);
    const [prevOrders,setPrevOrders]=useState<number | null>(null)

    const [ totalMargin, setTotalMargin ] = useState<OrderMarginResponse | null>(null)

    async function executeFullOrder() {
        setIsLoading(true)
        collectionStatus.setCollectionStatus = true
        collectionArray.setCollectionArray = []
        collectionArray.setCollectionIsExcecuted = false;
       let orders =  listDataMain.find((d:AllBaskets) => d.id === data.id)
       collectionArray.setBasketWhenExce = orders?.basketOrders
        await settingListnerDataMaster()
        basketOrders.map((order:BasketOrder) => executeOrderSingle(order));
        sessionStorage.removeItem("public_basket_id")
        dispatch(ExecutePublicBasket(false))
    }

    async function executePublicFullOrder(orders:BasketOrder[]) {
        setIsLoading(true)
        dispatch(sharedBasketExecute())
        collectionStatus.setCollectionStatus = true
        collectionArray.setCollectionArray = []
        collectionArray.setCollectionIsExcecuted = false;
       collectionArray.setBasketWhenExce = orders
        await settingListnerDataMaster()
        orders.map((order:BasketOrder) => executeOrderSingle(order));
        sessionStorage.removeItem("public_basket_id")
        dispatch(ExecutePublicBasket(false))
    }

    useEffect(()=>{
        if(execute_public_basket){
            let orders =  listDataMain.find((d:AllBaskets) => d.id === data.id)
            if(orders){
                executePublicFullOrder(orders.basketOrders)
            }
        }
    },[listDataMain])

    useEffect(()=>{
        let id = sessionStorage.getItem("public_basket_id")
        if(id && id!=""){
            let orders =  listDataMain.find((d:AllBaskets) => d.id === data.id)
            if(orders) callTotalMarginApi(orders.basketOrders)
        }
    },[listDataMain])

   async function settingListnerDataMaster(){
        let val = { ...data,basketOrders: basketOrders}
        await dispatch(setOrderListenerData(val))
    }

    function fetchAllOrder() {
        BrokerApi.instance?.orders().then((d:any) => {setMasterOrder(d)})
    }

    function setSingleExcData() {
        let orders =  listDataMain.find((d:AllBaskets) => d.id === data.id)
        collectionArray.setBasketWhenExce = orders?.basketOrders
    }

    function executeOrderSingle(order:BasketOrder) {
        DataFeedInstance?.tokenInfo({market_segment_id: order.marketSegmentId , token: order.token }).then((values:TokenSymbol) => {
            let listenerId:number =  order.id;
            BrokerApi.instance?.placeOrder({
                symbol: values.symbol,
                side: order.buySell,
                margin: Number(order.productType),
                qty: order.quantity,
                limitPrice: order.price,
                triggerPrice: order.triggerPrice,
                order_type: order.orderType,
                validity: Number(order.validity),
                is_amo: order.isAmo,
                origin:'complete_order_basket'
            },String(listenerId)).then(()=>{
                // logMessage(`ORDDDDDD ${listenerId}`);
                window.GtmPusher({
                    event: 'tv-order-placed-basketMulti'
                })
            })
        })
    }
    useEffect(()=>{
        let orders:AllBaskets | undefined =  listDataMain.find((d:AllBaskets) => d.id === data.id)
        setPrevOrders(Number(orders?.basketOrders.length))
    },[])

    useEffect(()=>{
        let orders:AllBaskets | undefined =  listDataMain.find((d:AllBaskets) => d.id === data.id)
        if(orders) setbasketOrders(orders?.basketOrders)
        if(orders) setIsExecuted(orders.isExecuted)
        if(Number(orders?.basketOrders.length) > 0 && orders?.basketOrders.length==prevOrders){
                callTotalMarginApi(orders?.basketOrders)
        }
    },[prevOrders])

    function updateDataNewAdd() {
        let orders:AllBaskets | undefined =  listDataMain.find((d:AllBaskets) => d.id === data.id)
        logMessage("basketDetails.js: UpdateDataNewAdd called")
        if(orders) setbasketOrders(orders?.basketOrders)
        if(orders) setIsExecuted(orders.isExecuted)
        if(Number(orders?.basketOrders.length) > 0){
            if(orders?.basketOrders.length!=prevOrders && prevOrders!=null){
                callTotalMarginApi(orders?.basketOrders)
            }
        }
    }



    let subscription:Subscription | undefined = undefined

    function checkValidOrderAndUpdate(values:OrderListener, idNum:number) {
        let ordersMain:BasketOrder[] =  collectionArray.basketWhenExce
        let currData:BasketOrder | undefined = ordersMain.find((d:BasketOrder) => d.id === idNum)
        if(currData === undefined){
            BrokerApi.instance?._host.showNotification("Order Not found","" ,0)
            callCloseLoddingChild()
        }
        let newData:BasketOrder = { ...currData,orderId: values.order.order_id} as BasketOrder
        let orderlist:BasketOrder[] = [...ordersMain];
        orderlist = orderlist.map((u:BasketOrder) => u.id !== idNum ? u : newData);
        let orders:AllBaskets | undefined =  listDataMain.find((d:AllBaskets) => d.id === data.id)
        const dataMY:UpdateBasketParam = {basketName: orders?.basketName as string,orders:orderlist}
        setIsLoading(true)
        flowBackEndService.UpdateBasket(dataMY,String(user?.access_token),Number(orders?.id))
            .then(value => {
                if(value.data.status ===  'success'){
                      callCloseLoddingChild()
                       fetchAllOrder();
                      setbasketOrders(orderlist)
                      basketSubServices.setNewBasket(currData)
                }else{
                    BrokerApi.instance?._host.showNotification('Error update',"Order Not Updated" ,0)
                    toast.error(`Error update ${value?.data?.message}`);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }


    function triggerOrderExecutionApi(OrderList:AllOrders[],curOrder:BasketOrder[]) {
        // console.log("BATCH ORDER",OrderList,curOrder)
        let newListOrderIDs:BasketOrder[]=[]
        curOrder.map((value:BasketOrder) => {
            const ifPresent: AllOrders | undefined = OrderList.find((v:AllOrders) => v.basketId === value.id);
            if(ifPresent){
                newListOrderIDs = [...newListOrderIDs,{...value,orderId: String(ifPresent.orderId)} as BasketOrder]
            }
        })

        // console.log("BBT",newListOrderIDs)
        if(curOrder.length !== newListOrderIDs.length){
            logMessage(`BasketDetals : order mismatch`)
            setIsLoading(false)
            return
        }

        collectionArray.setCollectionIsExcecuted = true

        setIsLoading(true)
        const datas:UpdateBasketParam = {basketName: data.basketName,isExecuted: true,orders:newListOrderIDs}
        flowBackEndService.UpdateBasket(datas,String(user?.access_token),data.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    fetchAllOrder();
                    collectionStatus.setCollectionStatus = false
                    collectionArray.setCollectionIsExcecuted = true
                    // collectionArray.setBasketWhenExce = []
                    setIsLoading(false)
                     setIsExecuted(true)
                     setbasketOrders(newListOrderIDs)
                     basketSubServices.setNewBasket(newListOrderIDs)
                }else{
                    toast.error(`Error update ${value?.data?.message}`);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    function getUniqueListBy<T>(arr: T[], key: keyof T): T[] {
        return [...new Map(arr.map(item => [item[key], item])).values()];
      }
      

    function setMultiOrderValue(values:OrderListener, idNum:number) {
        collectionArray.setCollectionArray = [...collectionArray.OrderCollection,{orderId: Number(values.order.order_id), basketId: idNum} as AllOrders];
        let uniqList:AllOrders[] = getUniqueListBy(collectionArray.OrderCollection,'basketId')
        let orders:BasketOrder[] =  collectionArray.basketWhenExce
        if(uniqList.length === orders.length){
            if(collectionArray.IsExcecuted === false) triggerOrderExecutionApi(uniqList,orders)
        }
    }



    function onAnotherStatusUpdateStatusOfOrder(values:OrderListener) {
        if(values.order?.listenerId){
            let idNum = +(values.order.listenerId)
            if(collectionStatus.collectionAllow) {
                if(collectionArray.basketWhenExce.find((d:BasketOrder) => d.id === idNum)) fetchAllOrder()
            }else {
                if(collectionArray.basketWhenExce.find((d:BasketOrder) => d.id === idNum)) fetchAllOrder()
            }
        }
    }

    function processOrderNow(values:OrderListener) {
        if(values.order?.listenerId){
            let idNum = +(values.order.listenerId)
            if(collectionStatus.collectionAllow) {
                if(values.order.status === 6) setMultiOrderValue(values,idNum)
            }else {
                if(values.order.status === 6) checkValidOrderAndUpdate(values,idNum)
            }
        }
    }

    // useEffect(() => {
    //     if(ordersNow) processOrderNow()
    // }, [ordersNow]);

   /* useEffect(() => {
        collectionArray.setCollectionArray = []
    }, [orderListenerData]);

    useEffect(() => {
        console.log("ORDEER",orderListenerData?.basketOrders)
    }, [orderListenerData?.basketOrders]);*/

    function listenOrdereExcecution(){
        subscription = OrderOMSFeedSub.OrderListener().subscribe((values:any) => {
            logMessage(`ORDER SS ${JSON.stringify(values)}`);
            // if(values?.order && values.order.status === 6) setOrdersNow(values)
            if(values?.order && values.order.status === 6)  processOrderNow(values)
            if(values?.order && values.order.status === 5)  hardcheckWhenReject(values)
            if (values?.order && values.order.status !== 6) onAnotherStatusUpdateStatusOfOrder(values)
            if(values.response && values.response.error) setIsLoading(false)
            setIsLoading(false)
        })
    }

    function hardcheckWhenReject(values:OrderListener){
        if(values.order?.listenerId){
            let idNum:number = +(values.order.listenerId)
           let val:AllOrders | undefined =  collectionArray.OrderCollection.find((d:AllOrders) => d.basketId === idNum);
            if(val){
                return
            }
            if(collectionStatus.collectionAllow) {
                 setMultiOrderValue(values,idNum)
            }else {
                 checkValidOrderAndUpdate(values,idNum)
            }
        }
    }


    useEffect(() => {
        listenOrdereExcecution()
        fetchAllOrder()
        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    useEffect(() => {
      if(listDataMain) updateDataNewAdd()
    }, [listDataMain])


    useEffect(() => {
        console.log("set setBasketDetails",data)
       dispatch(setBasketDetails(data))
    }, [data])

    useEffect(() => {
        // console.log("isExecuted",isExecuted,data.isExecuted)
    }, [isExecuted])


    useEffect(() => {
           // console.log("basketOrders",basketOrders);
      // if(basketOrders.length > 0) setbasketSize(basketOrders.length)
    }, [basketOrders])



    useEffect(() => {
       if(currentModification) openModel()
    }, [currentModification])


    useEffect(() => {
        searchDataFunc(formState.userInput)
    }, [formState.symbolType,formState.exchange])

    useEffect(() => {
        // searchAfterSomeTime()
    }, [formState.userInput])



    const onBackClick = () => {
        if(isLoading) return null;
        let state:BasketState =  {id: 1,param: null,url:'BucketList/EmptyState'};
       sessionStorage.removeItem("public_basket_id")
        dispatch(setCurrentBasketState(state))
    }

    const openModel = () => {
        if(childRef.current){
            childRef.current.getAlert();
        }
    }

    const searchDataFunc = (value:string) => {
        if(value.length === 0){
            return
        }
        let symbolType = Number(formState.symbolType)
        setIsLoadingSearch(true)
        DataFeedInstance?.searchSymbols(formState.userInput,formState.exchange,symbolType,(res:ScripSearchedData[]) => {
            setSData(res)
            setIsLoadingSearch(false)
        })
    }

    function handleFormStateChange(event:any)  {
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });

        clearTimeout(Number(typingTimer));
        if (value.length > 0) {
            const timerOut = setTimeout(() =>{
                searchDataFunc(value)
            }, 500);
            setTypingTimer(timerOut)
        }
    }

    const formStateChange = (key:string,value:string) => {
        setFormState({ ...formState, [key]: value });
    }

    const clearInput = () => {
        setFormState({ ...formState, userInput: '' });
    }

    function updateBasketArrangeOrder(dataOrderd:BasketOrder[],isExecuted_my:boolean | null= null) {
        let datas:UpdateBasketParam
        if(isExecuted_my === null){
            datas =  {basketName: data.basketName,orders:dataOrderd}
        }else {
            datas =  {basketName: data.basketName,isExecuted: isExecuted_my,orders:dataOrderd}
        }
        flowBackEndService.UpdateBasket(datas,String(user?.access_token),data.id)
            .then(value => {
                if(value.data.status ===  'success'){
                     basketSubServices.setNewBasket(dataOrderd)
                }else{
                    // toast.error(`Error update ${value?.data?.message}`);
                }
            });
    }

    const updateBasketOrderCallBack = useCallback((data:BasketOrder[]) => {
        setbasketOrders(data)
        updateBasketArrangeOrder(data)
    }, []);




   async function resetOrders(){
        let reverseData:BasketOrder[] = [];
        basketOrders.map((value:BasketOrder) => {
            reverseData = [...reverseData,{...value,orderId: ""}]
        })
        setbasketOrders(reverseData)
        setIsExecuted(false)
        // await dispatch(setOrderListenerData(null))
        BrokerApi.instance?.removeOrderLids(reverseData.map((value:BasketOrder)=>value.id))
        updateBasketArrangeOrder(reverseData, false)
    }

    function callCloseLoddingChild() {
        if(childRef.current){
            childRef.current.callChildMethod();
        }
    }

    const productMap:Record<string,string> = {
        "1": "INTRADAY",
        "2": "MTF",
        "3": "DELIVERY",
    }
    const VarietyMap:Record<string,string> = {
        "1": "RL",
        "2": "RL-MKT",
        "3": "SL",
        "4": "SL-MKT",
    }
    function formateMarginData(data:BasketOrder) {
       const finalInput:MarginInput = {
            "token": data.token,
            "exchange": SegmentExchangeMap[data.marketSegmentId],
            "transaction_type": (data.buySell == 1 ? "BUY" : "SELL"),
            "product": productMap[data.productType],
            "variety": VarietyMap[data?.orderType],
            "quantity": data.quantity * Number(data.stockData?.lotSize),
            "price": data.price
        }
        return finalInput
    }

    function callTotalMarginApi(orders:BasketOrder[] | undefined) {
        let data:MarginInput[] | undefined = orders?.map((d:BasketOrder) => formateMarginData(d))
        flowBackEndServices2.getTotalMargin({orders: data},String(user?.access_token)).then(value => {
            let response:OrderMarginResponse=value.data
            if(value.status === 200 && response.status === 'success'){
                dispatch(setPublicBasketMargin(response))
               setTotalMargin(response)
            }
        })
    }

    const analyseGraph = () =>{
        let optionAvailable:boolean = false
        let orders:AllBaskets | undefined =  listDataMain.find((d:AllBaskets) => d.id === data.id)
        orders?.basketOrders.map((d:BasketOrder)=>{
            if(d.stockData?.marketSegmentId==2){
                optionAvailable = true
            }
        })
        if(optionAvailable){
            let state:BasketState =  {id: 5,param: orders,url:'basketAnalyse'};
            dispatch(setCurrentBasketState(state))
            dispatch(basketOrderAnalyse())
        }else{
            toast.error("At least one order of any option should be in the basket")
        }
    }

    return (
        <React.Fragment>
            <BasketketHeader>
                <span onClick={onBackClick} className="heading2 no-drag">  <i className="fa fa-arrow-left c-pointer" aria-hidden="true"/>&nbsp; {data.basketName} ({basketOrders.length}/10)</span>
            </BasketketHeader>

            { currentModification && (
                <AddToBasket ref={childRef} stockData={currentModification} modification={'M'}/>
            )}

            {
                basketOrders.length > 0 && (
                    <div className="bottom_execute_bar currentBg2">
                        {isExecuted ? null:
                            <React.Fragment>
                                <Button disabled={isLoading} className="blue_btn my-3 w-25 mr-5 no-drag" onClick={executeFullOrder} variant="primary" type="submit">
                            {isLoading && (
                                <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                            )}
                            Execute
                        </Button>
                                <Button style={{"marginLeft":"19%"}} className="analyse_btn my-3 w-25 no-drag" onClick={analyseGraph} variant="primary" type="submit">
                            Analyse
                        </Button>
                            </React.Fragment>
                        }

                        { isExecuted && (
                            <React.Fragment>
                                <div onClick={resetOrders} className="btn blue_btn w-25 c-pointer mr-5">Reset</div>
                                {/*<div onClick={reverseOrder} className="btn blue_btn_outline text-nowrap w-25 c-pointer mr-5">Reverse Order</div>*/}
                            </React.Fragment>
                        )}

                        {(!isExecuted) && (
                            <div className='d-flex flex-row'>
                                    <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                                        <div className="text_grey currentText">Initial Margin</div>
                                        <div className="heading2">{totalMargin?totalMargin.initial_margin.toFixed(2):0}</div>
                                    </div>
                                    <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                                    <div className="text_grey currentText">Final Margin</div>
                                    <div className="heading2">{totalMargin?totalMargin.required_margin.toFixed(2):0}</div>
                                </div>
                            </div>
                        )}

                    </div>
                )
            }



            <div className="d-flex  w-100 no-drag">

                <Form  className="w-100 pt-3 px-5 ">

                    <Form.Group className="position-relative" controlId="formBasicEmail">

                        {formState.userInput.length === 0 && (
                            <div className="mysearch c-pointer"><SearchIcon/></div>
                        )}
                        {formState.userInput.length > 0 && (
                            <React.Fragment>
                                <div className="myclear"> <button onClick={clearInput} className="btn clearbtn">Clear</button> </div>
                                <SearchData data={sData} isLoading={isLoadingSearch} formState={formState} formStateChange={formStateChange} />
                            </React.Fragment>
                            )}
                        <Form.Control type="text"
                                      name="userInput"
                                      required
                                      autoComplete="off"
                                      value={formState.userInput}
                                      onChange={handleFormStateChange}
                                      placeholder="Search & Add Stock" />
                        <Form.Control.Feedback type='invalid' className="d-flex flex-row">

                        </Form.Control.Feedback>
                    </Form.Group>

                </Form>


            </div>

            {/*{getBasketOrders()}*/}

            <AllBasketOrder basketOrders={memBasketOrders}
                            data={memData}
                            masterOrder={memMasterOrder}
                            isExecuted={memisExecuted}
                            updateBasketOrderCallBack={updateBasketOrderCallBack}
                            ref={childRef}
                            />



        </React.Fragment>
    );
};

export default BasketDetails;








