import { logMessage } from "../utils/helpers"
import ReconnectingWebSocket from 'reconnecting-websocket';
import { TokenInfo } from "../models/symbolData";
import { InternalOMSFeedMessage, OrderPlacementPayload } from "../models/orders"
import { GTTOrder } from "../models/gttOrders";
import { addIfNotExist } from "../slices/gttSlices";
import store from "../store";
import { _exchangeMap, fetchAndMapTokensWithAuth } from "./broker.helper";

interface ExtendedInternalOMSFeedMessage extends InternalOMSFeedMessage {
    gtt?: GTTOrder;
}

export class Omsfeed {
    private _socket: ReconnectingWebSocket | null;
    private readonly _host: any;
    private readonly _broker: any;
    private readonly auth_token: string;
    private readonly q: any;

    constructor(host: any, broker: any, queue: any, auth_token: string) {
        this._socket = null;
        this._host = host;
        this._broker = broker;
        this.auth_token = auth_token;
        this.q = queue;
    }

    private setAllGttOrders(orders: GTTOrder): void {
        store.dispatch(addIfNotExist(orders));
    }

    private _getOrderStatus(status: string): number {
        const dict: Record<string, number> = {
            "PENDING": 6,
            "CANCELLED": 1,
            "REJECTED": 5,
            "COMPLETED": 2,
        };
        return dict[status];
    }

    private _getGtdOrderStatus(status: string): number {
        const dict: Record<string, number> = {
            "ACTIVE": 6,
            "COMPLETED": 2,
            "WITHDRAW": 1,
            "EXPIRED": 1,
            "REJECTED": 5
        };
        return dict[status];
    }

    public setupSocket(): void {
        const rws = new ReconnectingWebSocket(
            `wss://updates.rupeezy.in/vortex?auth_token=${this.auth_token}`,
            [],
            {
                maxReconnectionDelay: 2000,
                minReconnectionDelay: 100,
                reconnectionDelayGrowFactor: 1.3,
                maxRetries: 100
            }
        );

        rws.addEventListener('error', (error) => {
            logMessage(`Internal Oms Feed Provider: Socket Error: ${JSON.stringify(error)}`);
        });

        rws.addEventListener('close', () => {
            // Handle close event if needed
        });

        // Bind the message handler to maintain correct 'this' context
        rws.addEventListener('message', (message) => this.handleMessage(message));

        this._socket = rws;
    }

    private handleMessage(message: MessageEvent): void {
        const order: ExtendedInternalOMSFeedMessage = JSON.parse(message.data);
        
        this.q.push((cb: () => void) => {
            if (order.type === "order" && order.data.order_identifier.toString().indexOf("GTD") === -1) {
                this.handleRegularOrder(order, cb);
            } else if (order.type === 'trade') {
                this.handleTradeOrder(order, cb);
            } else if (order.type === "gtt_order") {
                if (order.gtt) {
                    this.handleGttOrder(order, cb);
                } else {
                    logMessage('Received GTT order without GTT data');
                    cb();
                }
            } else if (order.type === "sl_trigger") {
                this.handleSlTrigger(order, cb);
            }
        });
    }

    private handleRegularOrder(order: ExtendedInternalOMSFeedMessage, cb: () => void): void {
        const statusMessage = this.processStatusMessage(order.data.status_message);
        
        this._broker._quotesProvider.tokenInfo({
            market_segment_id: order.data.market_segment_id,
            token: order.data.token
        }).then((symbol: TokenInfo) => {
            const orderPayload = this.createOrderPayload(order, symbol, statusMessage);
            this._broker.updateOrder(orderPayload, "update");
            cb();
        });
    }

    private handleTradeOrder(order: ExtendedInternalOMSFeedMessage, cb: () => void): void {
        this._host.orderPartialUpdate(order.data.order_id, { remQty: order.data.pending_quantity });

        const tradePayload: OrderPlacementPayload = {
            id: String(order.data.trade_number),
            exchange: order.data.exchange,
            market_segment_id: order.data.market_segment_id,
            token: order.data.token,
            side: order.data.transaction_type === "BUY" ? 1 : -1,
            type: order.data.variety,
            product: order.data.product,
            tradedQty: order.data.total_quantity - order.data.pending_quantity,
            tradedPrice:order.data.traded_price,
            tradeTime: order.data.trade_time,
            orderNumber: order.data.order_id,
            time: Date.now()
        };

        this._broker.addExecution(tradePayload);
        cb();
    }

    private async handleGttOrder(order: ExtendedInternalOMSFeedMessage, cb: () => void): Promise<void> {
        if (!order.gtt) {
            logMessage('GTT order received without GTT data');
            cb();
            return;
        }

        const maketSegmentId = _exchangeMap(order.gtt.exchange)

        const data = await fetchAndMapTokensWithAuth({
            token_seg_ids: [
                {
                    market_segment_id: maketSegmentId,
                    token: `${order.gtt.token}`
                }
            ]
        })
        
        if(!data || !data.length) return

        this.setAllGttOrders({...order.gtt,symbol:data[0].symbol,market_segment_id: +maketSegmentId});

        this._broker._quotesProvider.tokenInfo({
            market_segment_id: maketSegmentId,
            token: `${order.gtt.token}`
        }).then((symbol: TokenInfo) => {
            const orderPayload = this.createGttOrderPayload(order, symbol);
            this._broker.updateOrder(orderPayload, "update");
            cb();
        });
    }

    private handleSlTrigger(order: ExtendedInternalOMSFeedMessage, cb: () => void): void {
        this._broker._quotesProvider.tokenInfo({
            market_segment_id: order.data.market_segment_id,
            token: order.data.token
        }).then((symbol: TokenInfo) => {
            const slPayload: OrderPlacementPayload = {
                id: order.data.order_id,
                symbol: symbol.symbol,
                side: order.data.transaction_type === "BUY" ? 1 : -1,
                order_type: order.data.variety,
                type: this.getOrderType(order.data.variety),
                product: order.data.product,
                status: 6,
                displayMessage: "SL Trigger hit",
                qty: order.data.total_quantity,
                remQty: order.data.pending_quantity,
            };
            
            this._broker.updateOrder(slPayload, "sl-update");
            cb();
        });
    }

    private processStatusMessage(message: string): string {
        const shortfallMatch = message.match(/(?:SFall|ShortFall)=(\S+)/);
        if (shortfallMatch) {
            return `Margin Shortfall of Rs ${shortfallMatch[1]}`;
        }
        if (message.includes("not allowed by  member")) {
            return "Trading in this scrip is not allowed";
        }
        return message || "Placed";
    }

    private getOrderType(variety: string): number {
        switch (variety) {
            case 'RL-MKT': return 2;
            case 'RL': return 1;
            case 'SL-MKT': return 3;
            default: return 4;
        }
    }

    private createOrderPayload(order: ExtendedInternalOMSFeedMessage, symbol: TokenInfo, statusMessage: string): OrderPlacementPayload {
        return {
            id: order.data.order_id,
            exchange: order.data.exchange,
            symbol: symbol.symbol,
            side: order.data.transaction_type === "BUY" ? 1 : -1,
            order_type: order.data.variety,
            type: this.getOrderType(order.data.variety),
            product: order.data.product,
            qty: order.data.total_quantity,
            remQty: order.data.pending_quantity,
            traded_quantity: order.data.total_quantity - order.data.pending_quantity,
            displayMessage: statusMessage,
            status: this._getOrderStatus(order.data.status),
            tradedPrice: order.data.traded_price,
            limitPrice: order.data.order_price,
            stopPrice: order.data.trigger_price,
            order_id: order.data.order_id,
            duration: order.data.order_identifier.indexOf("GTD") !== -1 ? "GTT" : order.data.validity,
            is_amo_order: order.data.is_amo ? "Yes" : "No",
            exchange_timestamp: order.data.order_created_at
        };
    }

    private createGttOrderPayload(order: ExtendedInternalOMSFeedMessage, symbol: TokenInfo): OrderPlacementPayload {
        if (!order.gtt || !order.gtt.orders?.[0]) {
            throw new Error('Invalid GTT order data');
        }
    
        const gttOrder = order.gtt.orders[0];
        
        return {
            id: order.gtt.id,
            exchange: order.gtt.exchange,
            symbol: symbol.symbol,
            side: order.gtt.transaction_type === "BUY" ? 1 : -1,
            order_type: gttOrder.variety,
            type: this.getOrderType(gttOrder.variety),
            product: order.gtt.product,
            qty: gttOrder.quantity,
            remQty: gttOrder.quantity, // Initially, remaining quantity equals total quantity
            traded_quantity: 0, // Initially no trades
            displayMessage: gttOrder.error_reason || "Placed",
            status: this._getGtdOrderStatus(gttOrder.status),
            tradedPrice: 0, // Initially no trades
            limitPrice: gttOrder.price,
            stopPrice: gttOrder.trigger_price,
            order_id: gttOrder.id.toString(),
            duration: "GTT", // GTT orders always have GTT duration
            is_amo_order: "No", // GTT orders are not AMO orders,
            exchange_timestamp: gttOrder.created_at
        };
    }

    public disconnectSocket(): void {
        if (this._socket) {
            logMessage('existing internal socket is disconnected');
            this._socket.close(1000);
            this._socket = null;
        }
    }
}