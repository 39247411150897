import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging,Messaging } from "firebase/messaging";
import { getRemoteConfig,getValue,fetchConfig,RemoteConfig } from "firebase/remote-config";
import { STAGE } from "./config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAYtBD2MpzN-WPRftR9RUEb5dZSs9ShYwY",
  authDomain: "flow-india.firebaseapp.com",
  projectId: "flow-india",
  storageBucket: "flow-india.appspot.com",
  messagingSenderId: "996040433390",
  appId: "1:996040433390:web:3800f4b49135e5ed461dff",
  measurementId: "G-JMJVTV89PG"
};

const remoteConfigVariables = new Map();

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
app.automaticDataCollectionEnabled = true;
export const analytics = getAnalytics(app);
var messaging: Messaging | null
try {
    messaging = getMessaging(app);
} catch (error) {
    messaging = null
}
export { messaging };

const vortexKey: string = process.env.REACT_APP_VORTEX_KEY as string;
const liveBaseUrl: string = process.env.REACT_APP_LIVE_BASE_URL as string;
const basketShare: string = process.env.REACT_APP_BASKET_SHARE as string;
const vortexUrl: string = process.env.REACT_APP_VORTEX_URL as string;
const waveApiKey: string = process.env.REACT_APP_WAVE_2_API_KEY as string;

export const remoteConfig:RemoteConfig = getRemoteConfig(app);
remoteConfig.defaultConfig = {
    "x_api_key": vortexKey ,
    "live_base_url": liveBaseUrl,
    "basket_share_url":basketShare,
    "vortex_url_v2": vortexUrl,
    "wave_2_Api_Key": waveApiKey,
    "vapidkey": "BHNI0Bx9iMUXbispgUwx4yZtqY-Y9HFvNdeBW6WQTKGmsihfq5-GsVCtkTmNgHXMqik77zhbECrWCr0jd3Cu7NY",
    "ltp_socket_url": "wss://feed.rupeezy.in:4518/",
    "ltp_proxy_url": "wss://feed-proxy.rupeezy.in:4517/",
}
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
if(process.env.REACT_APP_ENV == "production"){
    fetchConfig(remoteConfig)
}


export function remoteConfigGetter(value:string){
    return getValue(remoteConfig, value);
}
