import { configureStore } from '@reduxjs/toolkit';
import authReducer, { initialCheckData } from "./slices/authReducer";
import optionChainReducer from "./slices/optionChainSlice";
import basketOrderReducer from "./slices/basketOrderSlice";
import optionStrategyReducer from "./slices/optionStrategySlice";
import AlertReducer from "./slices/alertSlices";
import GttReducer from "./slices/gttSlices";
import Orders from "./slices/ordersSlice";


// Create the store
const store = configureStore({
  reducer: {
    authUser: authReducer,
    optionChain: optionChainReducer,
    basketOrder: basketOrderReducer,
    optionStrategy: optionStrategyReducer,
    alert:AlertReducer,
    gttAlerts: GttReducer,
    orders: Orders,
  },
  devTools: process.env.NODE_ENV === 'development',
});

// Type for the root state
export type RootState = ReturnType<typeof store.getState>;

// Type for the dispatch function
export type AppDispatch = typeof store.dispatch;

// Dispatch an action with type safety
store.dispatch(initialCheckData() as any);

export default store;
