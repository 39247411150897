/***
 * [This Component is Common For all the GTT Orders]
 *  */

import React, { useEffect, useState } from "react";
import ToggleButton from "../common/GTTTrigger/ToggleGroup";
import {
  AssetClasses,
  GTTFormState,
  OptionTimeframe,
  OrderType,
  SecurityType,
  TradeDirection,
  TriggerMode,
} from "../gttTypes";

import "./gttbody.scss";
import "../../../../scss/_buttons.scss";

import GenericInput from "../common/GTTGenericInput";
import {
  GttOrderCreateTerms,
  roundToNearestTick,
} from "./gttHelper";
import useCreateGTTOrder from "../../../../hooks/api/create/useCreateGTTOrder";
import { GTTBodyProps } from ".";
import { BrokerApi } from "../../../../chart-brokerapi/broker";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { gttSchema } from "./gttSchema";

export type GTTProbs = {
  setConditionalWidth: () => void;
  CloseGttModal: () => void;
  assetClass: AssetClasses;
  securityType: SecurityType;
  tradeDirection: TradeDirection;
  ltp: string;
  currentGTTTab: OptionTimeframe;
  tick: number;
  lotSize: number;
  isCondtionalWidthNeeded: boolean;
  securityDesc: string;
} & Pick<GTTBodyProps, "token" | "exchnage">;

export type GTTFormData = z.infer<typeof gttSchema>;

const CreateGTT: React.FC<GTTProbs> = ({
  tradeDirection,
  ltp,
  token,
  exchnage,
  currentGTTTab,
  tick,
  lotSize,
  isCondtionalWidthNeeded,
  setConditionalWidth,
  CloseGttModal,
  securityDesc,
}) => {
  const memorizedLTP: number = React.useMemo(() => +ltp, []);
  const tickBy100 = React.useMemo(() => tick / 100, []);

  const { createGTTOrder, isLoading, isError, error } = useCreateGTTOrder(
    token,
    exchnage,
    tradeDirection
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid, isSubmitting },
  } = useForm<GTTFormData>({
    resolver: zodResolver(gttSchema),
    mode: "onChange",
    defaultValues: {
      tickBy100: 0,
      dynamicLtp: "",
      currentGTTTab: "Intraday", // will be setted by useEffect
      tradeDirection: "Buy",
      triggresType: "OCO",

      stoplossTriggerPrice: "",
      stoplossQuantity: "1",
      stoplossPrice: "",
      stopLossOrderType: "Limit",

      targetTriggerPrice: "",
      targetQuantity: "1",
      targetPrice: "",
      targetLossOrderType: "Limit",
    },
  });

  useEffect(() => {
    setValue("currentGTTTab", currentGTTTab);
    setValue("tradeDirection", tradeDirection);

    // now calculate values
    const rawTriggerPrice = roundToNearestTick(
      tradeDirection === "Buy" ? memorizedLTP * 1.05 : memorizedLTP * 0.95,
      tickBy100
    );
    const rawTargetTriggerPrice = roundToNearestTick(
      tradeDirection === "Buy" ? memorizedLTP * 0.95 : memorizedLTP * 1.05,
      tickBy100
    );

    setValue("stoplossTriggerPrice", rawTriggerPrice);
    setValue("stoplossPrice", rawTriggerPrice);

    setValue("targetTriggerPrice", rawTargetTriggerPrice);
    setValue("targetPrice", rawTargetTriggerPrice);
    setValue('tickBy100', tickBy100)
  }, [currentGTTTab, tradeDirection,tickBy100]);

  useEffect(() => {
    setValue("dynamicLtp", ltp);
  }, [ltp]);

  useEffect(() => {
    trigger([
      "stoplossPrice",
      "targetPrice",
      "stoplossTriggerPrice",
      "targetTriggerPrice",
      "dynamicLtp",
      "tickBy100",
      'triggresType'
    ]);
  }, [
    watch("stoplossPrice"),
    watch("targetPrice"),
    watch("targetTriggerPrice"),
    watch("stoplossTriggerPrice"),
    watch("tickBy100"),
    watch('triggresType'),
    trigger,
    ltp,
  ]);

  const onSubmit = async (data: GTTFormData) => {
    try {
      const restOfProbs = {
        transaction_type: tradeDirection,
        product: currentGTTTab,
      };
      const formState: GTTFormState = {
        orderType: watch("stopLossOrderType"),
        price: watch("stoplossPrice"),
        quantity: watch("stoplossQuantity"),
        triggerPrice: watch("stoplossTriggerPrice"),
        triggerType: watch("triggresType"),
        targetOrderType: watch("targetLossOrderType"),
        targetPrice: watch("targetPrice"),
        targetQuantity: watch("targetQuantity"),
        targetTriggerPrice: watch("targetTriggerPrice"),
      };

      const response = await createGTTOrder(formState, restOfProbs);

      if (response.status === "success") {
        CloseGttModal();
        BrokerApi.instance?._host.showNotification(
          "GTT Order created successfully!",
          `GTT Order For ${securityDesc} has been Created`,
          1
        );
        // Optional: Reset form or navigate
      } else {
        BrokerApi.instance?._host.showNotification(
          "Failed to create GTT Order",
          response.message,
          0
        );
      }
    } catch (err) {
      // toast.error("Error creating GTT Order");
    }
  };

  const triggresType = watch("triggresType");
  const stoplossPrice = watch("stoplossPrice");
  const stoplossPriceOrderType = watch("stopLossOrderType");
  const targetLossOrderType = watch("targetLossOrderType");

  useEffect(() => {
    setConditionalWidth();
  }, [triggresType]);

  const isButtonDisabled =
    !isValid || isSubmitting || isLoading || Object.keys(errors).length > 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mx-4 my-5 justify-content-between">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <div style={{ fontSize: 20 }} className="text-color fw-bolder">
            Trigger type
          </div>

          <Controller
            name="triggresType"
            control={control}
            render={({ field }) => (
              <ToggleButton<TriggerMode>
                options={["Single", "OCO"]}
                selectedOption={field.value}
                onToggle={field.onChange}
              />
            )}
          />
        </div>
        <div
          className={`${
            triggresType === "OCO" ? "col-6" : "col-12"
          } text-start pe-4`}
        >
          {triggresType === "OCO" && (
            <div className="text-color fw-bolder">Stop Loss</div>
          )}

          <Controller
            name="stoplossTriggerPrice"
            control={control}
            render={({ field }) => (
              <GenericInput
                id="stoplossTriggerPrice"
                label="Trigger price"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                error={errors.stoplossTriggerPrice?.message}
                type="number"
              />
            )}
          />

          <div className="row">
            <Controller
              name="stoplossQuantity"
              control={control}
              render={({ field }) => (
                <GenericInput
                  id="stoplossQuantity"
                  label="Quantity"
                  containerClassName="col-6"
                  subLable={`1 lot = ${lotSize}`}
                  required
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  error={errors.stoplossQuantity?.message}
                  type="number"
                />
              )}
            />

            <div className="col-6">
              <Controller
                name="stoplossPrice"
                control={control}
                render={({ field }) => (
                  <GenericInput
                    label="Price"
                    id="stoplossPrice"
                    subLable={`Tick: ${tick / 100}`}
                    value={stoplossPrice === "Market" ? "Market" : field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={errors.stoplossPrice?.message}
                    disabled={stoplossPriceOrderType === "Market"}
                    type={
                      stoplossPriceOrderType === "Market" ? "text" : "number"
                    }
                  />
                )}
              />

              <div className="text-end mt-4">
                <Controller
                  name="stopLossOrderType"
                  control={control}
                  render={({ field }) => (
                    <ToggleButton<OrderType>
                      options={["Limit", "Market"]}
                      selectedOption={field.value}
                      onToggle={(value) => {
                        field.onChange(value); // Update the form field value
                        if (value === "Market") {
                          setValue("stoplossPrice", "Market");
                        } else {
                          const stoplossTriggerPrice = watch(
                            "stoplossTriggerPrice"
                          );
                          setValue("stoplossPrice", stoplossTriggerPrice || "");
                        }
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${
            triggresType === "OCO" ? "col-6" : "col-12"
          } text-start ps-4 border-start gtt-border-left`}
        >
          {triggresType === "OCO" && (
            <>
              <div className="text-color fw-bolder">Target</div>
              <Controller
                name="targetTriggerPrice"
                control={control}
                render={({ field }) => (
                  <GenericInput
                    id="targetTriggerPrice"
                    label="Trigger price"
                    value={field.value || ""}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={errors.targetTriggerPrice?.message}
                    type="number"
                  />
                )}
              />

              <div className="row">
                <Controller
                  name="targetQuantity"
                  control={control}
                  render={({ field }) => (
                    <GenericInput
                      id="targetQuantity"
                      required
                      label="Quantity"
                      containerClassName="col-6"
                      subLable={`1 lot = ${lotSize}`}
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.value)}
                      error={errors.targetQuantity?.message}
                      type="number"
                    />
                  )}
                />

                <div className="col-6">
                  <Controller
                    name="targetPrice"
                    control={control}
                    render={({ field }) => (
                      <GenericInput
                        label="Price"
                        subLable={`Tick: ${tick / 100}`}
                        value={
                          stoplossPrice === "Market"
                            ? "Market"
                            : field.value || ""
                        }
                        onChange={(e) => field.onChange(e.target.value)}
                        error={errors.targetPrice?.message}
                        disabled={targetLossOrderType === "Market"}
                        type={
                          targetLossOrderType === "Market" ? "text" : "number"
                        }
                      />
                    )}
                  />

                  <div className="text-end mt-4">
                    <Controller
                      name="targetLossOrderType"
                      control={control}
                      render={({ field }) => (
                        <ToggleButton<OrderType>
                          options={["Limit", "Market"]}
                          selectedOption={field.value}
                          onToggle={(value) => {
                            field.onChange(value); // Update the form field value
                            if (value === "Market") {
                              setValue("targetPrice", "Market");
                            } else {
                              const targetTriggerPrice =
                                watch("targetTriggerPrice");
                              setValue("targetPrice", targetTriggerPrice || "");
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div
          className="text-color mt-4"
          style={{ fontSize: 12, lineHeight: "16px", textAlign: "left" }}
        >
          {GttOrderCreateTerms}
          <a
            style={{ color: "#064AD3" }}
            target="_blank"
            href="https://support.rupeezy.in/support/solutions/articles/21000002688-gtt-orders"
          >
            Learn more about GTT
          </a>
        </div>
      </div>
      <div
        style={{ height: 60 }}
        className="px-5 alerts-create-edit-background d-flex justify-content-end gap-3 py-2"
      >
        <button
          type="submit"
          className={`btn-alert border-0 ${
            tradeDirection === "Buy"
              ? "btn tv-btn-success"
              : "btn tv-btn-danger"
          }`}
          style={{
            cursor: isButtonDisabled ? "not-allowed" : "pointer",
          }}
          disabled={isButtonDisabled}
        >
          {isSubmitting || isLoading ? "Creating..." : "Create GTT"}
        </button>
      </div>
    </form>
  );
};

export default CreateGTT;
