import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import OptionStrategyHeader from "../OptionStrategyHeader"
import EachOption from "./EachOption";
import {setCurrentExpiryDate,optionStoreExecute,optionBuilderExecute,setStrategyState,CloseOptionStrategy} from "../../../slices/optionStrategySlice";
import ReactDragListView from "react-drag-listview";
import nextId from "react-id-generator";
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {BrokerApi} from "../../../chart-brokerapi/broker";
import {RootState} from "../../../store"
import { StrategyState, LegDetails } from '../../../models/strategy';
import { ChartStateParams } from "../../../models/orders"




const ExectionScreen = ({})=>{

    const {current_multiplier,available_funds,required_margin,initial_margin, executable_options,current_strategy_state,current_symbol_details} = useSelector((state:RootState) => state.optionStrategy);
    const dispatch = useDispatch()

    const [executableOptions,setExecutableOptions]=useState<LegDetails[]>(executable_options?.options || [])

    useEffect(()=>{
        if(executableOptions) executionBody()
    },[executableOptions])


    const executeFullOrder=()=> {
        executableOptions?.map((d,index)=>{
            singleOrderExecution(d,index)
        })
        let state:StrategyState = {id:1,url:'tools'}
        dispatch(setStrategyState(state));
        // dispatch(CloseOptionStrategy())
        (dispatch(setCurrentExpiryDate(null)))
        if(current_strategy_state.url=="execution"){
            dispatch(optionStoreExecute())
        }else{
            dispatch(optionBuilderExecute())
        }
    }

    const singleOrderExecution=(order:any,index:number)=>{
            let listenerId:number =  index;
            let orderData:ChartStateParams = {
                symbol: current_symbol_details?.sExchange?.slice(0,3)+":"+order.option.security_description,
                side: order.action=='BUY'?1:-1,
                margin: 3,
                qty: (order.quantity/order.option.lot_size)*current_multiplier,
                limitPrice: 0,
                triggerPrice: 0,
                order_type: '2',
                validity: 1,
                is_amo: false,
                origin:'strategy'
            }
            BrokerApi.instance?.placeOrder(orderData,String(listenerId)).then(()=>{
                console.log(`ORDER FROM STRATEGY ${listenerId}`);
                // window.GtmPusher({
                //     event: 'tv-order-placed-basketMulti'
                // })
            })
    }

    let dragProps = {
        onDragEnd(fromIndex:number, toIndex:number) {
            const data:LegDetails[] = [...executableOptions];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            setExecutableOptions(data)
        },
        handleSelector: ".grab",
        nodeSelector:'.each_box',
        enableScroll:true,
    };

    const onBackClick=()=>{
        if(current_strategy_state.url=="builderExecution"){
            let state = {id:7,url:'builderStrategyDetails'}
            dispatch(setStrategyState(state))
        }else{
            let state = {id:4,url:'strategyDetails'}
            dispatch(setStrategyState(state))
        }
    }

    const getBottom=()=>{
        return(
            <div className='bottom-area pt-4' style={{"alignItems":"center"}}>
            <div className='container'>
                <div className='row'>
                    <div className='col-6' >
                        <div className='details'>
                        <div className='pl-3'>
                                <div className='detailshead'>Initial </div>
                                <div className='ltp_change' style={{"textAlign":"left"}}>{((initial_margin as number)*(current_multiplier?current_multiplier:1)).toFixed(2)}</div>
                            </div>
                            <div className='pl-3'>
                                <div className='detailshead'>Required</div>
                                <div className='ltp_change' style={{"textAlign":"left"}}>{((required_margin as number)*(current_multiplier?current_multiplier:1)).toFixed(2)}</div>
                            </div>
                            <div style={{"paddingLeft":"20%"}}>
                                <div className='detailshead'>Available</div>
                                <div className='ltp_change' style={{"textAlign":"left"}}>{available_funds?available_funds:0}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='continue2 c-pointer no-drag' onClick={executeFullOrder}>
                            Execute
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    const executionBody = () =>{
        return(
            executableOptions?.map((item,index) => {
                const id1 = nextId("BE-");
                return(
                    <EachOption data={item} index={index}/>

                    )
            })
        )
    }

    return(
        <React.Fragment>
            <OptionStrategyHeader>
                <div onClick={onBackClick} className='flex headerText no-drag'> <i  className="fa fa-arrow-left c-pointer" aria-hidden="true"/>&nbsp;<span className=' px-2'>{executable_options?.strategy_name}</span></div>
            </OptionStrategyHeader>
            <div className="d-flex flex-column justify-content-start align-items-start m-4">
            <div className="d-flex scroll_box2 my_scroll_track flex-column align-items-stretch w-100 justify-content-between">
                <ReactDragListView   {...dragProps}>
                    <table className="baskTable">
                        <thead className="card_bg">
                        <tr className="currentText">
                            <th>&nbsp;</th>
                            <th>Name</th>
                            <th>LTP</th>
                            <th>Price</th>
                            <th>Qty.</th>
                        </tr>
                        </thead>

                        <tbody>
                            {executionBody()}
                        </tbody>

                    </table>
                </ReactDragListView>
            </div>
        </div>
            {getBottom()}
        </React.Fragment>
    )
}

export default ExectionScreen