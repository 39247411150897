interface ValueValidationParams {
    symbol: string;
    ltp: number;
    value: number;
    property: string;
    volume: number;
  }
  
  export const valueValidation = ({ symbol, ltp, value, property,volume }: ValueValidationParams): string => {
    // Return early if any required values are missing
    if (!symbol || !value) {
      return '';
    }
  
    // Handle volume property separately
    if (property === 'VOLUME' && volume > value) {
      return `Volume must be > ${volume}`;
    }
  
    // Handle price-based validations
    switch (symbol) {
      case 'GE':
        return value <= ltp ? `Trigger price must be > ${ltp}` : '';
      case 'LE':
        return value >= ltp ? `Trigger price must be < ${ltp}` : '';
      default:
        return '';
    }
  };
