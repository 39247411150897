/**
 * @module Make sure that you include Promise polyfill in your bundle to support old browsers
 * @see {@link https://caniuse.com/#search=Promise | Browsers with native Promise support}
 * @see {@link https://www.npmjs.com/package/promise-polyfill | Polyfill}
 * **/
import {  ordersPageColumns, positionsPageColumns,fundSummaryColumns,holdingColumns, tradesColumns, gttSummaryColumns} from './columns';
import { Omsfeed } from './internalOmsFeed';
import {ChartBuySell} from '../components/ChartBuySell/index'
import {OrderOMSFeedSub} from "../sub";
import Queue from "queue"
import { logMessage,setOrderProperties } from '../utils/helpers';
import { Brackets,TradeContext } from "../charting_library"
import {DataFeedInstance} from "../chart-datafeed/udf-compatible-datafeed"
import { remoteConfigGetter } from "../utils/remoteConfig";
import { Quotes, ResolveSymbolInfo,TokenInfo } from '../models/symbolData';
import { ProfitLoss } from "../models/charts"
import Subscribed from "../models/symbolData"
import { OrderMarginPayload,OrderMarginResponse,ChartStateParams, OrderPlacementPayload,Positions,PositionDetails,OrderPlacementResponse,DayTrade,Holdings, HoldingData,Trades,TradeData,OrderResponse,Preferences, PreferenceData } from "../models/orders"
import { AllWatchlists,WatchlistsResponse,WatchlistStock,WatchlistStockData,CustomWatchlist, CreateWatchlistPayload } from "../models/watchlists"
import { GttAlert, GTTOrder } from '../models/gttOrders';
import CustomAlertButton, { IconType } from './customHelpers/customElements';
import { _exchangeMap, _getGttOrderStatus, _getOrderStatus, _getRealizedOverallPl, _getRealizedPl, _getUnrealizedOverallPl, _getUnrealizedPl, _marketSegmentId, chopIntegerIntoArray, deleteGTTOrder, fetchAndMapTokensWithAuth, formatNumber, getDatafeedSubscriptionId, sleep } from './broker.helper';
import store from '../store';
import { BrokerApi as BrokerApiInstace} from "../chart-brokerapi/broker";


import { 
    setModal, 
    setGttOrders, 
    getGttOrderById,
    setRecreateNewGTTOrderWithId
} from '../slices/gttSlices';
import { FLOW_BACKEND_BASE_URL } from '../utils/config';
import CustomBuyOrSell from './customHelpers/customBuyOrSell';
import CustomStatus, { BadgeProps } from './customHelpers/customStatus';
import { createGttAlertObject, findNearestParent } from './chart.utils';
import DeleteAlertButton from './customHelpers/DeleteAlertButton';

window.Buffer = window.Buffer || require("buffer").Buffer; 

// export let BrokerApiInstance: BrokerApi |  null;
export class BrokerApi{
        private logout_function: () => void;
        private _accountManagerData: { title: string; overallPl: number; equityPl: number; todayPl: number };
        private _positionById: Record<string, PositionDetails>;
        private _basketLid: Record<string, any>;
        private _positions: PositionDetails[];
        private _holdings: any[];
        private _trades: OrderPlacementPayload[];
        private _nseFundsDetails: any[];
        private _mcxFundsDetails: any[];
        private _combinedFundsDetails: any[];
        private _orderById: Record<string, OrderPlacementPayload>;
        private _tradeById: Record<string, any>;
        private _orderTrades: Record<string, any>;
        private _orders: OrderPlacementPayload[];
        private _executions: any[];
        private _idsCounter: number;
        private _authToken: string;
        private _headers: Record<string, string>;
        private _inhouseHeaders: Record<string, string>;
        private _quotesProvider: any;
        public _host: any;
        public _gttAlerts: GttAlert[];
        private _vortexUrl: string;
        private _inHouseUrl: string;
        private _watchlists: CustomWatchlist[];
        private _accountsMetaInfo: any[];
        private _preferences: PreferenceData
        private _userProfile: any;
        private _shouldFetchGTDOrders: boolean;
        private _amChangeDelegate: any;
        private _holdingsPriceChangeDelegate: any;
        private _gttPriceChangeDelegate: any; 
        private _activeSubscriptionIds: Set<string> = new Set();
        private _fundsChangeDelegate: any;
        private _mcxfundsChangeDelegate: any;
        private _combinedfundsChangeDelegate: any;
        private _tradesChangeDelegate: any;
        private _positionChangeDelegate: any;
        private _closedPositionChangeDelegate: any;
        private _overallPlValue: any;
        private _todayPlValue:any;
        private _equityPlValue: any;
        private _gettingAccountsMetaInfo: any;
        private q:any
        private omsFeed:any
        static instance: BrokerApi | null = null;
    constructor(host:any, quotesProvider:any,vortexUrl:any,authToken:any,odinApiKey:any, inHouseUrl:any, userProfile:any,logout_function:()=>{}) {
        this.logout_function = logout_function
        this._accountManagerData = { title: 'Rupeezy', overallPl: 0, equityPl: 0, todayPl: 0 };
        this._positionById = {};
        this._basketLid = {};
        this._positions = [];
        this._holdings= [];
        this._trades = []
        this._nseFundsDetails= [];
        this._mcxFundsDetails = [];
        this._combinedFundsDetails = [];
        this._orderById = {};
        this._tradeById = {};
        this._orderTrades = {};
        this._orders = [];
        this._executions = [];
        this._idsCounter = 1;
        this._authToken = authToken
        this._headers = {"Authorization": "Bearer "+ authToken, "x-api-key": odinApiKey, "content-type": "application/json"}
        this._inhouseHeaders = {"Authorization":"Bearer "+authToken,"X-Application-Id": String(process.env.REACT_APP_TV_APPLICATION_ID),"X-Stage": String(process.env.REACT_APP_ENV),"x-api-key": odinApiKey}
        this._quotesProvider = quotesProvider;
        this._host = host;
        this._gttAlerts = [];

        
        this._vortexUrl = vortexUrl
        this._inHouseUrl = inHouseUrl+"aux/"
        this._watchlists = []
        this._host.setButtonDropdownActions(this?._buttonDropdownItems());
        this._accountsMetaInfo = []
        this._preferences = {}
        this._userProfile = userProfile
        this._shouldFetchGTDOrders = false 
        const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility();
        if (sellBuyButtonsVisibility !== null) {
            sellBuyButtonsVisibility.subscribe(() => {
                this._host.setButtonDropdownActions(this?._buttonDropdownItems());
            });
        }
        const domPanelVisibility = this._host.domPanelVisibility();
        if (domPanelVisibility) {
            domPanelVisibility.subscribe(() => {
                this._host.setButtonDropdownActions(this?._buttonDropdownItems());
            });
        }
        const orderPanelVisibility = this._host.orderPanelVisibility();
        if (orderPanelVisibility) {
            orderPanelVisibility.subscribe(() => {
                this._host.setButtonDropdownActions(this._buttonDropdownItems());
            });
        }
        this._amChangeDelegate = this._host.factory.createDelegate();
        this._holdingsPriceChangeDelegate = this._host.factory.createDelegate();
        this._gttPriceChangeDelegate = this._host.factory.createDelegate();
        this._fundsChangeDelegate = this._host.factory.createDelegate();
        this._mcxfundsChangeDelegate = this._host.factory.createDelegate();
        this._combinedfundsChangeDelegate = this._host.factory.createDelegate();
        
        this._tradesChangeDelegate = this._host.factory.createDelegate();
        this._positionChangeDelegate = this._host.factory.createDelegate();
        this._closedPositionChangeDelegate = this._host.factory.createDelegate();
        this._overallPlValue = this._host.factory.createWatchedValue(this._accountManagerData.overallPl);
        this._todayPlValue = this._host.factory.createWatchedValue(this._accountManagerData.todayPl);
        this._equityPlValue = this._host.factory.createWatchedValue(this._accountManagerData.equityPl);
        this._amChangeDelegate.subscribe(null, (values:ProfitLoss) => {
            this._overallPlValue.setValue(values.overallPl);
            this._equityPlValue.setValue(values.equityPl);
            this._todayPlValue.setValue(values.todayPl);
        });
        // _getPositions(); 
        this._gettingAccountsMetaInfo =  this._getAccountsMetaInfo()
        this.init()
        BrokerApi.instance = this;
        this.subscribeToGttUpdates();
    }

    init(){
        try {
            this._getOrders().then((_)=>{
                console.log("orders fetched")
            })
            this._getTrades()
            this._getWatchlists()
            this._getHoldings()
            this._getPreferences()
            this.q = Queue({ results: [] })
            this.q.concurrency = 1
            this.q.autostart = true 
            this.q.timeout = 1000
            this.q.on('timeout', function (next:()=>{}, job:string) {
                logMessage(`Queue: Job Timeout: ${job.toString().replace(/\n/g, '')}`)
                next()
              })
            this.q.start()
            this.omsFeed = new Omsfeed(this._host, this, this.q, this._authToken)
        } catch (error:any) {
            console.log("broker error"+ error,error.stack )
        }
        
    }

    setModal(id:string): void {
        store.dispatch(setModal(id));
    }

    setIDForNewGTTOrderWithId(id:string): void {
        // If Order is Trigred then  if user wants to create new order then he can just set the id and all Set
        store.dispatch(setRecreateNewGTTOrderWithId(id));
    }

    setAllGttOrders(orders: GTTOrder[]): void {
        store.dispatch(setGttOrders(orders));
    }

    getAllGttOrders(): GTTOrder[] {
        return store.getState().gttAlerts.gttOrders;
    }

    // This is for GTT : Ends

    connectionStatus() {
        return 1 /* Connected */;
    }
    socket_disconnect(){
        this.omsFeed.disconnectSocket()
    }
    socket_initialize(){
        this.omsFeed.setupSocket()
    }
    isTradable(symbol:string) {
        return this._quotesProvider.resolveSymbol(symbol,()=>{},()=>{}).then((symbolInfo:ResolveSymbolInfo)=>{
            if (symbolInfo.isTradeable != undefined){
                if (! symbolInfo.isTradeable){
                    return Promise.resolve(false)
                }else{
                    return Promise.resolve(true)
                }
            }else{
                return Promise.resolve(true)
            }     
        }).catch((error:any)=>{
            return Promise.resolve(true);
        })
        
    }
    watchlists(){
        return this._getWatchlists()
    }

    async updateWatchlist(listId:number,payload:CreateWatchlistPayload){
        const res = await this._inhouseRequest(this._inHouseUrl, 'watchlist/stocks/' + listId, payload, undefined, 'PUT');
        return await Promise.resolve(res);
    }

    async deleteWatchlist(listId:number){
        const res = await this._inhouseRequest(this._inHouseUrl, 'watchlist/' + listId, undefined, undefined, 'DELETE');
        return await Promise.resolve(res);
    }

    async createWatchlist(payload:CreateWatchlistPayload){
        const res = await this._inhouseRequest(this._inHouseUrl, 'watchlist/stocks', payload, undefined, 'POST');
        return await Promise.resolve(res);
    }

    async subscribeFcmByToken(token:string){
        const res = await this._inhouseRequest(this._inHouseUrl, 'callback/registerFcm', { web_fcm_token: token }, undefined, 'POST');
        return await Promise.resolve(res);
    }
    async unSubscribeFcmByToken(token:string){
        const res = await this._inhouseRequest(this._inHouseUrl, 'callback/deRegisterFcm', { web_fcm_token: token }, undefined, 'POST');
        return await Promise.resolve(res);
    }

    getRequiredMargin(preOrder:OrderMarginPayload){
        const handler = (params:OrderMarginPayload)=> {
            return this._quotesProvider.getTokensFromSymbol([params.symbol]).then((response:TokenInfo[])=>{
                return response[0]
            }).then((symbolData:TokenInfo)=>{
                if (isNaN(Number(params.qty))) {
                    return Promise.resolve(true);
                }
                return this._vortexRequest(this._vortexUrl,'margins/order',
                    {
                        "transaction_type": (params.side == 1 ? "BUY" : "SELL"),
                        "exchange": (_marketSegmentId(String(symbolData.market_segment_id?.toString()))),
                        "quantity": Number(params.qty) ,
                        "old_price": Number(params.oldPrice),
                        "old_quantity": Number(params.oldQty) ,
                        "price": Number(params.limitPrice),
                        "product": (Number(params.margin).toString() == '1' ? "INTRADAY" : (Number(params.margin).toString() == '2' ? 'MTF' : 'DELIVERY') ),
                        "token": parseInt(String(symbolData.token?.toString())), 
                        "mode": params.mode == "M" ? "MODIFY" : "NEW",
                    }
                ,"POST")
            }).then((response:OrderMarginResponse)=> Promise.resolve(response))
            .catch((e:any)=>{
            })
        }
        return handler(preOrder)
    }
    openInNewTab = (url:string) => {
        const newWindow = window.open(url, '_blank')
        // https://aero.rupeezy.in/NetNet/EDISGatewayResponse.aspx
        if (newWindow) newWindow.opener = null
        var aa = setInterval(()=>{
            if(newWindow?.closed){
                clearInterval(aa)
            }
        },1000)
    }
      
    getBrokerage(order:ChartStateParams){
        const payload = {
            exchange:  _marketSegmentId(String(order.market_segment_id)),
            tradeType: (order.margin?.toString() === '1' ? "INTRADAY" : (order.margin?.toString() === '2' ? 'MTF' : 'DELIVERY') ),
            instrumentType:((order.odin_option_type == "CE" ||order.odin_option_type == "PE" ) ? "OPTIONS" : (order.symbol?.substr(order.symbol.length - 3) == "FUT" ? "FUTURES" : "")),
            buySell: (order.side == 1 ? "BUY": "SELL"),
            symbol: order.odin_symbol, 
            price: Number(order.lot_size )*Number(order.market_type == 1 ?  order.limitPrice : order.ltp),
            quantity: Number(order.qty)
        }
        return this._inhouseRequest(this._inHouseUrl, 'orderMargin',payload,"", "POST")
    }

    placeOrder(preOrder:ChartStateParams,listenerId:string='') {
        DataFeedInstance?.resolveSymbol(String(preOrder.symbol),(symbolInfo:ResolveSymbolInfo)=>{
            if (symbolInfo.asmGsmStage){
                if(preOrder.origin){
                    if(preOrder.origin=="buy_sell_button"){
                        var element = document.getElementById("optionChainNew")
                        if(element){
                            element.style.display = "none";
                        }
                        window.tvWidget.showConfirmDialog({title: "Disclaimer",body:`Security is under Surveillance Measure- ${symbolInfo.asmGsmStage}. Do you wish you continue?`,callback: (confirmed)=>{
                            if(confirmed) this.newPlaceOrder(preOrder,listenerId ,symbolInfo)
                            var element = document.getElementById("optionChainNew")
                            if(element){
                                element.style.display = "block";
                            }
                        }})
                        return 
                    }else{
                        this.newPlaceOrder(preOrder,listenerId,symbolInfo).then(()=>{
                            return Promise.resolve("Done")
                        })
                    }
                }else{
                    window.tvWidget.showConfirmDialog({title: "Disclaimer",body:`Security is under Surveillance Measure- ${symbolInfo.asmGsmStage}. Do you wish you continue?`,callback: (confirmed)=>{if(confirmed) this.newPlaceOrder(preOrder,listenerId,symbolInfo)}})
                    return 
                }
            }else{
                this.newPlaceOrder(preOrder,listenerId,symbolInfo).then(()=>{
                    return Promise.resolve("Done")
                })
            }
        },()=>{},'')
        return Promise.resolve("Done")
    }

    newPlaceOrder(preOrder:ChartStateParams,listenerId:string='',symbolInfo:ResolveSymbolInfo){
        const handler = (params:ChartStateParams,listenerId:string='',symbolInfo:ResolveSymbolInfo) => {
            this._host.activateBottomWidget();
            params = setOrderProperties(params)
                this.q.push(function (cb:()=>{}){
                    logMessage(`Broker: Place order started` )
                    const handler = (params:ChartStateParams,listenerId:string='',symbolInfo:ResolveSymbolInfo) => {
                        BrokerApi.instance?._host.activateBottomWidget();
                        let price:number = 0.0
                        price = params.limitPrice || params.seenPrice || 0
                        const order:OrderPlacementPayload = {
                            exchange:symbolInfo.odin_exchange,
                            token: parseInt(String(symbolInfo.odin_token)),
                            transaction_type: (params.side?.toString() === '1' ? "BUY" : "SELL"),
                            product: (params.margin?.toString() === '1' ? "INTRADAY" : (params.margin?.toString() === '2' ? 'MTF' : 'DELIVERY') ),
                            variety: (params.order_type?.toString() === '2' ? "RL-MKT" : (params.order_type?.toString() === '1' ? 'RL' : (params.order_type?.toString() === '3' ? 'SL' : 'SL-MKT'))),
                            quantity: Number((symbolInfo.odin_exchange === "NSE_FO" || symbolInfo.odin_exchange === "BSE_FO") ? Number(params.qty) * Number(symbolInfo.odin_lot_size) : params.qty),
                            price: Number(price),
                            trigger_price: Number(params.triggerPrice) || 0,
                            disclosed_quantity: 0,
                            validity: (params.validity?.toString() === '2' ? "IOC" : (params.validity?.toString() === "3" ? "GTD" : "DAY")),
                            validity_days: (params.validity?.toString() === "3" ? 360 : 0),
                            is_amo: Boolean(params.is_amo),
                            listenerId: Number(listenerId)
                            }
                            BrokerApi.instance?.updateOrder(order,"new",cb)
                        logMessage(`Broker: Place order after transformation: ${JSON.stringify(order)}` )
                        return Promise.resolve("Done")
                    };
    
                    return handler(params,listenerId,symbolInfo);   
                })
            
            return Promise.resolve("Done")
        };
        return handler(preOrder,listenerId,symbolInfo);
    }

    modifyOrder(order:ChartStateParams) {
        const handler = (params:ChartStateParams) => {
            this.q.push(function (cb:()=>{}){
                return BrokerApi.instance?._quotesProvider.resolveSymbol(params.symbol, (symbolInfo:ResolveSymbolInfo)=>{
                    var aa:OrderPlacementPayload = BrokerApi.instance?._orderById[String(order.order_id)] as OrderPlacementPayload
                    return BrokerApi.instance?._quotesProvider.getQuotes([params.symbol], (result:Quotes[])=>{
                        BrokerApi.instance?._host.activateBottomWidget();
                        let price = 0.0
                        if (params.order_type == 'RL-MKT' || params.order_type == "SL-MKT" ||params.order_type?.toString() == '2' || params.order_type?.toString() == '4' ){
                            price = Math.max(result[0].v.ask, result[0].v.bid, result[0].v.lp) 
                        }else{
                            price = params.limitPrice || params.seenPrice || 0
                        }
                        let order_type:string = ""
                        if( params.order_type == "RL-MKT" ||params.order_type == "RL" ||params.order_type == "SL-MKT" ||params.order_type == "SL"){
                            order_type = params.order_type 
                        }else{
                            order_type = (params.order_type?.toString() == '2' ? "RL-MKT" : (params.order_type?.toString() == '1' ? 'RL' : (params.order_type?.toString() == '3' ? 'SL' : 'SL-MKT')))
                        }

                        let validity:string = ""
                        if (params.duration == "GTT"){
                            validity = "GTD"
                        }else{
                            if( params.validity != undefined && !isNaN(Number(params.validity))){
                                validity =  (params.validity == 2 ? "IOC" : params.validity == 3 ? "GTD":"DAY")
                            }else{
                                validity = String(params.duration)
                            }
                        }

                        const order:OrderPlacementPayload = {
                            variety: order_type,
                            quantity: ((symbolInfo.odin_exchange === "NSE_FO" || symbolInfo.odin_exchange === "BSE_FO") ? Number(params.qty) * Number(symbolInfo.odin_lot_size) : Number(params.qty)),
                            price: Number(price),
                            trigger_price: Number(params.triggerPrice) || Number(params.stopPrice) || 0,
                            validity: validity,
                            validity_days: aa?.validity_days,
                            disclosed_quantity: 0,
                            traded_quantity: Number(aa.traded_quantity == null ? 0 :  ((symbolInfo.odin_exchange === "NSE_FO" || symbolInfo.odin_exchange === "BSE_FO") ? Number(aa.traded_quantity) * Number(symbolInfo.odin_lot_size) : aa.traded_quantity) ),
                            order_id: String(params.order_id),
                            market_segment_id: symbolInfo.odin_market_segment_id
                          }
                            BrokerApi.instance?.updateOrder(order,"modify",cb);
                        return Promise.resolve("Done")
                    }
                        
                    )
                }, undefined, undefined)
            })
            
            
        };
        handler(order)
        return Promise.resolve("Done");
    }
    editPositionBrackets(positionId:number, positionBrackets:Brackets) {
        const handler = (id:number, brackets:Brackets) => {
            const position = this._positionById[id];
            if (position) {
                const modifiedPosition = Object.assign({}, position);
                modifiedPosition.takeProfit = (brackets && brackets.takeProfit) || (position && position.takeProfit) || null;
                modifiedPosition.stopLoss = (brackets && brackets.stopLoss) || (position && position.stopLoss) || null;
                this._updatePosition(modifiedPosition);
            }
            return Promise.resolve();
        };
        return handler(positionId, positionBrackets);
    }
    closePosition(positionId:string,qty:number) {
        const position = this._positionById[positionId];
        var product:string = positionId.split("|")[1]
        var margin:string = ""
        if(product == "INTRADAY"){
            margin = '1'
        }else if(product == "DELIVERY"){
            margin = '3'
        }else{
            margin = '2'
        }
        const handler = (position:ChartStateParams,margin:string) => {
            window.GtmPusher({
                event: 'tv-closed-position'
            })
            return this.placeOrder({
                symbol: position.symbol,
                side: position.side === -1 /* Sell */ ? 1 /* Buy */ : -1 /* Sell */,
                order_type: '2' /* Market */,
                qty: qty || position.qty,
                margin: Number(margin)
            });
        };
        return handler(position,margin);
    }
    orders() {
        return Promise.resolve(this._getOrders());
    }
    positions() {
        return Promise.resolve(this._getPositions());
        // return Promise.resolve(this._positions.slice());
    }

    chartContextMenuActions(e:TradeContext,t:any){
        var aa = []
        aa.push({
            text: "Create New Buy Order ..",
            checkable: false,
            checked: false,
            symbolInfo: this.symbolInfo(e.symbol),
            checkedStateSource: ( function(_:any){}), 
            action:( function(_:any){ 
                ChartBuySell.instance?.ShowOrderDialog({
                    symbol: e.symbol, 
                    limitPrice: Number(e.formattedValue),
                    side: 1
                },()=>{})
            }),
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M19.9792 16.6205C19.7396 16.8955 19.3241 16.9285 19.044 16.6948L14.3924 12.8117L14.072 12.5442L13.7516 12.8117L9.10009 16.6947C8.82008 16.9285 8.40456 16.8955 8.16495 16.6205C7.92467 16.3447 7.94981 15.9272 8.22144 15.6822L14.0721 10.4057L19.9227 15.6822C20.1943 15.9272 20.2195 16.3447 19.9792 16.6205ZM18.4032 17.4624C19.1009 18.0448 20.1362 17.9626 20.7332 17.2774C21.3318 16.5902 21.2692 15.55 20.5924 14.9396L14.407 9.36109L14.0721 9.05908L13.7373 9.36109L7.55171 14.9396C6.87492 15.55 6.81229 16.5902 7.41096 17.2774C8.00796 17.9626 9.04326 18.0448 9.74094 17.4624L14.072 13.8468L18.4032 17.4624Z"></path></svg>'
        },
             {
                text: "Create New Sell Order ..",
                checkable: false,
                checked: false,
                symbolInfo: this.symbolInfo(e.symbol),
                checkedStateSource: ( function(_:any){}),
                action:( function(_:any){
                    ChartBuySell.instance?.ShowOrderDialog({
                        symbol: e.symbol, 
                        limitPrice: Number(e.formattedValue),
                        side: -1
                    },()=>{})
                }),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M19.9792 12.2892C19.7396 12.0142 19.3241 11.9812 19.044 12.2149L14.3924 16.098L14.072 16.3655L13.7516 16.098L9.10009 12.2149C8.82008 11.9812 8.40456 12.0142 8.16495 12.2892C7.92467 12.565 7.94981 12.9825 8.22144 13.2275L14.0721 18.504L19.9227 13.2275C20.1943 12.9825 20.2195 12.565 19.9792 12.2892ZM18.4032 11.4472C19.1009 10.8648 20.1362 10.9471 20.7332 11.6323C21.3318 12.3195 21.2692 13.3597 20.5924 13.9701L14.407 19.5486L14.0721 19.8506L13.7373 19.5486L7.55171 13.9701C6.87492 13.3597 6.81229 12.3195 7.41096 11.6323C8.00796 10.9471 9.04326 10.8648 9.74094 11.4473L14.072 15.0628L18.4032 11.4472Z"></path></svg>'
            },
            {
                text: "Create New Buy SL Order ..",
                checkable: false,
                checked: false,
                symbolInfo: this.symbolInfo(e.symbol),
                checkedStateSource: ( function(_:any){}),
                action:( function(_:any){
                    
                    BrokerApi.instance?.symbolInfo(e.symbol).then((res)=>{
                        ChartBuySell.instance?.ShowOrderDialog({
                            symbol: e.symbol, 
                            limitPrice: Number((parseFloat(e.formattedValue) + 10 * res.minTick).toFixed(2)),
                            stopPrice: Number(e.formattedValue),
                            side: 1,
                            order_type: "SL"
                        },()=>{})
                    })
                    
                }),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M19.9792 16.6205C19.7396 16.8955 19.3241 16.9285 19.044 16.6948L14.3924 12.8117L14.072 12.5442L13.7516 12.8117L9.10009 16.6947C8.82008 16.9285 8.40456 16.8955 8.16495 16.6205C7.92467 16.3447 7.94981 15.9272 8.22144 15.6822L14.0721 10.4057L19.9227 15.6822C20.1943 15.9272 20.2195 16.3447 19.9792 16.6205ZM18.4032 17.4624C19.1009 18.0448 20.1362 17.9626 20.7332 17.2774C21.3318 16.5902 21.2692 15.55 20.5924 14.9396L14.407 9.36109L14.0721 9.05908L13.7373 9.36109L7.55171 14.9396C6.87492 15.55 6.81229 16.5902 7.41096 17.2774C8.00796 17.9626 9.04326 18.0448 9.74094 17.4624L14.072 13.8468L18.4032 17.4624Z"></path></svg>'
            },
            {
                text: "Create New Sell SL Order ..",
                checkable: false,
                checked: false,
                symbolInfo: this.symbolInfo(e.symbol),
                checkedStateSource: ( function(_:any){}),
                action:( function(_:any){
                    BrokerApi.instance?.symbolInfo(e.symbol).then((res)=>{
                        ChartBuySell.instance?.ShowOrderDialog({
                            symbol: e.symbol, 
                            limitPrice: Number((parseFloat(e.formattedValue) - 10 * res.minTick).toFixed(2)),
                            stopPrice: Number(e.formattedValue),
                            side: -1,
                            order_type: "SL"
                        },()=>{})
                    })
                    
                }),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M19.9792 12.2892C19.7396 12.0142 19.3241 11.9812 19.044 12.2149L14.3924 16.098L14.072 16.3655L13.7516 16.098L9.10009 12.2149C8.82008 11.9812 8.40456 12.0142 8.16495 12.2892C7.92467 12.565 7.94981 12.9825 8.22144 13.2275L14.0721 18.504L19.9227 13.2275C20.1943 12.9825 20.2195 12.565 19.9792 12.2892ZM18.4032 11.4472C19.1009 10.8648 20.1362 10.9471 20.7332 11.6323C21.3318 12.3195 21.2692 13.3597 20.5924 13.9701L14.407 19.5486L14.0721 19.8506L13.7373 19.5486L7.55171 13.9701C6.87492 13.3597 6.81229 12.3195 7.41096 11.6323C8.00796 10.9471 9.04326 10.8648 9.74094 11.4473L14.072 15.0628L18.4032 11.4472Z"></path></svg>'
            },
            {
                text: "Create New GTT Order",
                checkable: false,
                checked: false,
                symbolInfo: this.symbolInfo(e.symbol),
                checkedStateSource: ( function(_:any){}),
                action:( function(_:any){
                    BrokerApi.instance?.symbolInfo(e.symbol).then((res)=>{
                        ChartBuySell.instance?.ShowOrderDialog({
                            symbol: e.symbol,
                            limitPrice: Number((parseFloat(e.formattedValue) - 10 * res.minTick).toFixed(2)),
                            stopPrice: Number(e.formattedValue),
                            side: -1,
                            order_type: "RL",
                            is_gtt_order: true
                        },()=>{})
                    })

                }),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="44" height="44"><path fill="currentColor" d="M35 14.66 29.16 9l-.7.72 5.84 5.66.7-.72ZM9 14.66 14.84 9l.7.72-5.84 5.66-.7-.72ZM22 16v7h-5v1h6v-8h-1Z"></path><path fill="currentColor" fill-rule="evenodd" d="M22 33a11 11 0 1 0 0-22 11 11 0 0 0 0 22Zm0-1a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z"></path></svg>'
            }
            )


        return Promise.resolve(aa)
    }
    executions(symbol:string) {
        return Promise.resolve(this._executions
            .filter((data) => {
            return data.symbol === symbol;
        }));
    }
    async addExecutionWithoutRefresh(execution:OrderPlacementPayload){
        return this._quotesProvider.tokenInfo({market_segment_id:execution.exchange, token: execution.token }).then((symbol:TokenInfo)=>{
            execution.exchange = _marketSegmentId(String(execution.exchange))
            execution.symbol = symbol.symbol
            this._executions.push(execution)
            this._tradeById[Number(execution.id)] = execution
            this._trades.push(execution)
            this._host.executionUpdate(execution)


            this._tradesChangeDelegate.fire(execution)
            return Promise.resolve()
        })
    }
    async addExecution(execution:OrderPlacementPayload){
        this._quotesProvider.tokenInfo({market_segment_id:execution.market_segment_id, token: execution.token }).then((symbol:TokenInfo)=>{
            execution.symbol = symbol.symbol
            this._executions.push(execution)
            this._tradeById[String(execution.id)] = execution
            this._trades.push(execution)
            this._host.executionUpdate(execution)


            this._tradesChangeDelegate.fire(execution)
            return Promise.resolve()
        }).then((_:any)=>{
            return this._refreshPositions()
        }).then(()=>{
            return this._refreshOrders()
        }).then((pos:any)=>{
            return Promise.resolve(pos)
        }).catch((e:any)=>{
            Promise.reject(e)
        })
        
    }

    async _refreshOrders(){
        this._orders = [];
        this._orderById = {}
        await this._getOrders()
        for(const order of this._orders){
            this._host.orderUpdate(order)
            let lid_index =  this._basketLid[String(order.id)]
            if ( lid_index !== 'undefined' && lid_index  !== "" ){
                order.listenerId = lid_index
                OrderOMSFeedSub.NotifyOrder({order: order})
            }
        }
        Promise.resolve(this._orders);
    }
    async _refreshPositions(){
        await sleep(2000)
        this._positions = [];
        this._positionById = {}
        Promise.resolve(this._getPositions());
    }

    removeOrderLids(lids:any){
        var new_map = this._basketLid
        Object.keys(new_map).forEach(function(key,index){
            if (lids.includes(new_map[key] )){
                new_map[key] = undefined
            }
        })
        this._basketLid = new_map
    }
    
    reversePosition(positionId:string) {
        const position = this._positionById[positionId];
        if(position.product.toUpperCase() == "MTF" || position.product.toUpperCase() == "DELIVERY"){
            return Promise.reject("Cannot reverse DELIVERY or MTF positions")
        }
        const handler = () => {
            window.GtmPusher({
                event: 'tv-reversed-position'
            })
            return this.placeOrder({
                symbol: position.symbol,
                side: position.side === -1 /* Sell */ ? 1 /* Buy */ : -1 /* Sell */,
                type: 2 /* Market */,
                qty: position.qty * 2,
                margin: 1, 
                order_type: '2',
                validity: 2

            });
        };
        return handler();
    }
    cancelOrder(orderId:string) {
        const order:OrderPlacementPayload = this._orderById[orderId];
        const handler = async() => {
            this.q.push(function (cb:()=>{}) {
                order.status = 1 /* Canceled */;
                var url 
                if( order.validity == "GTT"){
                    var ordersArr  = String(order.id).split("_")
                    url = 'trading/orders/gtt/'+ordersArr[0]
                }else{
                    var encodedParam = encodeURIComponent(String(order.id));
                    url = 'trading/orders/regular/'+encodedParam
                }
                
                return BrokerApi.instance?._vortexRequest(BrokerApi.instance?._vortexUrl,url,undefined,'DELETE').then((response)=>{
                    return response
                }).then(()=>{
                    order.status = 1
                    BrokerApi.instance?.updateOrder(order,"cancel",()=>{});
                    cb()
                    return Promise.resolve("Order Cancelled")
                })
                .catch((e)=>Promise.reject(e))
            })
            
        };
        return handler();
    }
    cancelOrders(symbol:string, side:number, ordersIds:string[]) {
        const closeHandler = () => {
            return Promise.all(ordersIds.map((orderId) => {
                return this.cancelOrder(orderId);
            })).then(() => { }); // tslint:disable-line:no-empty
        };
        return closeHandler();
    }
    accountManagerInfo() {
        const summaryProps = [
            {
                text: 'Overall Positions P&L',
                wValue: this._overallPlValue,
                formatter: 'fixed', // default value
            },
            {
                text: "MTM",
                wValue: this._todayPlValue,
                formatter: 'fixed', // default value
            },
            {
                text: 'Equity P&L',
                wValue: this._equityPlValue,
                formatter: 'fixed', // default value
            },
        ];
        return {
            accountTitle: 'Astha Trade',
            summary: summaryProps,
            customFormatters: [{
                    name: 'custom_uppercase',
                    format: (inputs:any) => String(inputs.value).toUpperCase(),
                },
                {
                    name: 'custom_integer', 
                    format:(inputs:any)=> parseInt(inputs.value)
                },
                {
                    name: 'custom-gtt-button',
                    formatElement: (dataFields: any) => {
                        const status = dataFields.values[1] as "active" | "triggered" | "deleted"

                        if(status === 'deleted') return <>-</>;

                        
                        
                        const uniqueID = dataFields.values[0];
                        const targetOrder = getGttOrderById(store.getState(), uniqueID);

                        const handleClick = (event: React.MouseEvent) => {
    
                            window.tvWidget.showConfirmDialog({
                              title: "Delete GTT Order",
                              body: "Do You Wish To Delete This Order?",
                              callback: (confirmed: boolean) => {
                                if (confirmed) {
                                  const url = `${FLOW_BACKEND_BASE_URL}/trading/orders/gtt`;
                      
                                  // Call the deleteGTTOrder function only if the user confirmed
                                  deleteGTTOrder(url, uniqueID)
                                    .then(data => {
                                        BrokerApiInstace.instance?._host.showNotification(
                                            data.message,
                                            `${targetOrder?.id} had beed Updated`
                                          );
                                        // rowToDelete?.remove();
                                    })
                                    .catch(error => {
                                        // console.error('Error deleting order:', error);
                                        BrokerApiInstace.instance?._host.showNotification(
                                            "Error deleting GTT Order",
                                            error.message,
                                            `Erroe While Updating the ${targetOrder?.id} `
                                          );
                                    });
                                } else {
                                  console.log('Order deletion canceled.');
                                }
                              }
                            });
                          }

                        const isDisabled = targetOrder?.orders[0].status === 'triggered'
                        const selectFunction = isDisabled ? this.setIDForNewGTTOrderWithId : this.setModal
                        if(!targetOrder?.orders[0].status) return;
                        return <>
                                <CustomAlertButton iconType={isDisabled ? IconType.ReCreate : IconType.Edit} onClick={()=> selectFunction(uniqueID)}></CustomAlertButton>
                                ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ 
                                <DeleteAlertButton status={targetOrder?.orders[0].status} isDisabled={targetOrder?.orders[0].status === 'triggered'} onClick={handleClick}></DeleteAlertButton>
                            </>;
                    }
                },
                {
                    name: 'custom-buy-sell-button',
                    formatElement: (dataFields: any) => {
                        const tradingDirction = dataFields.values[0];
                        return <CustomBuyOrSell >{tradingDirction}</CustomBuyOrSell>;
                    }
                },
                {
                    name: 'active-inactive-badge',
                    formatElement: (dataFields: any) => {
                        const tradingDirction = dataFields.values[0] as BadgeProps['Status'];
                        return <CustomStatus Status={tradingDirction} />;
                    }
                },
                {
                    name: 'delete-gtt-button',
                    formatElement: (dataFields: any) => {
                        const status = dataFields.values[1] as "active" | "triggered" | "deleted"

                        if(status === 'deleted') return <>-</>;

                        const uniqueID = dataFields.values[0];
                      
                      const targetOrder = getGttOrderById(store.getState(), uniqueID);
                      const handleClick = (event: React.MouseEvent) => {
                        // Get the clicked DOM node
                          const clickedNode = event.target as HTMLElement;

                        // Step 1: Find the nearest SVG element
                        const svgNode = clickedNode.tagName.toLowerCase() === 'svg'
                            ? clickedNode
                            : findNearestParent(clickedNode, 'svg');

                        if (!svgNode) return
                        // Step 2: From SVG, find the nearest parent <td>
                        const tdNode = findNearestParent(svgNode.parentElement, 'td');
                        if (!tdNode) return;
                        // Step 3: From TD, find the nearest parent <tr>
                        const trNode = findNearestParent(tdNode.parentElement, 'tr');
                        if (!trNode) return
                        const tableBody = trNode.parentNode as HTMLElement;
                        if (!tableBody) return

                        const rowToDelete = tableBody.querySelector(`tr[data-row-id="${uniqueID}"]`);

                  
                        window.tvWidget.showConfirmDialog({
                          title: "Delete GTT Order",
                          body: "Do You Wish To Delete This Order?",
                          callback: (confirmed: boolean) => {
                            if (confirmed) {
                              const url = `${FLOW_BACKEND_BASE_URL}/trading/orders/gtt`;
                  
                              // Call the deleteGTTOrder function only if the user confirmed
                              deleteGTTOrder(url, uniqueID)
                                .then(data => {
                                    BrokerApiInstace.instance?._host.showNotification(
                                        data.message,
                                        1
                                      );
                                    // rowToDelete?.remove();
                                })
                                .catch(error => {
                                    // console.error('Error deleting order:', error);
                                    BrokerApiInstace.instance?._host.showNotification(
                                        "Error deleting GTT Order",
                                        error.message,
                                        0
                                      );
                                });
                            } else {
                              console.log('Order deletion canceled.');
                            }
                          }
                        });
                      };

                      if(!targetOrder?.orders[0].status) return;
                  
                      return <DeleteAlertButton status={targetOrder?.orders[0].status} isDisabled={targetOrder?.orders[0].status === 'triggered'} onClick={handleClick}></DeleteAlertButton>;
                    }
                }
                  
            ],
            // ordersPage: {
            //     orderColumns: ordersPageColumns
            // },
            orderColumns: ordersPageColumns,
            positionColumns: positionsPageColumns,
            // orderColumnss: positionsPageColumns,
            pages: [
                {
                    id: 'trades', 
                    title: 'Trades',
                    tables: [
                        {
                            id: 'trades', 
                            columns: tradesColumns, 
                            getData: ()=> {
                                return this._getTrades();
                            },
                            changeDelegate: this._tradesChangeDelegate,
                        }
                    ]
                },
                {
                    id: 'funds',
                    title: 'Funds',
                    tables: [
                        {
                            id: 'combinedfundsummary',
                            title: "COMBINED FUNDS",
                            columns: fundSummaryColumns,
                            getData: () => {
                                return this._getCombinedFunds();
                            },
                            initialSorting: {
                                columnId: 'id',
                                asc: true
                            },
                            changeDelegate: this._combinedfundsChangeDelegate,
                        }
                    ],
                },
                {
                    id: 'holdings',
                    title: 'Holdings',
                    tables: [
                        {
                            id: 'holdings',
                            columns: holdingColumns,
                            getData: () => {
                                return  this._getHoldings();
                            },
                            initialSorting: {
                                columnId: 'id',
                                asc: false,
                            },
                            changeDelegate: this._holdingsPriceChangeDelegate,
                        },
                    ],
                },
                {
                    id: 'gtt',
                    title: 'GTT Orders',
                    tables: [
                        {
                            changeDelegate: this._gttPriceChangeDelegate,
                            columns: gttSummaryColumns,
                            id: 'gtt',
                            getData: () => {
                                return  this._getAllGtt();
                            },
                        },
                    ],
                }
            ],
            contextMenuActions: (contextMenuEvent:any, activePageActions:any) => {
                return Promise.resolve(this._bottomContextMenuItems(activePageActions));
            },
        };
    }
    async symbolInfo(symbol:string) {
        const mintick = await this._host.getSymbolMinTick(symbol);
        const pipSize = mintick; // pip size can differ from minTick
        const accountCurrencyRate = 1; // account currency rate
        const pointValue = 1; // USD value of 1 point of price
        return {
            qty: {
                min: 1,
                max: Number.MAX_VALUE,
                step: 1,
            },
            pipValue: pipSize * pointValue * accountCurrencyRate || 1,
            pipSize: pipSize,
            minTick: mintick,
            description: '',
        };
    }
    currentAccount() {
        return '1';
    }

    async _getAccountsMetaInfo(){
        // var aa = [{
        //     id: '1',
        //     name: "Shauryam Gupta"
        // }]
        // this._accountsMetaInfo = aa
        // return Promise.resolve(aa)
        return this._vortexRequest(this._vortexUrl,'user/profile', undefined,'GET').then(response=>{
            if (response.profile != 'undefined'){
                
                var aa = [{
                    id: '1',
                    name: response.profile.name
                }]
                this._accountsMetaInfo = aa
                return Promise.resolve(aa)
            }else{
                return Promise.reject(response)
            }
        }).catch((e)=> Promise.reject(e))
    }
    async accountsMetainfo() {
        var aa =  await this._gettingAccountsMetaInfo.then((_:any)=>{
            return Promise.resolve(this._accountsMetaInfo)
        })
        return aa
        
        // return [
        //     {
        //         id: '1',
        //         name: 'Test account',
        //     },
        // ];
    }
    _bottomContextMenuItems(activePageActions:any) {
        const separator = { separator: true };
        const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility();
        if (activePageActions.length) {
            activePageActions.push(separator);
        }
        return activePageActions.concat([
            {
                text: 'Show Buy/Sell Buttons',
                action: () => {
                    if (sellBuyButtonsVisibility) {
                        sellBuyButtonsVisibility.setValue(!sellBuyButtonsVisibility.value());
                    }
                },
                checkable: true,
                checked: sellBuyButtonsVisibility !== null && sellBuyButtonsVisibility.value(),
            },
            {
                text: 'Trading Settings...',
                action: () => {
                    this._host.showTradingProperties();
                },
            },
        ]);
    }
    _buttonDropdownItems() {
        const defaultActions = this._host.defaultDropdownMenuActions();
        return defaultActions.concat([
            {
                text: 'Trading Settings...',
                action: () => {
                    this._host.showTradingProperties();
                },
            },
        ]);
    }
    _updateOrderLast(order:any) {
        this._host.orderPartialUpdate(order.id, { last: order.last });
    }
    updateOrder(order:OrderPlacementPayload,type:string, callback:()=>void) {
        if(type=="new"){
            return this._placeOrder(order,callback)
            
        }else if(type == "modify"){
            return this._modifyOrder(order,callback)
        }else if (type == "sl-update"){
            const hasOrderAlready = Boolean(this._orderById[String(order.id)]);
            if (hasOrderAlready) {
                var changeIdx:number=0
                this._orders.forEach(function(currentValue, index, arr){
                    if( currentValue.id == order.id){
                        changeIdx = index
                    }
                })
                var oldOrder = this._orders[changeIdx]
                oldOrder.type = order.type
                oldOrder.variety =  order.variety
                this._orders[changeIdx] = oldOrder
                this._host.orderUpdate(oldOrder);
                this._host.showNotification("Stoploss Trigerred",oldOrder.id,1)
            }
        }else{
            this._quotesProvider.resolveSymbol(order.symbol, (symbolInfo:ResolveSymbolInfo)=>{
                // order.exchange = _marketSegmentId(order.exchange)
                var qty_divisor:number = 1 
                if (order.exchange == "NSE_FO" || order.exchange == "BSE_FO" ){
                    qty_divisor = Number(symbolInfo.odin_lot_size)
                }
                order.remQty = Number(order.remQty)/qty_divisor
                order.traded_quantity = Number(order.traded_quantity)/qty_divisor
                order.qty = Number(order.qty)/qty_divisor
                order.lot_size = symbolInfo.odin_lot_size
                
                if( order.exchange == "NSE_CUR"){
                    order.limitPrice = Number(order.limitPrice) / 100000
                    order.stopPrice = Number(order.stopPrice) /100000
                }
                const hasOrderAlready = Boolean(this._orderById[String(order.id)]);
                var oldOrder = this._orderById[String(order.id)]
                this._orderById[String(order.id)] = order;
                if (!hasOrderAlready) {
                    
                    order.message = {text: order.displayMessage}
                    order.is_amo = (order.is_amo ? "Yes" : "No")
                    this._orders.push(order)
                    this._host.orderUpdate(order);
                }else{
                    if (oldOrder.displayMessage?.indexOf("Margin Shortfall") != -1){
                        order.displayMessage = oldOrder.displayMessage
                    }else if(oldOrder.message == "Trading in this scrip is not allowed"){
                        order.displayMessage = oldOrder.displayMessage
                    }
                    var changeIdx:number=0
                    this._orders.forEach(function(currentValue, index, arr){
                        if( currentValue.id == order.id){
                            changeIdx = index
                        }
                    })
                    order.is_amo = (order.is_amo ? "Yes" : "No")
                    this._orders[changeIdx] = order
                    this._host.orderUpdate(order);
                }
                let lid_index = this._basketLid[Number(order.id)]
                if (lid_index  !== "" ){
                    order.listenerId = lid_index
                    OrderOMSFeedSub.NotifyOrder({order: order})
                }

                // if( order.status == 5){
                //     this._host.showNotification("Order execution failed",order.displayMessage ,0)
                // }
            })
            
            
            return ; 
        }
    }
    _updatePosition(position:PositionDetails) {
        const hasPositionAlready = Boolean(this._positionById[position.id]);
        if (hasPositionAlready && !position.qty) {
            this._unsubscribeData(position.id);
            const index = this._positions.indexOf(position);
            if (index !== -1) {
                this._positions.splice(index, 1);
            }
            delete this._positionById[position.id];
            this._host.positionUpdate(position);
            return;
        }
        if (!hasPositionAlready) {
            this._positions.push(position);
            this._subscribeData(position.symbol, position.id, (last:any) => {
                if (position.last === last) {
                    return;
                }
                position.last = last;
                position.profit = (position.last - Number(position.price)) * position.qty * (position.side === -1 /* Sell */ ? -1 : 1);
                this._host.plUpdate(position.symbol, position.profit);
                this._host.positionPartialUpdate(position.id, position);
                this._recalculateAMData();
            });
        }
        this._positionById[position.id] = position;
        this._host.positionUpdate(position);
    }
    // Add these console logs
    _subscribeData(symbol: string, id: string, updateFunction: (a: any) => void) {
        this._quotesProvider.subscribeTtQuotes([], [symbol], (symbols: Quotes) => {
            const deltaData = symbols;
            
            if (deltaData.s !== 'ok' && deltaData.s !== 'success') {
                return;
            }
            if (typeof deltaData.v.lp === 'number') {
                updateFunction(deltaData.v);
            }
        }, getDatafeedSubscriptionId(id));
    }

    _unsubscribeData(id:string) {
        this._quotesProvider.unsubscribeQuotes(getDatafeedSubscriptionId(id));
    }
    _recalculateAMData() {
        
        let pl = 0;
        this._positions.forEach((position) => {
            pl += (position.overallPl) || 0;
        });
        // this._accountManagerData.pl = pl;
        this._accountManagerData.overallPl =  pl;

        pl=0;
        this._positions.forEach((position) => {
            pl += (position.totalPl) || 0;
        });

        this._accountManagerData.todayPl =  pl;

        pl = 0 ; 
        this._holdings.forEach((holding)=>{
            pl += holding.unrealized_pnl
        })

        this._accountManagerData.equityPl =  pl;
        this._amChangeDelegate.fire(this._accountManagerData);
    }
    
    async _getOrders(){
        if (this._orders.length == 0){
            var aa = this._vortexRequest(this._vortexUrl, 'trading/orders',{});
            // var bb = this._vortexRequest(this._vortexUrl, 'trading/orders/gtt')
            var temporders:OrderPlacementPayload[] = []
            return Promise.all([aa]).then((res:OrderResponse[]) => {
                this._orders = []
                var promiseArray = []
                
                if( res[0].hasOwnProperty("orders")){
                    for (const order of res[0]["orders"]){
                        var limitPrice = 0
                        var stopPrice = 0
                        
                        if(order.variety == "RL" || order.variety == "RL-MKT"){
                            limitPrice = order.order_price
                        }else if (order.variety == "SL" || order.variety == "SL-MKT"){
                            stopPrice = order.trigger_price
                        }
                        promiseArray.push(this._quotesProvider.tokenInfo({market_segment_id: _exchangeMap(order.exchange), token: order.token }))
                        var shortfall_idx 
                        
                        if( (shortfall_idx = order.error_reason.indexOf("ShortFall=")), shortfall_idx != -1){
                            var shortfall =    order.error_reason.slice(shortfall_idx+10,order.error_reason.length)
                            order.error_reason = "Margin Shortfall of Rs "+shortfall
                        }
                        if(order.error_reason == "New Positions for given scrip are not allowed by  member"){
                            order.error_reason = "Trading in this scrip is not allowed"
                        }
                        var qty_divisor 
                        if (order.exchange == "NSE_FO" || order.exchange == "BSE_FO" ){
                            qty_divisor = order.lot_size
                        }else{
                            qty_divisor= 1 
                        }

                        let no:OrderPlacementPayload = {
                            id: order.order_id, 
                            market_segment_id: Number(_exchangeMap(order.exchange)),
                            token: order.token,
                            // symbol: order.token.toString(),
                            side: (order.transaction_type == "BUY" ? 1 : -1),
                            order_type: (order.variety || "REJECTED"),
                            type: (order.variety) == 'RL-MKT' ? 2 : (order.variety == 'RL' ? 1 : (order.variety == 'SL-MKT' ? 3 : 4)),
                            product: (order.product || ""),
                            qty: order.total_quantity/qty_divisor,
                            remQty: order.pending_quantity/qty_divisor,
                            traded_quantity: order.traded_quantity/qty_divisor, 
                            lot_size: order.lot_size,
                            displayMessage:( order.error_reason == "" ? "Placed" : order.error_reason),
                            message: {text: ( order.error_reason == "" ? "Placed" : order.error_reason)},
                            status: _getOrderStatus(order.status), 
                            tradedPrice: 0, 
                            limitPrice: parseFloat(order.order_price.toString()) || 0.00,
                            stopPrice: parseFloat(order.trigger_price.toString()) || 0.00,
                            order_id: order.order_id,
                            duration: order.validity,
                            exchange: order.exchange,
                            is_amo_order: (order.is_amo ? "Yes" : "No"),
                            exchange_timestamp: order.exchange_timestamp
                        }
                        temporders.push(
                            no
                        )
                }}
                if( res[1] != undefined &&  res[1].hasOwnProperty("data")){
                    for (const gtt_order of res[1]["data"]){
                        for (const order of gtt_order.orders) {
                            
                            var limitPrice:number = order.price
                            var triggerPrice = order.trigger_price
                            promiseArray.push(this._quotesProvider.tokenInfo({market_segment_id: _exchangeMap(gtt_order.exchange), token: gtt_order.token }))
                            let no:OrderPlacementPayload = {
                                id: gtt_order.id +"_"+ (order.id.toString()),
                                market_segment_id: Number(_exchangeMap(gtt_order.exchange)),
                                token: gtt_order.token,
                                // symbol: order.token.toString(),
                                side: (order.transaction_type == "BUY" ? 1 : -1),
                                order_type: (order.variety || "REJECTED"),
                                type: (order.variety) == 'RL-MKT' ? 2 : (order.variety == 'RL' ? 1 : (order.variety == 'SL-MKT' ? 3 : 4)),
                                product: (order.product || ""),
                                qty: order.quantity,
                                remQty: order.quantity,
                                traded_quantity: 0,
                                lot_size: gtt_order.lot_size,
                                displayMessage: ( "Placed" ),
                                message: {text:( "Placed")},
                                status: _getGttOrderStatus(order.status), 
                                tradedPrice: 0, 
                                limitPrice: order.price,
                                stopPrice: order.trigger_price,
                                order_id: gtt_order.id +"_"+ (order.id.toString()),
                                duration: "GTT-"+gtt_order.trigger_type.toUpperCase(),
                                exchange: order.exchange,
                                validity: "GTT", 
                                is_amo_order: "No",
                                exchange_timestamp: order.exchange_timestamp
                            }
                            temporders.push(
                                no
                            )
                        
                        }
                        
                    }
                }
                return Promise.all(promiseArray);
            }).then((res)=>{
                if(this._orders.length==0){
                    temporders.forEach((order:OrderPlacementPayload,i:number)=>{
                        order.symbol = res[i].symbol
                        this._orders.push(
                            order
                        )
                        this._orderById[String(order.order_id)] = order
                    })
                }     
                this._getTrades()
                return Promise.resolve(this._orders)
            })
        }else{
            
            return this._orders
        }
        
    }
    async _getTrades(){
        if (this._trades.length == 0 && Object.keys(this._orderById).length>0){
            return this._vortexRequest(this._vortexUrl, 'trading/trades',{}).then((response:Trades)=>{
                if( response.hasOwnProperty("trades")){
                    response.trades.forEach(async (trade:TradeData)=> {
                        var orderDetails:OrderPlacementPayload=this._orderById[trade.order_id]
                        var t:OrderPlacementPayload = {
                            id: String(trade.trade_no), 
                            exchange: _exchangeMap(String(orderDetails.exchange)),
                            token: orderDetails.token,
                            side: orderDetails.side,
                            type: String(orderDetails.order_type),
                            product: orderDetails.product,
                            tradedQty: trade.trade_quantity,
                            tradedPrice: trade.trade_price, 
                            tradeTime: trade.traded_at,
                            orderNumber: trade.order_id, 
                            time: Date.now()
                        }

                        await this.addExecutionWithoutRefresh(t)
                    })
                }
                return Promise.resolve(this._trades)
            })
        }else{
            return Promise.resolve(this._trades)
        }
    }
    async _getPositions(){
        if(this._positions.length == 0){
            return this._vortexRequest(this._vortexUrl, 'trading/portfolio/positions',{}).then((response:Positions)=>{
                this._positions = []
                if( response.hasOwnProperty("data")){
                    response.data.net.forEach((position:DayTrade)=> {
                            this._quotesProvider.tokenInfo({market_segment_id: _exchangeMap(position.exchange), token: position.token }).then((symbol:TokenInfo)=>{
                                let ini_np:PositionDetails = {
                                    id: symbol.symbol+"|"+position.product,
                                    symbol: String(symbol.symbol),
                                    product: position.product, 
                                    side: (position.quantity > 0 ? 1 : -1),
                                    qty: ( position.exchange == 'NSE_CUR' ? Math.abs(position.quantity) : Math.abs(position.quantity)/Number(position.lot_size)),
                                    lot_size: Number(position.lot_size),
                                    avgPrice: position.overnight_average_price!=0?position.overnight_average_price:position.average_price,
                                    last: Number(position.ltp),
                                    realizedPl: _getRealizedPl(position),
                                    unrealizedPl: _getUnrealizedPl(position, Number(position.ltp)),
                                    totalPl: 0,
                                    overallPl: 0,
                                    buyQty: position.buy_quantity,
                                    buyAvg: position.buy_price, 
                                    sellQty: position.sell_quantity, 
                                    sellAvg: position.sell_price,                                    
                                }
                                this._positions.push(ini_np);
                                this._positionById[ini_np.id] = ini_np
                                this._host.positionUpdate(ini_np);
                                this._subscribeData(String(symbol.symbol),"POS"+ini_np.id, (v:any)=>{
                                    var foundIndex = this._positions.findIndex(x => x.symbol == symbol);
                                    let realizedPl = _getRealizedPl(position)
                                    let unrealizedPl = _getUnrealizedPl(position, parseFloat(v.lp),parseFloat(v.prev_close_price),parseFloat(v.open_price))
                                    let realizedOverallPl = _getRealizedOverallPl(position)
                                    let unrealizedOverallPl = _getUnrealizedOverallPl(position, parseFloat(v.lp))
                                    let np:PositionDetails =  {
                                        id: symbol.symbol+"|"+position.product,
                                        symbol: String(symbol.symbol),
                                        product: position.product, 
                                        side: (position.quantity > 0 ? 1 : -1),
                                        qty: ( position.exchange == 'NSE_CUR' ? Math.abs(position.quantity) : Math.abs(position.quantity)/Number(position.lot_size)),
                                        lot_size: Number(position.lot_size),
                                        avgPrice: position.overnight_average_price!=0?position.overnight_average_price:position.average_price,
                                        last: v.lp,
                                        realizedPl: realizedPl,
                                        unrealizedPl: unrealizedPl,
                                        totalPl: realizedPl + unrealizedPl,
                                        overallPl: realizedOverallPl + unrealizedOverallPl,
                                        buyQty: position.buy_quantity,
                                        buyAvg: position.buy_price, 
                                        sellQty: position.sell_quantity, 
                                        sellAvg: position.sell_price,
                                    }
                                    this._positions[foundIndex] = np
                                    this._positionById[np.id] = np
                                    // this._host.positionUpdate(ini_np);
                                    this._host.plUpdate(np.id, Number(np.overallPl.toFixed(2))) ;
                                    this._host.positionPartialUpdate(np.id, np);
                                    this._recalculateAMData()
                                    // holding.last_price = last
                                })
                            })                        
                        
                    })
                }
                return this._positions;
            })
        }else{
            Promise.resolve(this._positions)
        }
        
    }
     async _getHoldings(){
         if (this._holdings.length == 0){
            return this._vortexRequest(this._vortexUrl, 'trading/portfolio/holdings',{}).then((response:Holdings)=>{
                this._holdings = []
                response.data.forEach((holding:HoldingData)=> {
                    var tvsymbol  = (holding.nse != undefined ? "NSE:"+holding.nse.symbol+"-EQ" : "BSE:"+holding.bse.symbol+"-EQ")
                    this._holdings.push({
                        id: tvsymbol,
                        symbol: tvsymbol,
                        average_price: holding.average_price, 
                        last_price: holding.last_price,
                        quantity: holding.total_free,
                        market_value: Number(holding.total_free)*Number(holding.last_price),
                        days_change: 0,
                        unrealized_pnl: ((holding.last_price)-(holding.average_price))*(holding.total_free),
                        unrealized_pnl_per: ((holding.last_price)-(holding.average_price))*(holding.total_free)/(holding.average_price) * 100,
                        holding_type: holding.product
                        
                    })
                    this._subscribeData(tvsymbol,tvsymbol, (v:Subscribed)=>{
                        if(Number(holding.last_price) === v.lp){
                            return
                        }
                        var foundIndex = this._holdings.findIndex(x => x.id == tvsymbol);
                        this._holdings[foundIndex] = {
                            id: tvsymbol,
                            symbol: tvsymbol,
                            average_price: holding.average_price, 
                            quantity: holding.total_free,
                            market_value: holding.total_free*v.lp,
                            days_change: formatNumber(v.chp),
                            last_price: v.lp,
                            unrealized_pnl: (v.lp-holding.average_price)*holding.total_free,
                            unrealized_pnl_per: (v.lp-holding.average_price)/holding.average_price * 100, 
                            holding_type: holding.product
                        }
                        this._holdingsPriceChangeDelegate.fire({
                            id: tvsymbol,
                            symbol: tvsymbol,
                            average_price: holding.average_price, 
                            quantity: holding.total_free,
                            market_value: Number(holding.total_free)*v.lp,
                            days_change: formatNumber(v.chp),
                            last_price: v.lp,
                            unrealized_pnl: (v.lp-holding.average_price)*holding.total_free,
                            unrealized_pnl_per: (v.lp-holding.average_price)/holding.average_price * 100, 
                            holding_type: holding.product
                        })
                        var pl = 0 ; 
                        this._holdings.forEach((holding)=>{
                            pl += holding.unrealized_pnl
                        })
                        this._accountManagerData.equityPl =  pl;
                        this._amChangeDelegate.fire(this._accountManagerData);
                        // holding.last_price = last
                    })
                })
                
                return this._holdings ;
            })
         }else{ 
            return Promise.resolve(this._holdings)
         }
        
    }
    async _getCombinedFunds(){
        return this._vortexRequest(this._vortexUrl, 'user/funds',{}).then((response)=>{
              this._combinedFundsDetails = []
              var id,sortProp
              var exchange_combined = response?.exchange_combined
              var isTotalRow = false
              if(exchange_combined === undefined){
                  return Promise.resolve(this._combinedFundsDetails)
              }
              for(const key in exchange_combined){
                  switch (key) {
                      case "deposit":
                          id = "Deposit"
                          sortProp = 0 
                          break;
                      case "funds_transferred": 
                          id = "Funds Transferred"
                          sortProp = 1
                          break; 
                      case "collateral": 
                          id = "Collateral"
                          sortProp = 2
                          break; 
                      case "credit_for_sale": 
                          id = "Credit for sale"
                          sortProp = 3
                          break; 
                      case "option_credit_for_sale":
                          id = "Option CFS"
                          sortProp = 4
                          break; 
                      case "limit_utilization":
                          id = "Limit Utilization"
                          sortProp = 5
                          break; 
                      case "funds_withdrawal":
                          id = "Funds Withdrawal"
                          sortProp = 6
                          break; 
                      case "mtm_and_booked_loss":
                          id = "MM Profit/Loss"
                          sortProp = 7
                          break; 
                      case "booked_profit":
                          id = "Booked Profit/Loss"
                          sortProp = 8
                          break; 
                      case "total_trading_power": 
                          id = "Total Trading Power"
                          sortProp = 9 
                          isTotalRow  =true 
                          break ; 
                      case "total_utilization": 
                          id = "Total Utilization"
                          sortProp = 10
                          isTotalRow  =true 
                          break ; 
                      case "net_available": 
                          id = "Net Available"
                          sortProp = 11
                          isTotalRow  =true 
                          break ; 
                      default: 
                          id = ""
                          break;
                  }
                  if(id != ""){
                      this._combinedFundsDetails.push({
                          id: id,
                          value: exchange_combined[key],
                          sortProp: sortProp, 
                          isTotalRow: isTotalRow
                      })
                  }
              }
              console.warn(this._combinedFundsDetails)
              return this._combinedFundsDetails
        })
    }

    async _getAllGtt({ currentGttOrders }: { currentGttOrders?: GTTOrder[] } = {}) {
        try {
          const preresponse: { status: string; data: GTTOrder[] } = currentGttOrders
            ? { status: "success", data: currentGttOrders }
            : await this._vortexRequest(this._vortexUrl, "trading/orders/gtt", {});
      
          // Prepare the payload for fetching token-symbol mappings
          const temp = preresponse.data.map((order: GTTOrder) => ({
            token: order.token.toString(),
            market_segment_id: order.market_segment_id.toString(),
          }));
      
          const data = await fetchAndMapTokensWithAuth({ token_seg_ids: temp });
      
          // Ensure `data` is valid
          if (!data || data.length !== temp.length) {
            throw new Error("Failed to fetch token-to-symbol mappings or mismatched response.");
          }
      
          // Map symbols back to GTT orders
          const mappedResponse = preresponse.data.map((gttOrder: any, index: number) => ({
            ...gttOrder,
            symbol: data[index]?.symbol, // Convert back to number
          }));
      
          if (preresponse.status === "success") {
            this._unsubscribeAll();
      
            const gttOrders = mappedResponse;
            this.setAllGttOrders(gttOrders);
      
            const getAllGttOrders = this.getAllGttOrders();
            this._gttAlerts = [];
      
            for (const gttOrder of getAllGttOrders) {
              const currentObject = createGttAlertObject(gttOrder);
              this._gttAlerts.push(currentObject);
              this._subscribeToSymbol(currentObject.symbol, currentObject, gttOrder.id);
              this._activeSubscriptionIds.add(currentObject.symbol);
            }
      
            // Ensure table updates by firing complete array
            this._gttPriceChangeDelegate.fire([...this._gttAlerts]);
      
            return this._gttAlerts;
          }
      
          return [];
        } catch (error) {
          console.error("Error fetching GTT orders:", error);
          return [];
        }
      }
      
    

    private _subscribeToSymbol(symbol: string, currentObject: any,id?:string) {
        this._subscribeData(symbol, id ?? symbol, (v: Subscribed) => {
            const alertIndex = this._gttAlerts.findIndex(alert => alert.id === currentObject.id);
            if (alertIndex !== -1) {
                // Create the updated alert object
                const updatedAlert = {
                    ...this._gttAlerts[alertIndex],
                    last: v.lp,
                };
                
                // Update the array
                this._gttAlerts[alertIndex] = updatedAlert;
                
                // Fire delegate with just the updated row
                this._gttPriceChangeDelegate.fire(updatedAlert);
            }
        });
    }

    private _unsubscribeAll() {
        this._activeSubscriptionIds.forEach(id => {
            this._quotesProvider.unsubscribeTtQuotes && this._quotesProvider.unsubscribeTtQuotes(getDatafeedSubscriptionId(id));
        });
        this._activeSubscriptionIds.clear();
    }

    // Store subscription to handle updates
    subscribeToGttUpdates() {
        store.subscribe(() => {
            const currentGttOrders = this.getAllGttOrders();
            this._gttAlerts = currentGttOrders.map(gttOrder => createGttAlertObject(gttOrder));

            // First clear all subscriptions
             if(currentGttOrders)  {
                for (const gttOrder of currentGttOrders) {
                    const currentObject = createGttAlertObject(gttOrder);
                    this._subscribeToSymbol(currentObject.symbol, currentObject,gttOrder.id);
                    this._activeSubscriptionIds.add(currentObject.symbol);
                    this._gttPriceChangeDelegate.fire(currentObject);
                }
            }
        });
    }


    async _placeOrder(order:OrderPlacementPayload,callback:()=>void){
        return this._vortexRequest(this._vortexUrl, 'trading/orders/regular', order, "POST").then((response:OrderPlacementResponse)=>{
            if (response.status === 'success'){
                // this._host.showNotification("Order placement","Order placed successfully" ,1)
                this._basketLid[response.data.order_id] = order.listenerId
                OrderOMSFeedSub.NotifyOrder({response: {success: response,type:'Place', listenerId: order.listenerId}})
                callback()
            }else{
                this._host.showNotification("Order placement",response.message ,0)
                OrderOMSFeedSub.NotifyOrder({response: {error: response.message, listenerId: order.listenerId}})
                OrderOMSFeedSub.NotifyOrder({order: {listenerId: order.listenerId,status: 6,order_id: ''}})
                callback()
                if (response.url != undefined){
                    BrokerApi.instance?.openInNewTab(response.url)
                }
                // _triggerFeedIfAmoFail(response.message,order.listenerId)
            }
        }).catch((error)=>{
            
            this._host.showNotification("Order placement failed", error.message,0)
            OrderOMSFeedSub.NotifyOrder({response: {error: error, listenerId: order.listenerId}})
            callback()
        })
    }

    async _modifyOrder(order:OrderPlacementPayload,callback:()=>void){
        var url:string 
        var encodedParam = encodeURIComponent(String(order.order_id));
        url = 'trading/orders/regular/'+encodedParam
        return this._vortexRequest(this._vortexUrl, url, order, "PUT").then((response:OrderPlacementResponse)=>{
            if (response.status != 'success'){
                // this._host.showNotification("Order modification","Order modified successfully" ,1)
                
                this._host.showNotification("Order modification",response.message ,0)
                OrderOMSFeedSub.NotifyOrder({response: {error: response}})
                callback()
                if (response.url != undefined){
                    BrokerApi.instance?.openInNewTab(response.url)
                }
            }else {
                OrderOMSFeedSub.NotifyOrder({response: {success: response,type:'Place'}})
                callback()
            }
        }).catch((error)=>{
            this._host.showNotification("Order modification failed", error.message,0)
             OrderOMSFeedSub.NotifyOrder({response: {error: error}})
             callback()
        })
    }
    async _vortexRequest(odinUrl: string, urlPath: string, body: any, method:string= "GET"): Promise<any> {
        const options: RequestInit = {}; // Use the RequestInit type for fetch options
    
        if (this._headers) {
            options.headers = this._headers;
        }
    
        if (body !== undefined) {
            if(method!="GET")
                options.body = JSON.stringify(body);
        }
    
        options.method = method;
    
        try {
            const response = await fetch(`${odinUrl}${urlPath}`, options);
    
            if (response.status === 401) {
                this.logout_function();
                return;
            }
    
            const responseText = await response.text();
    
            try {
                return JSON.parse(responseText);
            } catch (parseError) {
                console.error("Error parsing response:", parseError);
            }
        } catch (error) {
            console.error("Error in _vortexRequest:", error);
        }
    }
    
    async _inhouseRequest(inHouseUrl: string, urlPath: string, body: any, params: any, method:string = "GET"
    ): Promise<any> {
        const options: RequestInit = {}; // Define fetch options
    
        if (this._inhouseHeaders) {
            options.headers = this._inhouseHeaders;
        }
    
        if (body !== undefined && method !== "GET") {
            options.body = JSON.stringify(body);
        }
    
        const url = new URL(`${inHouseUrl}${urlPath}`);
    
        if (params) {
            url.search = new URLSearchParams(params).toString();
        }
    
        options.method = method;
    
        try {
            const response = await fetch(url.toString(), options);
    
            if (response.status === 401) {
                this.logout_function();
                return null; // Return null explicitly on unauthorized response
            }
    
            const responseText = await response.text();
    
            try {
                return JSON.parse(responseText);
            } catch (parseError) {
                console.error("Error parsing response:", parseError);
                throw new Error("Failed to parse response");
            }
        } catch (error) {
            console.error("Error in _inhouseRequest:", error);
            throw new Error("Request failed");
        }
    }
    

    async _getWatchlists() {
        if (this._watchlists.length === 0) {
            return this._inhouseRequest(this._inHouseUrl, 'watchlists', "", "", "GET").then((response: WatchlistsResponse) => {
                return Promise.all(
                    response.response.map(async (watchlist: AllWatchlists) => {
                        const results = await Promise.all(
                            watchlist.watchlistStocks.map((stock: WatchlistStock) => this._quotesProvider._getSymbolFromTokens({
                                market_segment_id: stock.marketSegmentId.toString(),
                                token: stock.scripToken.toString(),
                            })
                            )
                        );
                        return ({
                            id: watchlist.ID,
                            name: watchlist.watchlistName,
                            readonly: watchlist.defaultProfile,
                            symbols: results.map((aa: WatchlistStockData) => aa.symbol),
                            wlPosition: watchlist.wlPosition,
                        });
                    })
                ).then((processedWatchlists: CustomWatchlist[]) => {
                    this._watchlists = processedWatchlists; // Update all at once
                    return this._watchlists;
                });
            });
        }
        return Promise.resolve(this._watchlists);
    }
    

    async _getPreferences(){
        return this._inhouseRequest(this._inHouseUrl, 'user/preferences',"","","GET").then((response:Preferences)=>{
            this._preferences = response.response
            return Promise.resolve(this._preferences)
        })
    }
    
    async setTheme(theme:string){
        this._preferences.chartTheme = theme
        const response = await this._inhouseRequest(this._inHouseUrl, 'user/preferences', this._preferences, "", "PUT");
        return Promise.resolve(response);
    }
}


export default BrokerApi