import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { useDispatch } from "react-redux";

// Import components and types as needed
import AlertContainer from "../AlertContainer";
import PropertyDropdown from "../common/PropertyDropdown";
import ConditionDropdown from "../common/ConditionDropdown";
import ValueInput from "../common/ValueInput";
import ErrorText from "../common/ErrorText";
import AlertNameInput from "../common/AlertNameInput";
import LastTradedPriceInput from "../common/LastTradedPriceInput";
import NoteInput from "../common/NoteInput";
import { AlertData } from "../../../hooks/api/get/getAlerts";
import { Alerts } from "../AlertTools";
import useUpdateAlert from "../../../hooks/api/put/useUpdateAlert";
import { toggleAlertsListModel } from "../../../slices/alertSlices";
import {
  Conditions,
  conditionToString,
  propertyToStringForEdit,
} from "../../../helper/alert/condition";
import { Quotes } from "../../../models/symbolData";
import { zodResolver } from "@hookform/resolvers/zod";
import BrokerApi from "../../../chart-brokerapi/broker";
import SearchResultsDropdown, {
  SearchArrayType,
} from "../common/SearchResultsDropdown";
import useScripLookUp, {
  ScripLookUpHitSource,
} from "../../../hooks/api/get/getScripLookUp";
import { productCode } from "../../../utils/alertsProductCode";
import nextId from "react-id-generator";
import { DataFeedInstance } from "../../../chart-datafeed/udf-compatible-datafeed";
import MarketSegmentDetailsEdit from "../common/MarketSegmentDetailsEdit";
import Skeleton from "../common/RectangleSkeltion";
import { alertFormSchema } from "./alertFormSchema.model";

interface CreateOrEditFormProps {
  handleAlertsTab: (alerts: Alerts) => void;
  probs: Omit<AlertData, "status">;
}

type AlertFormData = z.infer<typeof alertFormSchema>;

const EditAlertForm: React.FC<CreateOrEditFormProps> = ({
  handleAlertsTab,
  probs,
}) => {
  const [dataForMarketingSegment, setDataForMarketingSegment] =
    useState<ScripLookUpHitSource>();
  const serchType = "All";
  const [selectedToken, setSelectedToken] = useState<number | null>(null);
  const [selectedMarketSegmentId, setSelectedMarketSegmentId] = useState<
    number | null
  >(null);
  const [isnIsIndex, setIsnIsIndex] = useState<boolean>(false);

  const hasBeenMountedRef = useRef(false);
  const isUpdatingFromValueInr = useRef(false);
  const isUpdatingFromLastTradedPrice = useRef(false);

  const [selectedMarketSegment, setSelectedMarketSegment] = useState<
    Quotes["v"] | null
  >(null);


  const memoizedSelectedMarketSegment = useMemo(() => {
    if (!selectedMarketSegment) return null;

    if (hasBeenMountedRef.current) {
      const payload = {
        volume: selectedMarketSegment.volume,
        lp: selectedMarketSegment.lp,
      };
      return payload;
    }

    return null;
  }, [hasBeenMountedRef.current]);

  const dispatch = useDispatch();
  const { mutate } = useUpdateAlert();

  const propertyOptions = useMemo(() => {
    return isnIsIndex
      ? [
        { value: "", label: propertyToStringForEdit("") },
        { value: "LTP", label: propertyToStringForEdit("LTP") },
      ]
      : [
        { value: "", label: propertyToStringForEdit("") },
        { value: "LTP", label: propertyToStringForEdit("LTP") },
        { value: "AVGPRICE", label: propertyToStringForEdit("AVGPRICE") },
        { value: "VOLUME", label: propertyToStringForEdit("VOLUME") },
      ];
  }, [isnIsIndex]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<AlertFormData>({
    resolver: zodResolver(alertFormSchema),
    mode: "onChange",
    defaultValues: {
      alertName: "",
      property: "",
      condition: "",
      valueInr: "",
      lastTradedPrice: "",
      note: "",
      volume: undefined,
      ltp: undefined,
    },
  });

  useEffect(() => {
    // If the condition has changed to 'VOLUME', update valueInr accordingly
    if (memoizedSelectedMarketSegment) {
      const newValue =
        watch("property") === "VOLUME"
          ? memoizedSelectedMarketSegment.volume?.toFixed(2)
          : `${memoizedSelectedMarketSegment.lp}`;

      setValue("valueInr", newValue);
      setValue("lastTradedPrice", "1212120"); // setting this to 0  as we need to reset it again
    }
  }, [watch("property"), setValue]);

  // As inr Changes then we need to adjust lastTradedPrice as well
  useEffect(() => {
    // Skip if this update was triggered by lastTradedPrice change
    if (isUpdatingFromLastTradedPrice.current) {
      isUpdatingFromLastTradedPrice.current = false;
      return;
    }

    const isVolume = watch("property") === "VOLUME";
    const valueInr = watch("valueInr");

    if (memoizedSelectedMarketSegment && valueInr) {
      const intialValue = isVolume
        ? memoizedSelectedMarketSegment.volume
        : memoizedSelectedMarketSegment.lp;

      // Calculate percentage change
      const percentageChange = ((parseFloat(valueInr) - intialValue) / intialValue) * 100;

      // Set flag before updating
      isUpdatingFromValueInr.current = true;
      setValue("lastTradedPrice", percentageChange.toFixed(2));
    }
  }, [watch("valueInr"), watch("property"), memoizedSelectedMarketSegment, setValue]);

  // Effect for when lastTradedPrice changes
  useEffect(() => {
    // Skip if this update was triggered by valueInr change
    if (isUpdatingFromValueInr.current) {
      isUpdatingFromValueInr.current = false;
      return;
    }

    const isVolume = watch("property") === "VOLUME";
    const lastTradedPrice = watch("lastTradedPrice");

    if (memoizedSelectedMarketSegment && lastTradedPrice) {
      const baseValue = isVolume
        ? memoizedSelectedMarketSegment.volume
        : memoizedSelectedMarketSegment.lp;

      // Calculate new value based on percentage
      const newValue = baseValue * (1 + parseFloat(lastTradedPrice) / 100);

      // Set flag before updating
      isUpdatingFromLastTradedPrice.current = true;
      setValue("valueInr", newValue.toFixed(2));
    }
  }, [watch("lastTradedPrice"), watch("property"), memoizedSelectedMarketSegment, setValue]);

  if (!probs.stock_data?.symbol) return null;

  const { data } = useScripLookUp({
    scripName: probs.stock_data.symbol,
    productCode: productCode(serchType),
    serchType: serchType,
  });

  useEffect(() => {
    const condition = watch("condition");
    if (condition) {
      // Trigger validation when condition changes
      trigger(["valueInr", "lastTradedPrice"]);
    }
    trigger(["valueInr"])
  }, [watch("condition"),watch("property"),watch('lastTradedPrice'),watch('valueInr'),trigger]);

  useEffect(() => {
    const dataForMarketingSegment = data?.find(
      (item) => item.nToken === Number(probs.token)
    );

    setDataForMarketingSegment(dataForMarketingSegment);

    if (dataForMarketingSegment?.nIsIndex) {
      setIsnIsIndex(!!dataForMarketingSegment.nIsIndex);
    }
  }, [data, probs.marketSegmentId]); // Include probs.marketSegmentId as a dependency if it can change

  const conditionOptions = useMemo(() => {
    const baseOptions = [
      { value: "", label: conditionToString("") },
      { value: "GE", label: conditionToString("GE") },
    ];

    if (watch("property") !== "VOLUME") {
      baseOptions.push(
        { value: "EQ", label: conditionToString("EQ") },
        { value: "LE", label: conditionToString("LE") }
      );
    }

    return baseOptions;
  }, [watch("property")]);

  // Initialize form with props data
  useEffect(() => {
    const isVolume = probs.property === "VOLUME";
    const intialValue = isVolume
      ? memoizedSelectedMarketSegment?.volume
      : memoizedSelectedMarketSegment?.lp;
    const chnagedValue = probs.triggerValue;

    if (probs) {
      let alertPercentValueChange: number = 0;
      if (intialValue) {
        alertPercentValueChange =
          ((chnagedValue - intialValue) / intialValue) * 100;
      }

      reset({
        searchQuery: probs.stock_data?.symbol || "",
        alertName: probs.name || "",
        property: probs.property || "",
        condition: probs.condition || "",
        valueInr: probs.triggerValue?.toString() || "",
        lastTradedPrice: alertPercentValueChange.toFixed(2), // Initialize with 0 or adjust as needed
        note: probs.note || "",
      });

      setSelectedToken(probs.token);
      setSelectedMarketSegmentId(probs.marketSegmentId);
    } else {
      console.warn("Missing probs:", { probs });
    }
  }, [probs, reset, memoizedSelectedMarketSegment]);

  

  const onSubmit = useCallback(
    async (data: AlertFormData) => {
      if (!selectedMarketSegmentId || !selectedToken) return;

      const payload = {
        id: probs.id,
        basketId: null,
        condition: data.condition.trim(),
        marketSegmentId: selectedMarketSegmentId,
        name: data.alertName.trim(),
        note: data.note?.trim() || "",
        property: data.property.trim(),
        triggerValue: parseFloat(data.valueInr),
        token: selectedToken,
      };

      try {
        await mutate(payload);
        BrokerApi.instance?._host.showNotification(
          "Alert Updated Successfully",
          `${data.alertName} has been updated successfully`,
          1
        );
        handleAlertsTab("all-alerts");
        dispatch(toggleAlertsListModel(false));
      } catch (error: any) {
        BrokerApi.instance?._host.showNotification(
          error?.message || "Something Went Wrong",
          `${data.alertName} has not been updated successfully`,
          1
        );
      }
    },
    [
      selectedMarketSegmentId,
      selectedToken,
      probs.id,
      mutate,
      handleAlertsTab,
      dispatch,
    ]
  );

  useEffect(() => {
    let listenerGuid: string | undefined;

    if (selectedToken && selectedMarketSegmentId) {
      listenerGuid = nextId();

      const handleQuotesUpdate = (quotes: Quotes[]) => {
        const selectedSegment = quotes[0].v;

        if (hasBeenMountedRef.current) {
          if (
            JSON.stringify(selectedSegment) !==
            JSON.stringify(selectedMarketSegment)
          ) {
            setSelectedMarketSegment(selectedSegment);
            setValue('ltp', selectedSegment.lp);
            setValue('volume', selectedSegment.volume);
          }
        } else {
          setSelectedMarketSegment(selectedSegment);
          setValue('ltp', selectedSegment.lp);
            setValue('volume', selectedSegment.volume);
          hasBeenMountedRef.current = true;
        }
      };

      DataFeedInstance?.subcscribeQuotesFromTokens(
        [{ market_segment_id: selectedMarketSegmentId, token: selectedToken }],
        handleQuotesUpdate,
        listenerGuid
      );
    }

    // Cleanup function to unsubscribe from the data feed
    return () => {
      if (listenerGuid) {
        DataFeedInstance?.unsubscribeQuotes(listenerGuid);
      }
    };
  }, [selectedToken, selectedMarketSegmentId]);

  return (
    <>
      <AlertContainer>
        <div className="px-5 py-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="searchQuery"
              control={control}
              render={({ field }) => (
                <div className="position-relative w-100">
                  {data ? (
                    <MarketSegmentDetailsEdit
                      isEditable={false}
                      searchQuery={probs.stock_data.symbol}
                      selectedMarketSegment={selectedMarketSegment!}
                      reSetInput={() => field.onChange("")}
                      dataForMaretingSegment={dataForMarketingSegment}
                    />
                  ) : (
                    <Skeleton
                      className="bg-gray"
                      style={{ width: "100%", height: isnIsIndex ? 60 : 103 }}
                    />
                  )}
                </div>
              )}
            />

            {errors.searchQuery && (
              <ErrorText message={errors.searchQuery.message || ""} />
            )}

            <Controller
              name="alertName"
              control={control}
              render={({ field }) => (
                <AlertNameInput
                  label="Alert Name"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  disabled={false}
                  error={errors.alertName?.message}
                />
              )}
            />

            {errors.alertName && (
              <ErrorText message={errors.alertName.message || ""} />
            )}

            <Controller
              name="property"
              control={control}
              render={({ field }) => (
                <PropertyDropdown
                  label="Property"
                  value={field.value}
                  propertyOptions={propertyOptions}
                  onChange={(e) => field.onChange(e.target.value)}
                  disabled={!watch("alertName")}
                />
              )}
            />

            <Controller
              name="condition"
              control={control}
              render={({ field }) => (
                <ConditionDropdown
                  formData={{ ...watch() }}
                  loading={false}
                  conditionOptions={conditionOptions}
                  handleDropdownChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />

            <div className="d-flex gap-2 mt-4">
              <div className="flex-grow-1">
                <Controller
                  name="valueInr"
                  control={control}
                  render={({ field }) => (
                    <ValueInput
                      property={watch("property") as Conditions}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      disabled={!watch("condition")}
                      error={errors.valueInr?.message}
                    />
                  )}
                />
                {errors.valueInr && (
                  <ErrorText message={errors.valueInr.message || ""} />
                )}
              </div>
              <div className="flex-grow-1">
                <Controller
                  name="lastTradedPrice"
                  control={control}
                  render={({ field }) => (
                    <LastTradedPriceInput
                      property={watch("property") as Conditions}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      disabled={!watch("condition")}
                      error={errors.lastTradedPrice?.message}
                    />
                  )}
                />
              </div>
            </div>

            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <NoteInput
                  value={field.value || ""}
                  onChange={(e) => field.onChange(e.target.value)}
                  onClear={() => field.onChange("")}
                  disabled={!watch("condition")}
                />
              )}
            />
          </Form>
        </div>
      </AlertContainer>

      <div
        className="px-5 alerts-create-edit-background d-flex justify-content-end gap-3 py-2"
        style={{ height: 60 }}
      >
        <button
          type="button"
          className="btn-alert secondary"
          onClick={() => handleAlertsTab("all-alerts")}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn-alert primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
          style={{
            cursor: !isValid ? "not-allowed" : "pointer",
            opacity: !isValid ? 0.7 : 1,
          }}
        >
          Update
        </button>
      </div>
    </>
  );
};

export default EditAlertForm;
