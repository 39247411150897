import moment from "moment";
import { GttAlert, GTTOrder } from "../models/gttOrders";
import { toFixedNumber } from "../utils/helpers";

function getProductType(gttOrder:string) {
  if (gttOrder === "BTST") return "INTRA WEEK";
  if (gttOrder === "MTF") return "PAY LATER";
  return gttOrder;
}

export function createGttAlertObject(gttOrder: GTTOrder): GttAlert {
    // Split exchange and series
    
    // Construct the quantity string
    const quantity = `${gttOrder.orders[1]?.quantity || gttOrder.orders[1]?.quantity === 0 ? gttOrder.orders[1]?.quantity : ''}${gttOrder.orders[1]?.quantity && gttOrder.orders[0]?.quantity ? ' | ' : ''}${gttOrder.orders[0]?.quantity || ''}`;

    const createdAt = moment(gttOrder.orders[0].created_at);
    let time = createdAt.format('DD-MM-YYYY HH:mm:ss')

    // Construct the alert object
    const currentObject = {
        id: gttOrder.id,
        product: gttOrder.symbol,
        tradeDirection: gttOrder.transaction_type === 'BUY' ? "Buy" : "Sell",
        gtt_trigger_type: gttOrder.trigger_type.toUpperCase() + " " + getProductType(gttOrder.product),
        last: gttOrder.strike_price,
        status: gttOrder.orders[0]?.status,
        stop_chase: gttOrder.transaction_type === 'BUY' && gttOrder.trigger_type === 'oco' ? toFixedNumber(gttOrder.orders[0]?.trigger_price) : '',
        orderPrice: gttOrder.trigger_type === 'single' ? gttOrder.orders[0]?.variety === 'RL-MKT' ? "Market" : toFixedNumber(gttOrder.orders[0]?.price) : '',
        trigger_price: gttOrder.trigger_type === 'single' ? toFixedNumber(gttOrder.orders[0]?.trigger_price) : '',
        stoploss: gttOrder.transaction_type === 'SELL' && gttOrder.trigger_type === 'oco' ? toFixedNumber(gttOrder.orders[1]?.trigger_price) : '',
        wait_till: gttOrder.transaction_type === 'BUY' && gttOrder.trigger_type === 'oco' ? toFixedNumber(gttOrder.orders[1]?.trigger_price) : '',
        profit: gttOrder.transaction_type === 'SELL' && gttOrder.trigger_type === 'oco' ? toFixedNumber(gttOrder.orders[0]?.trigger_price) : '',
        quantity: quantity,
        createdAt: time,
        sortProp: toFixedNumber(gttOrder.strike_price),
        isTotalRow: true,
        symbol: gttOrder.symbol,
    };

    return currentObject;
}


export const findNearestParent = (node: HTMLElement | null, tagName: string): HTMLElement | null => {
    while (node) {
      if (node.tagName.toLowerCase() === tagName.toLowerCase()) {
        return node; // Return the node if it matches the tagName
      }
      node = node.parentElement; // Move to the parent element
    }
    return null; // Return null if no matching parent is found
  };