import * as React from 'react';
import {BrokerApi} from '../../chart-brokerapi/broker'
import {DataFeedInstance} from '../../chart-datafeed/udf-compatible-datafeed'
import flowBackEndServices2 from "../../services/flowBackendServices";
import './index.scss'
import Draggable from "react-draggable";
import {ReactComponent as CloserIcon} from "../../assets/images/close-square2.svg";
import { ChargesInstance } from '../Charges';
import {NumberColor} from "../common/number_color";
import moment from 'moment'
import { Quotes, ResolveSymbolInfo } from '../../models/symbolData';
import { Order,ChartStateParams,OrderMarginResponse } from '../../models/orders'
import GTT from '../DashBoard/GTT';
import Orders from '../DashBoard/Orders/Create';

  interface Props {
    token?:string
    unmountMe?:()=>void;
    order?:any;
  }
  
export class ChartBuySell extends React.Component<Props,ChartStateParams>{
    static instance: ChartBuySell | null = null;
    ref: React.RefObject<HTMLDivElement>;
    callback?: (key:boolean) => void;
    access_token:string
    available_funds:string
    constructor(props: Props) {
		super(props);

		this.ref = React.createRef();
        ChartBuySell.instance = this;
        this.state = this.defaultStateParams
        this.closeOrderDialog = this.closeOrderDialog.bind(this)
        this.unsubscribe = this.unsubscribe.bind(this)
        this.showCharges = this.showCharges.bind(this)
        this.updatePrice = this.updatePrice.bind(this)
        this.updateQty = this.updateQty.bind(this)
        this.setOrderType= this.setOrderType.bind(this)
        this.toggleSl = this.toggleSl.bind(this)
        this.toggleAmo = this.toggleAmo.bind(this)
        this.toggleSide = this.toggleSide.bind(this)
        this.getScripName = this.getScripName.bind(this)
        
        this.setTriggerPrice = this.setTriggerPrice.bind(this)
        this.setValidity = this.setValidity.bind(this)
        this.setMargin = this.setMargin.bind(this)
        this.placeOrder = this.placeOrder.bind(this)
        this.callback 
        this.access_token = props.token as string
        this.available_funds = ""
	}

    listenerGuid:string | undefined = undefined;

    defaultStateParams:ChartStateParams = {
        order_id: "",
        symbol: "",
        side: 1,
        margin: 1,
        qty: 0,
        limitPrice: 0,
        triggerPrice: undefined,
        order_type: '1',
        validity: 1,
        odin_symbol: "",
        odin_exchange: "",
        odin_expiry_date: "",
        token: "",
        odin_series: "",
        odin_strike_price: 0,
        odin_option_type: "",
        lot_size: 0,
        remQty: 0,
        oldQty: 0,
        oldPrice: 0 ,
        market_type: 1,
        priceScale: 1,
        is_sl: false,
        visible: false,
        market_segment_id: 0,
        scrip_name: "",
        exchange: "",
        ltp: 0,
        ch: 0,
        chp: 0,
        requiredMargin: 0,
        availableMargin: 0,
        tick_size: 0,
        dpr: "",
        is_amo: false,
        gtt_enabled: false,
        chargesVisible: false,
        gtt_order_type: ''
    }
    dismiss() {
        if(this.props.unmountMe) this.props.unmountMe();
    }
    placeOrder(){
        if( this.state.order_id === 'undefined'){
            BrokerApi.instance?.placeOrder({
                symbol: this.state.symbol,
                side: this.state.side, 
                margin: this.state.margin,
                qty: this.state.qty,
                limitPrice: this.state.limitPrice,
                triggerPrice: this.state.triggerPrice,
                order_type: this.state.order_type,
                validity: this.state.validity, 
                odin_symbol: this.state.odin_symbol, 
                odin_exchange: this.state.odin_exchange, 
                odin_expiry_date: this.state.odin_expiry_date, 
                odin_token: this.state.token,
                odin_series: this.state.odin_series,
                odin_strike_price: this.state.odin_strike_price,
                odin_option_type: this.state.odin_option_type, 
                odin_lot_size: this.state.lot_size,
                is_amo: this.state.is_amo,
                origin:'buy_sell_button'
            }).then((res:string)=>{
                this.setState({limitPrice: 0, 
                    visible: false, 
                    order_type: '1',
                    is_sl: false,
                    validity: 1, 
                    requiredMargin: 0.00 , 
                    availableMargin: 0,
                    order_id: '', 
                    market_type: 1,
                    margin: 1,
                    qty: 0,
                    remQty: 0,
                    odin_symbol: "", 
                    odin_exchange: "", 
                    odin_expiry_date: "", 
                    odin_token: "",
                    odin_series: "",
                    odin_strike_price: 0,
                    odin_option_type: "", 
                    lot_size: 1
                })
                if (this.callback) {
                    this.callback(true);
                }
                window.GtmPusher({
                    event: 'tv-order-placed'
                })
            })
        }else{
            BrokerApi.instance?.modifyOrder({
                order_id: this.state.order_id,
                symbol: this.state.symbol,
                side: this.state.side, 
                qty: this.state.qty,
                limitPrice: this.state.limitPrice,
                triggerPrice: this.state.triggerPrice,
                order_type: this.state.order_type,
                validity: this.state.validity, 
                tradedQty: ((this.state.qty as number)-(this.state.remQty as number))
            }).then(()=>{
                if(this.callback){
                    this.callback(true)
                }
                this.setState({limitPrice: 0, 
                    visible: false, 
                    order_type: '1',
                    is_sl: false,
                    validity: 1, 
                    requiredMargin: 0.00 , 
                    availableMargin: 0,
                    order_id: '', 
                    market_type: 1,
                    margin: 1,
                    qty: 0,
                    remQty: 0 })
            })
        }

        this.unsubscribe(this.listenerGuid as string)
        
    }
    componentWillUnmount(){
        this.unsubscribe(this.listenerGuid as string)
	}

    componentDidMount(){
    }

    unsubscribe(listenerGuid:string){
        if(listenerGuid) {
            DataFeedInstance?.unsubscribeQuotes(listenerGuid)
        }
    }

    getAvailableBalance(market_segment_id:number){
        flowBackEndServices2.getAvailableFunds(this.access_token).then(value => {
            return value.data
        }).then((data)=>{
            if (market_segment_id != 5 ){
                this.available_funds = data.nse.net_available
            }else{
                this.available_funds = data.mcx.net_available
            }

        }).catch(reason => {
            console.log("Error in margin data",reason);
        })


    }


    closeOrderDialog(){
        this.unsubscribe(this.listenerGuid as string)
        this.setState({visible: false, order: {},chargesVisible: false})
        ChargesInstance?.closeChargesDialog()
        if (typeof this.callback === 'function'){
            this.callback(true)
        }
    }

    showCharges(){
        this.setState({ ...this.state, chargesVisible: true }, () => {
            this.updateCharges();
          });
    }

    _showOrderDialog(order:Order){
        DataFeedInstance?.resolveSymbol(String(order.symbol),()=>{},()=>{},'').then((symbolInfo:ResolveSymbolInfo)=>{
            if (!symbolInfo.isTradeable){
                window.tvWidget.showNoticeDialog({title: "Symbol non tradable",body: "Indices are non tradable. Try trading in F&O instead",callback: ()=>{}})
                return
            }
            var symbol_array:string[] =  order.symbol?.split(":") as string[];
            var exchange = symbol_array[0]
            var scrip_name = symbol_array[1]
            const new_state:ChartStateParams = {...this.state, symbol: order.symbol as string,
                is_gtt_order: order.is_gtt_order,
                exchange: exchange,
                scrip_name: scrip_name,
                market_segment_id: symbolInfo.odin_market_segment_id, 
                token: symbolInfo.odin_token, 
                order_id: String(order.order_id), 
                odin_symbol: symbolInfo.odin_symbol, 
                odin_exchange: symbolInfo.odin_exchange, 
                odin_expiry_date: symbolInfo.odin_expiry_date, 
                lot_size: symbolInfo.odin_lot_size,
                odin_series: symbolInfo.odin_series,
                odin_strike_price: Number(symbolInfo.odin_strike_price),
                odin_option_type: String(symbolInfo.odin_option_type),
                priceScale: symbolInfo.pricescale,
                gtt_order_type: '',
                tick_size: Number(symbolInfo.minmov)/Number(symbolInfo.pricescale)}
                this.setState(new_state,()=>{
                    DataFeedInstance?.getQuotes([String(order.symbol)],(quotes:Quotes[])=>{
                        var margin:number
             
                        switch (order.product) {
                            case "INTRADAY":
                                margin = 1 
                                break;
                            case "MTF":
                                margin = 2 
                                break;
                            case "DELIVERY":
                                margin = 3 
                                break;
                            case "AMO":
                                margin = 1 
                                break;    
                            default:
                                margin = 1
                                break;
                        }
                        var order_type:string 
                        var is_sl:boolean = false 
                        var market_type:number 
                        var triggerPrice
                        if(order.order_type == "RL"){
                            is_sl = false 
                            market_type = 1 
                            order_type = '1'
                        }else if(order.order_type == "RL-MKT"){
                            is_sl = false  
                            market_type = 2 
                            order_type = '2' 
                        }else if(order.order_type == "SL"){
                            is_sl = true  
                            order_type = '3'
                            market_type = 1
                            triggerPrice = order.stopPrice
                        }else if(order.order_type == "SL-MKT"){
                            is_sl = true  
                            order_type = '4'
                            market_type = 2
                        }else if (order.type == 2){
                           is_sl = false 
                           order_type = '2' 
                           market_type = 2  
                        }else{
                            is_sl = false 
                            market_type = 1 
                            order_type = '1'
                        }
            
                        if(order.duration != 'undefined' ){
                            if(order.duration == "DAY"){
                                this.state = {...this.state,validity:1}
                            }else if(order.duration == "IOC"){
                                this.state = {...this.state,validity:2}
                            }else if(order.duration == 'GTT'){
                                this.state = {...this.state,validity:3}
                                is_sl = true
                                triggerPrice = order.stopPrice
                            }
                        }
                        this.setState({...this.state,
                            limitPrice: (order.limitPrice || quotes[0].v.lp), 
                            qty: (order.qty == undefined ? 1 : order.qty), 
                            duration: order.duration, 
                            product: order.product,
                            margin: margin,
                            gtt_enabled: ((( order.order_id  != 'undefined' && order.duration == "GTT" ))),
                            oldQty: Number(order.order_id != 'undefined' ? order.qty : 0),
                            oldPrice: Number(order.order_id != 'undefined' ? order.limitPrice : 0.00),
                            side: order.side as number,
                            is_sl: is_sl,
                            order_type: order_type,
                            market_type: market_type,
                            remQty: Number(order.remQty),
                            dpr: this.state.dpr==""?quotes[0].v.dpr:this.state.dpr,
                            triggerPrice: triggerPrice,
                            is_amo: (order.product == "AMO DELIVERY" ||  order.is_amo_order == "Yes"),
                            visible: true,
                        chargesVisible:false, },()=>{
                                this.updateCharges()
                            });
                            
                        // this.updateCharges();
                    },()=>{})
                })
                this.getSubscribedData(String(symbolInfo.odin_token),Number(symbolInfo.odin_market_segment_id),new_state)
                this.getAvailableBalance(Number(symbolInfo.odin_market_segment_id))
        })  
    }

    getSubscribedData(token1:string,mktId:number,old_state:any){
        this.unsubscribe(this.listenerGuid as string)
        this.listenerGuid = `top-${token1}-${mktId}`
        DataFeedInstance?.subcscribeQuotesFromTokens([{market_segment_id: mktId , token: Number(token1) }],(quotes:Quotes[])=>{
            this.setState((old_state) => ({
                ltp: quotes[0].v.lp,
                ch: quotes[0].v.ch,
                chp: quotes[0].v.chp,
                dpr: quotes[0].v.dpr
              }))
        },this.listenerGuid)
    }
    ShowOrderDialog(order:Order,callback:any){
        this.callback = callback
        this.setState(this.defaultStateParams, () => {
            this._showOrderDialog(order);
        });
    }

    updateMargin(){
        BrokerApi.instance?.getRequiredMargin({
            symbol: this.state.symbol,
            side: this.state.side, 
            qty: Number(this.state.qty) * Number((Number(this.state.market_segment_id).toString() == '2' || Number(this.state.market_segment_id).toString() == '4') ? this.state.lot_size : 1 ), 
            oldQty: this.state.validity == 3 ? 0 : Number(this.state.oldQty)* Number((Number(this.state.market_segment_id).toString() == '2' || Number(this.state.market_segment_id).toString() == '4') ? this.state.lot_size : 1 ), 
            oldPrice: this.state.validity == 3 ? 0 : this.state.oldPrice, 
            id: this.state.order_id,
            validity: String(this.state.validity), 
            limitPrice: this.state.limitPrice,
            margin: String(this.state.margin),
            mode: (this.state.order_id != 'undefined' ? this.state.validity == 3 ? "N" :"M" : 'N'),
            market: (this.state.market_type == 1 ? 0 : 1), 
            lot_size: this.state.lot_size,
            priceScale: this.state.priceScale
            
        }).then((response:OrderMarginResponse)=>{
            this.setState({...this.state, requiredMargin: Number(response?response.required_margin ? response?.required_margin.toFixed(2) : 0.00 : 0.00), availableMargin:Number(response?response.available_margin ? response?.available_margin.toFixed(2) : 0.00 : 0.00) })
        })
    }

    updatePrice(event:any){
        if(/^\d*\.?\d*$/.test(event.target.value)){
            this.setState({...this.state, limitPrice: event.target.value,chargesVisible:false},()=>{this.updateCharges()})
        }
        
    }

    updateQty(event:any){
        if(/^[0-9]*$/.test(event.target.value)){
            this.setState({...this.state, qty: event.target.value,chargesVisible:false},()=>{
                this.updateCharges()
            })
            
        }  
        
    }

    setGttOrderType(event:any){
        if(event.target.id == "gtt-order-type-single"){
            console.log("single")
            this.setState({...this.state, gtt_order_type: 'single'})
        }else if(event.target.id == "gtt-order-type-oco"){
            console.log("oco")
            this.setState({...this.state, gtt_order_type: 'oco'})
        }
    }
    setOrderType(event:any){
        if(event.target.id == "order-type-1"){
            if( this.state.is_sl){
                this.setState({...this.state, market_type: 1, order_type: '3'})
            }else{
                this.setState({...this.state, market_type: 1, order_type: '1'})
            }
            
        }else if(event.target.id == "order-type-2"){
            if( this.state.is_sl){
                this.setState({...this.state, market_type: 2, order_type: '4',limitPrice: this.state.ltp,chargesVisible:false},()=>{
                this.updateCharges()
            })
            }else{
                this.setState({...this.state, market_type: 2, order_type: '2',limitPrice: this.state.ltp,chargesVisible:false},()=>{
                this.updateCharges()
            })
            }
            
        }
        
    }

    updateCharges(){
        if(this.state.chargesVisible){
            ChargesInstance?.showChargesDialog(this.state)
        }
        this.updateMargin()
    }
    toggleAmo(event:any){
        if(!this.state.is_amo && this.state.validity == 3){
            this.setState({...this.state, is_amo: !this.state.is_amo, validity: 1,chargesVisible:false},()=>{
                this.updateCharges()
            })
        }else{
            this.setState({...this.state, is_amo: !this.state.is_amo,chargesVisible:false},()=>{
                this.updateCharges()
            })
        }  
    }

    toggleSide(event:any){
        if (this.state.side == 1){
            this.setState({...this.state, side: -1,chargesVisible:false},()=>{
                this.updateCharges()
            })
        }else{
            this.setState({...this.state, side: 1,chargesVisible:false},()=>{
                this.updateCharges()
            })
        }
        
    }

    toggleSl(event:any){
        if(this.state.market_type == 1 ){
            if(this.state.is_sl){
                this.setState({...this.state, is_sl: !this.state.is_sl, order_type: '1'})
            }else{
                this.setState({...this.state, is_sl: !this.state.is_sl, order_type: '3'})
            }
        }else{
            if(this.state.is_sl){
                this.setState({...this.state, is_sl: !this.state.is_sl, order_type: '2'})
            }else{
                this.setState({...this.state, is_sl: !this.state.is_sl, order_type: '4'})
            }
        }
        
    }

    setTriggerPrice(event:any){
        if(/^\d*\.?\d*$/.test(event.target.value)){
            this.setState({...this.state, triggerPrice: event.target.value,chargesVisible:false},()=>{this.updateCharges()})
        }
        
    }

    setValidity(event:any){
        if( event.target.id=='label-validity_1') {
            this.setState({...this.state, validity: 1})
        }else if( event.target.id == "label-validity_2"){
            this.setState({...this.state, validity: 2})
        }else if( event.target.id == "label-validity_3"){
            this.setState({...this.state, is_amo: false })
            this.setState({...this.state, is_amo: false,validity: 3, market_type: 1 })
        }
    }

    setMargin(event:any){
        switch(event.target.id ){
            case "custom-order-dialog__format-intraday-id": 
                this.setState({...this.state, margin: 1, gtt_enabled: false, validity: (this.state.validity == 3 ? 1 : this.state.validity),chargesVisible:false},()=>{this.updateCharges()});
                break ;
            case "custom-order-dialog__format-mtf-id": 
                this.setState({...this.state, margin: 2, gtt_enabled: false, validity: (this.state.validity == 3 ? 1 : this.state.validity),chargesVisible:false},()=>{this.updateCharges()});
                break;
            case "custom-order-dialog__format-delivery-id": 
                this.setState({...this.state, margin: 3, gtt_enabled: false,chargesVisible:false},()=>{this.updateCharges()});
                break;
        }
    }

    EquityMargins(){
        return(
            <><div id="custom-order-dialog__format-intraday-link-id" style={{ pointerEvents: (this.state.order_id != 'undefined' ? 'none' : 'all') }} >
                <div className= {`w3-third tablink w3-hover-light-grey w3-padding ${this.state.margin ==1 ?'order-bottombar-selected' : 'order-bottombar' } ${this.state.margin !=1 && this.state.order_id != 'undefined' ? 'no_state_order_background': 'state_order_background'}`} id="custom-order-dialog__format-intraday-id"  onMouseUp={this.setMargin}>Intraday</div>
            </div>
            <div id="custom-order-dialog__format-mtf-link-id" style={{ pointerEvents: (this.state.order_id != 'undefined' ? 'none' : 'all') }} >
                <div className={`w3-third tablink w3-hover-light-grey w3-padding ${this.state.margin ==2 ?'order-bottombar-selected' : 'order-bottombar' } ${this.state.margin !=1 && this.state.order_id != 'undefined' ? 'no_state_order_background': 'state_order_background'}`} id="custom-order-dialog__format-mtf-id" onMouseUp={this.setMargin}>Pay Later</div>
            </div>
            <div id="custom-order-dialog__format-delivery-link-id" style={{ pointerEvents: (this.state.order_id != 'undefined' ? 'none' : 'all') }} >
                <div className={`w3-third tablink w3-hover-light-grey w3-padding ${this.state.margin ==3 ?'order-bottombar-selected' : 'order-bottombar' } ${this.state.margin !=1 && this.state.order_id != 'undefined' ? 'no_state_order_background': 'state_order_background'}`} id="custom-order-dialog__format-delivery-id" onMouseUp={this.setMargin}>Delivery</div>
            </div></>
        );
    }

    FoMargins(){
        return(
            <><div id="custom-order-dialog__format-intraday-link-id" style={{ pointerEvents: (this.state.order_id != 'undefined' ? 'none' : 'all') }}>
                <div className= {`w3-half tablink w3-hover-light-grey w3-padding ${this.state.margin ==1 ?'order-bottombar-selected' : 'order-bottombar' } ${this.state.margin !=1 && this.state.order_id != 'undefined' ?'no_state_order_background' : 'state_order_background'}`} id="custom-order-dialog__format-intraday-id" onMouseUp={this.setMargin}>Intraday</div>
            </div>
            <div id="custom-order-dialog__format-delivery-link-id" style={{ pointerEvents: (this.state.order_id != 'undefined' ? 'none' : 'all') }}>
                <div className={`w3-half tablink w3-hover-light-grey w3-padding ${this.state.margin ==3 ?'order-bottombar-selected' : 'order-bottombar' } ${this.state.margin !=3 && this.state.order_id != 'undefined' ? 'no_state_order_background': 'state_order_background'}`} id="custom-order-dialog__format-delivery-id" onMouseUp={this.setMargin}>Carry Forward</div>
            </div></>
        );
    }

    BuyButton(){
        return (<button className="button custom-order-dialog__ok-button" id="custom-order-dialog__ok-button-id" style={{ backgroundColor: '#28C269' }} onMouseUp={this.placeOrder} >BUY</button>)
    }

    ModifyBuyButton(){
        return (<button className="button custom-order-dialog__ok-button" id="custom-order-dialog__ok-button-id" style={{ backgroundColor: '#28C269' }} onMouseUp={this.placeOrder}>MODIFY BUY</button>)
    }

    SellButton(){
        return (<button className="button custom-order-dialog__ok-button" id="custom-order-dialog__ok-button-id" style={{ backgroundColor: '#FE4141' }} onMouseUp={this.placeOrder}>SELL</button>)
    }

    ModifySellButton(){
        return (<button className="button custom-order-dialog__ok-button" id="custom-order-dialog__ok-button-id" style={{ backgroundColor: '#FE4141' }} onMouseUp={this.placeOrder}>MODIFY SELL</button>)
    }

    getScripName(symbol:string,scrip_name:string,expiry_date:string,option_type:string,strike_price:number){
        if(!isNaN(strike_price)){
            var option_name=""
            expiry_date = moment(expiry_date, "DDMMYYYY").format('DDMMMYY')
            option_name += symbol+" "+expiry_date+" "+option_type+strike_price/100
            return option_name
        }else{
            return scrip_name
        }
    }

    GttOrderType(){
        return(<ul><li className={this.state.gtt_order_type == 'single'  ? 'is-active': ""}>
        <a id="gtt-order-type-single" onMouseUp={this.setGttOrderType}>Single</a>
            </li>
            <li className={this.state.gtt_order_type == 'oco' ? 'is-active': ""}>
                <a id="gtt-order-type-oco" onMouseUp={this.setGttOrderType}>OCO</a>
            </li>
        </ul>)
    }
    BothLimitMkt(){
        return(<ul><li className={this.state.market_type == 1  ? 'is-active': ""}>
        <a id="order-type-1" onMouseUp={this.setOrderType}>Limit</a>
            </li>
            <li className={this.state.market_type == 2 ? 'is-active': ""}>
                <a id="order-type-2" onMouseUp={this.setOrderType}>Market</a>
            </li>
        </ul>)
    }

    OnlyLimit(){
        return(<ul><li className={this.state.market_type == 1  ? 'is-active': ""}>
        <a id="order-type-1" onMouseUp={this.setOrderType}>Limit</a>
            </li>
            
        </ul>)
    }

    SymbolHeader(){
        return(<div className="custom-order-dialog__header handle_b" id="custom-order-dialog__header-id" data-dragg-area="true">
        <div className="custom-order-dialog__stock-info no-drag" id="custom-order-dialog__quote-stock-info-id">
            <div className="custom-order-dialog__stock-description" id="custom-order-dialog__stock-name-description">
                <div className="custom-order-dialog__stock-name" id="custom-order-dialog__stock-name-id">{this.getScripName(String(this.state.odin_symbol),String(this.state.scrip_name),String(this.state.odin_expiry_date),String(this.state.odin_option_type),Number(this.state.odin_strike_price))}</div>
                <div className="custom-order-dialog__exchange" id="custom-order-dialog__exchange-id">{this.state.exchange}</div>
            </div>
            <div className="custom-order-dialog__quote" id="custom-order-dialog__quote-id">
                <div className="custom-order-dialog__quote-ltp" id="custom-order-dialog__quote-ltp-id">{Number(this.state.ltp).toFixed(2)}</div>
                <div className="custom-order-dialog__quote-chng green" id="custom-order-dialog__quote-chng-id"><NumberColor number={this.state.ch} numFix={true} /></div>
                <div className="custom-order-dialog__quote-chngp green" id="custom-order-dialog__quote-chngp-id"><NumberColor number={Number(this.state.chp)} numFix={true} percentageAdd={true} isFiniteCheck={true} returnBracket={true}/></div>
            </div>
        </div>
        <div className='custom-order-dialog__actions'>
            <div className="field custom-order-dialog__side-switch-row no-drag" style={{"marginBottom":"26px"}}>
                    <div className="custom-order-dialog__side-switch-description">{this.state.side === 1 ? "Buy" : "Sell"}</div>
                    <input type="checkbox"  checked={this.state.side === 1} name="side-switch" id="side-switch" className="switch is-rtl is-rounded side-switch" onChange={this.toggleSide} />
                    <label htmlFor="side-switch" className='side-switch' />
            </div>
            <div className="custom-order-dialog__dialog-actions no-drag" id="custom-order-dialog__quote-dialog-actions-id" style={{"paddingTop":"4px"}}>
                {/*<button className="button custom-order-dialog__close_button" id="custom-order-dialog__quote-close_button-id" onMouseUp={this.closeOrderDialog} />*/}
                <div onClick={this.closeOrderDialog} className="c-pointer" id=""><CloserIcon/></div>
            </div>
        </div>
        
    </div>)
    }

    render() {
        var symbolHeader = this.SymbolHeader()
        if( this.state.visible == false){
            return (

                <div
                    ref={ this.ref }
                    className={ 'custom-order-dialog' }
                >
                    {this.props.order}
                    </div>
            );
        }else{
            let header = ((Number(this.state.market_segment_id).toString() == '1' || Number(this.state.market_segment_id).toString() == '3') ? this.EquityMargins() : this.FoMargins())
            var actionButton = null
            var limitMkt = null 
            if(this.state.order_id != "undefined"){
                if(this.state.side == 1){
                    actionButton = this.ModifyBuyButton()
                }else{
                    actionButton = this.ModifySellButton()
                }
            }else{
                if(this.state.side == 1){
                    actionButton = this.BuyButton()
                }else{
                    actionButton = this.SellButton()
                }
            }
            if (this.state.validity == 3){
                limitMkt = this.OnlyLimit()
            }else{
                limitMkt = this.BothLimitMkt()
            }

            if (this.state.is_gtt_order){
                return(
                    <GTT 
                        CloseGttModal={this.closeOrderDialog} 
                        token={this.state.token || ''}
                        segMentId={this.state.market_segment_id || ''}
                        exchnage={this.state.odin_exchange || ''}
                        ltp={this.state?.ltp?.toFixed(2) || "0"}
                        ch={this.state.ch || 0}
                        chp={this.state?.chp?.toString() || "0"}
                    />
                    );
            }else{
                return(
                    <>
                    <Draggable handle=".handle_b" cancel=".no-drag"  bounds="parent">
                        <div className="custom-order-dialog ui-draggable" id="custom-order-dialog-id" style={{ display: 'block', visibility: 'visible' }}>
                            <input id="custom-order-dialog-hidden-id" style={{ display: 'none' }} />
                            {symbolHeader}
                            <div className="custom-order-format w3-row" id="custom-order-format-id" format-type={1}>
                                {header}
                            </div>
                            <div className='scrollable'>
                                <div className="custom-order-dialog__basic-input">
                                    <div className="custom-order-dialog__qty-section">
                                        <label className="custom-order-dialog__qty-input-label" htmlFor="custom-order-dialog__qty-input-id">Enter Lots</label>
                                        <input type="text" className="custom-order-dialog__qty-input" id="custom-order-dialog__qty-input-id" autoComplete="off" value = {this.state.qty} onChange={this.updateQty} />
                                        
                                    </div>
                                    <div className="custom-order-dialog__price-section">
                                        <label className="custom-order-dialog__price-input-label" htmlFor="custom-order-dialog__price-input-id"><span>Price</span><span className='custom-order-dialog__price-tick-label'>Tick: {this.state.tick_size}</span></label>
                                        <input type="text" className="custom-order-dialog__price-input" id="custom-order-dialog__price-input-id" autoComplete="off" value = {this.state.market_type == 2?'Market Price':this.state.limitPrice} onChange={this.updatePrice} disabled={this.state.market_type == 2}/>
                                        
                                    </div>
                                </div>
                                <div className='order-details'>
                                    <div className="custom-order-dialog__qty-input-lot-size"> 1 lot = {this.state.lot_size}</div>
                                    <div className="custom-order-dialog__price-dpr"> DPR: {this.state.dpr}</div>
                                </div>
                                <div className="custom-order-dialog__order-type-section">
                                    <div className="field custom-order-dialog__sl-switch-row">
                                        <div className="custom-order-dialog__sl-switch-description">Stoploss</div>
                                        <input type="checkbox" checked={this.state.is_sl} name="sl-switch" id="sl-switch" className="is-large switch is-rtl is-rounded " onChange={this.toggleSl} />
                                        <label htmlFor="sl-switch" />
                                    </div>
                                    <div className="custom-order-dialog__order-type-select tabs is-toggle" id="custom-order-dialog__order-type-select-id" order-type={1}>
                                        {limitMkt}
                                    </div>
                                </div>
                                <div className="custom-order-dialog__sl-switch-container">
                                    <div className="custom-order-dialog__sl-value-input-container" id="custom-order-dialog__sl-value-input-container-id" style={{display: this.state.is_sl ? 'block': 'none'}}>
                                        <label className="custom-order-dialog__sl-value-input-label" id="custom-order-dialog__sl-value-input-label-id" htmlFor="custom-order-dialog__sl-value-id">Trigger Price</label>
                                        <input type="text" className="custom-order-dialog__sl-value-input" id="custom-order-dialog__sl-value-input-id" value={this.state.triggerPrice} onChange={this.setTriggerPrice} />
                                    </div>
                                </div>
                                <div className="control field m-t-md custom-order-dialog__validity-container">
                                    <label className="label custom-order-dialog__validity-type-header" >Validity</label>
                                    <div className="custom-order-dialog__validity-types-row">
                                        <label htmlFor="validity_1" className={`button custom-order-dialog__validity-type ${ this.state.validity == 1 ? 'is-primary' : ''} ${this.state.order_id != 'undefined' && this.state.validity == 3 ? 'hidden' : ''}`} id="label-validity_1" onMouseUp={this.setValidity}>Day
                                            <input type="radio" defaultValue={1} name="validity_1" id="validity_1" className="hidden" />
                                        </label>
                                        <label htmlFor="validity_2" className={`button custom-order-dialog__validity-type ${ this.state.validity == 2 ? 'is-primary' : ''} ${this.state.order_id != 'undefined' && this.state.validity == 3 ? 'hidden' : ''}`} id="label-validity_2" onMouseUp={this.setValidity}>IOC
                                            <input type="radio" defaultValue={2} name="validity_2" id="validity_2" className="hidden" />
                                        </label>
                                        <label htmlFor="validity_2" className={`button custom-order-dialog__validity-type ${ this.state.validity == 3 ? 'is-primary' : ''} ${ this.state.gtt_enabled  ? '' : 'hidden'}` } id="label-validity_3" onMouseUp={this.setValidity} style={{display:"none"}}>GTT
                                            <input type="radio" defaultValue={3} name="validity_2" id="validity_2" className="hidden" />
                                        </label>
                                    </div>
                                </div>
                                <div className="field custom-order-dialog__amo-switch-row" style={{display: ((Number(this.state.market_segment_id).toString() == '1' || Number(this.state.market_segment_id).toString() == '3')&& (!(this.state.order_id != 'undefined' && this.state.validity == 3) || (this.state.order_id != 'undefined' && this.state.is_amo) || this.state.order_id == 'undefined')) ? 'block flex': 'none'}}>
                                        <div className="custom-order-dialog__amo-switch-description">After Market Order</div>
                                        <input type="checkbox"  checked={this.state.is_amo} name="amo-switch" id="amo-switch" className="is-large switch is-rtl is-rounded " onChange={this.toggleAmo} />
                                        <label htmlFor="amo-switch" />
                                </div>
    
    
                            </div>
                            <div className="custom-order-dialog__footer">
                                <div className="custom-order-dialog__margin-div">
                                    <div className="custom-order-dialog__required-margin-div">
                                        <span className="custom-order-dialog__required-margin-div-label">Required: &nbsp;</span><span className="custom-order-dialog__required-margin-div-value" id="custom-order-dialog__required-margin-div-value-id">{this.state.requiredMargin}</span>
                                    </div>
                                    <div className="custom-order-dialog__required-margin-div">
                                        <span className="custom-order-dialog__available-margin-div-label">Available: &nbsp;</span><span className="custom-order-dialog__available-margin-div-value" id="custom-order-dialog__available-margin-div-value-id">{this.state.availableMargin}</span>
                                    </div>
                                    <div className="custom-order-dialog__charges-div">
                                        <span className="custom-order-dialog__available-margin-div-value" id="custom-order-dialog__available-margin-div-value-id" onClick={this.showCharges}>View Charges</span> 
                                    </div>
                                </div>
                                
                                {actionButton}
                            </div>
                        </div>
                    </Draggable>
                    {/* <Orders 
                        CloseGttModal={this.closeOrderDialog} 
                        token={this.state.token || ''}
                        segMentId={this.state.market_segment_id || ''}
                        exchnage={this.state.odin_exchange || ''}
                        ltp={this.state?.ltp?.toFixed(2) || "0"}
                        ch={this.state.ch || 0}
                        chp={this.state?.chp?.toString() || "0"}
                        onClick={this.showCharges}
                        symbol={this.state.symbol || ''}
                    /> */}
                    </>
                    );
            }
            
        }
	}
}

export default ChartBuySell