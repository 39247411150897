import Decimal from 'decimal.js';

import { GTTOrder } from "../../../../models/gttOrders";
import { GTTFormState, TradeDirection } from "../gttTypes";

export const calculateEditInitialGttState = ({
  orderType,
  price,
  quantity,
  triggerPrice,
  triggerType,
  targetOrderType,
  targetPrice,
  targetQuantity,
  targetTriggerPrice,
}: GTTFormState): GTTFormState => {
  return {
    triggerType: triggerType,
    triggerPrice: triggerPrice,
    quantity: quantity,
    price: price,

    orderType: orderType,

    targetTriggerPrice: targetTriggerPrice,
    targetQuantity: targetQuantity,
    targetPrice: targetPrice,
    targetOrderType: targetOrderType,
  };
};

export const transformGTTObjectToFormState = (
  gttOrder: GTTOrder,
  tradeDirection: TradeDirection,
  tick: number
): GTTFormState => {
  // Helper function to safely convert and round price values
  const formatPrice = (value: number | string | undefined): string => {
    if (!value) return "";
    const numValue = typeof value === "string" ? parseFloat(value) : value;
    return isNaN(numValue) ? "" : roundToNearestTick(numValue, tick);
  };

  // Safety check for undefined/null gttOrder or orders
  if (!gttOrder?.orders || gttOrder.orders.length === 0) {
    return {
      triggerType: "Single",
      triggerPrice: "",
      quantity: "",
      price: "",
      orderType: "Limit",
      targetTriggerPrice: "",
      targetQuantity: "",
      targetPrice: "",
      targetOrderType: "Limit",
    };
  }

  // Destructure and create copies to avoid mutating original data
  let [firstOrder, secondOrder] = [...gttOrder.orders];

  // For single order case
  if (!secondOrder) {
    return {
      triggerType: "Single",
      triggerPrice: formatPrice(firstOrder?.trigger_price),
      quantity: firstOrder?.quantity?.toString() || "",
      price: formatPrice(firstOrder?.price),
      orderType: firstOrder?.variety === "RL" ? "Limit" : "Market",
      targetTriggerPrice: "",
      targetQuantity: "",
      targetPrice: "",
      targetOrderType: "Limit",
    };
  }

  // For OCO orders, determine which is stopLoss and which is target
  let stopLoss, target;

  if (tradeDirection === "Buy") {
    // For Buy: Higher trigger price is stopLoss
    if (Number(firstOrder.trigger_price) > Number(secondOrder.trigger_price)) {
      stopLoss = firstOrder;
      target = secondOrder;
    } else {
      stopLoss = secondOrder;
      target = firstOrder;
    }
  } else {
    // For Sell: Lower trigger price is stopLoss
    if (Number(firstOrder.trigger_price) < Number(secondOrder.trigger_price)) {
      stopLoss = firstOrder;
      target = secondOrder;
    } else {
      stopLoss = secondOrder;
      target = firstOrder;
    }
  }

  // Return the transformed state with rounded prices
  return {
    triggerType: "OCO",
    triggerPrice: formatPrice(stopLoss?.trigger_price),
    quantity: stopLoss?.quantity?.toString() || "",
    price: formatPrice(stopLoss?.price),
    orderType: stopLoss?.variety === "RL" ? "Limit" : "Market",
    targetTriggerPrice: formatPrice(target?.trigger_price),
    targetQuantity: target?.quantity?.toString() || "",
    targetPrice: formatPrice(target?.price),
    targetOrderType: target?.variety === "RL" ? "Limit" : "Market",
  };
};

// Using Concept Of Binary Serach ## https://leetcode.com/discuss/interview-experience/1979273/infinite-sorted-array
export function roundToNearestTick(value: number, tick: number): string {
  if (tick <= 0) {
      throw new Error("Tick must be a positive number.");
  }

  // Convert inputs to Decimal
  const valueNum = new Decimal(value);
  const tickNum = new Decimal(tick);

  // Calculate the nearest lower and upper bounds divisible by the tick
  const lowerBound = valueNum.minus(valueNum.mod(tickNum));
  const upperBound = lowerBound.plus(tickNum);

  let low = lowerBound;
  let high = upperBound;

  let result: Decimal | null = null;

  // Binary search for the nearest divisible value
  while (low.lessThanOrEqualTo(high)) {
      const mid = low.plus(high).dividedBy(2).toDecimalPlaces(2); // Ensure 2 decimal places
      const modResult = mid.mod(tickNum);

      if (modResult.isZero()) {
          result = mid;
          break;
      } else if (mid.lessThan(valueNum)) {
          low = mid.plus(0.01);
      } else {
          high = mid.minus(0.01);
      }
  }

  // If no exact result is found, fallback to the closer bound
  if (!result) {
      const distanceToLower = valueNum.minus(lowerBound).abs();
      const distanceToUpper = upperBound.minus(valueNum).abs();
      result = distanceToLower.lessThan(distanceToUpper) ? lowerBound : upperBound;
  }

  return result.toFixed(2);
}

export function isDivisor(dividend: number | string | Decimal, divisor: number | string | Decimal): boolean {
  // Convert the inputs to Decimal objects if they are not already
  const dividendDecimal = new Decimal(dividend);
  const divisorDecimal = new Decimal(divisor);

  // Check if the division of the dividend by the divisor results in an integer
  return dividendDecimal.mod(divisorDecimal).isZero();
}

export const GttOrderCreateTerms = 'I agree to the terms, understand and accept that order executed are not guaranteed.'