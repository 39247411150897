import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import Draggable from "react-draggable";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import flowBackEndServices2 from "../../services/flowBackendServices";
import {numDifferentiation, segmentToInstrumentType} from "../../comman/status";
import {openFutureChain, OpenFutureChain,openHighLowChain,openMarketDepthChain} from "../../slices/optionChainSlice";
import FutureHeader from "./FutureHeader"
import './FutureChainModel.scss';
import FutureChainTableData from "./FutureChainTableData"
import FutureSubsData from "./FutureSubsData"
import MarketDepthData from "./MarketDepthData"
import LoadingSpinner from "./LoadingSpinner";
import HighLow from "./HighLow";
import {ResolveSymbolInfo} from "../../models/symbolData"
import {FutureHistoricalData,FutureChainData,Future} from "../../models/future"
import { RootState } from "../../store";


interface FutureChainProps{
    futureData:any
}

const FutureChainModel:React.FC<FutureChainProps> = ({futureData})=> {

    const { open_future_chain,future_key,socket_open,user} = futureData
    const dispatch = useDispatch();

    const [odinToken,setOdinToken] = useState<string | null>(null)
    const [scripName,setScripName]=useState<string | null>(null)
    const [marketSegmentId,setMarketSegmentId]=useState<number | null>(null)
    const [futuresData,setFuturesData]=useState<Future[]>([])
    const [showFutureChain,setShowFutureChain]=useState<boolean>(true)
    const [showMarketDepth,setShowMarketDepth]=useState<boolean>(false)
    const [showHighLow,setShowHighLow] = useState<boolean>(false)
    const [futuresAvailable,setFuturesAvailable]=useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [historicOHLC,setHistoricOHLC]=useState<FutureHistoricalData | null>(null)
    const currentTab = useSelector((state: RootState) => state.optionChain.currentTab);


    useEffect(()=>{
       if(future_key) showOrderDialog()
       return () => {
    }
    },[])


    function showOrderDialog(){
            DataFeedInstance?.resolveSymbol(future_key, (symbolInfo:ResolveSymbolInfo)=>{
                var scrip_name:string = String(symbolInfo.odin_symbol)
                var instrumentType = segmentToInstrumentType(String(symbolInfo.odin_exchange))
                setScripName(scrip_name)
                setOdinToken(String(symbolInfo.odin_token))
                setMarketSegmentId(Number(symbolInfo.odin_market_segment_id))
                getFutureData(scrip_name,instrumentType)
                getHistoricalOHLC(String(symbolInfo.odin_token),Number(symbolInfo.odin_market_segment_id))
            }, ()=>{}, undefined)
    }

    function getHistoricalOHLC(token:string,segmentId:number){
        flowBackEndServices2.getHistoricalOHLC(token,segmentId,user.access_token).then(value => {
            return value.data
        }).then((data)=>{
            let response:FutureHistoricalData=data
            setHistoricOHLC(response)
        }).catch(reason => {
            console.log("Error in OHLC data",reason);
        })
    }

    function getFutureData(scripName:string,instrumentType:string){
        dispatch(openFutureChain())
        interface input{
            symbol:string;
            instrumentType:string;
        }
        const data:input = {symbol: scripName,instrumentType: instrumentType}
        setIsLoading(true)
        flowBackEndServices2.getFutures(data,user.access_token).then(value => {
            return value.data
        }).then((data)=>{
            let response:FutureChainData=data
                if(response.hasParentStock){
                    setFuturesData(response.Futures)
                }else{
                    setFuturesAvailable(false)
                }
              setIsLoading(false)
        }).catch(reason => {
            setIsLoading(false)
            console.log("Error in F&O data",reason);
        })
    }
    function iterateFutureData(){
        return futuresData.map((key,value)=>{
            return(
                <FutureSubsData data={key} key={value}/>
            )
        })
    }

    function availableFuturesData(){
        return(
            <div className="container mt-4 mb-4 dataBackground">
                {futuresData?<FutureChainTableData data={futuresData} symbol={future_key} odinToken={odinToken as string} mktId={marketSegmentId as number}/>:null}
                {iterateFutureData()}
            </div>
        )
    }
    function notavailableFuturesData(){
        return(
            <div className="container notavailable">
                <h4>Futures are not available for {futuresAvailable?scripName:future_key}</h4>
            </div>
        )
    }
    
    function getFutureChainTable(){
        return(
            <React.Fragment>
                <div className="container mt-2" style={{"paddingLeft":"4.5%","paddingRight":"4.5%"}}>
                    <div className="row headerBackground"   >
                        <div className="col-5 p-3 tableHeader" style={{"justifyContent":"left","paddingLeft":"5%"}}>Symbol</div>
                        <div className="col-2 p-3 tableHeader" style={{"justifyContent":"left"}}>Expiry</div>
                        <div className="col-2 p-3 tableHeader" style={{"justifyContent":"left"}}>LTP</div>
                        <div className="col-3 p-3 tableHeader" style={{"justifyContent":"right","paddingRight":"5%"}}>Change</div>
                    </div>
                </div>
                { isLoading?<LoadingSpinner/>:futuresAvailable ?availableFuturesData():notavailableFuturesData()}

            </React.Fragment>
        )
    }

    function getMarketDepthData(){
        dispatch(openMarketDepthChain())
        return(
            <div className="container mt-2" >
                <div className="row">
                    <div className="col-12">
                        <div className="headerBackground" style={{"width":"48%","display":"inline-block","marginRight":"1%"}}>
                            <div className="row">
                                <div className="col-4 mktHeader alignMktDepth">Orders</div>
                                <div className="col-4 mktHeader alignMktDepth">Qty</div>
                                <div className="col-4 mktHeader alignMktDepth">Bid</div>
                            </div>
                        </div>
                        <div className="headerBackground" style={{"width":"48%","display":"inline-block","marginLeft":"1%"}}>
                        <div className="row">
                                <div className="col-4 mktHeader alignMktDepth">Offer</div>
                                <div className="col-4 mktHeader alignMktDepth">Qty</div>
                                <div className="col-4 mktHeader alignMktDepth">Orders</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <MarketDepthData symbol={future_key}/>
                    </div>
                </div>
            </div>
        )
    }

    function getHighLow(){
        dispatch(openHighLowChain())
        return(
            <React.Fragment>
                <div className="container mt-2" style={{"paddingLeft":"4.5%","paddingRight":"4.5%"}}>
                    <div className="row headerBackground my-4">
                        <div className="col-2 p-3 tableHeader" style={{"justifyContent":"left","paddingLeft":"5%"}}>Period</div>
                        <div className="col-6 p-3 tableHeader" style={{"position":"relative"}}>
                            <div style={{"position":"absolute","left":"0px"}}>Low</div>
                            <div style={{"position":"absolute","right":"-49px"}}>High</div>
                        </div>
                        <div className="col-4 p-3 tableHeader" style={{"justifyContent":"right","paddingRight":"5%"}}>Returns</div>
                    </div>
                </div>
                {historicOHLC?<HighLow data={historicOHLC} odinToken={odinToken as string} mktId={marketSegmentId as number}/>:null}
            </React.Fragment>
        )

    }

    function toggleTab(tab:string){
        // for intail selection
        // const inital = currentTab === 'FutureChain' ? "future" : currentTab === 'MarketDepth' ? "market" : "highLow"
        const fut = document.getElementById("future")
        const mkt = document.getElementById("market")
        const hl = document.getElementById("highLow")
        if(tab==="future"){
            fut?.classList.replace("toggleButton","activetoggleButton");
            mkt?.classList.replace("activetoggleButton","toggleButton");
            hl?.classList.replace("activetoggleButton","toggleButton");
            setShowFutureChain(true)
            setShowMarketDepth(false)
            setShowHighLow(false)
        }
        if(tab==="market"){
            mkt?.classList.replace("toggleButton","activetoggleButton");
            fut?.classList.replace("activetoggleButton","toggleButton");
            hl?.classList.replace("activetoggleButton","toggleButton");
            setShowFutureChain(false)
            setShowMarketDepth(true)
            setShowHighLow(false)
        }
        if(tab==="highLow"){
            hl?.classList.replace("toggleButton","activetoggleButton");
            mkt?.classList.replace("activetoggleButton","toggleButton");
            fut?.classList.replace("activetoggleButton","toggleButton");
            setShowFutureChain(false)
            setShowMarketDepth(false)
            setShowHighLow(true)
        }
    }

  return (
    <Draggable
    handle=".draggable"
    cancel=".no-drag" 
    bounds="parent"
    allowAnyClick={true}
    >
        <div style={{zIndex:41}} className={`futureChain`}>
            <div className="draggable">
                <FutureHeader  data={future_key}/>
            </div>
            <div className="container py-2">
                <div className="row">
                    <div className="col-12">
                        <div className="toggleSection">
                            <button style={{opacity: !!currentTab ? '.7' : 1}} disabled={!!currentTab} className={`${currentTab === 'FutureChain' || currentTab === '' ? 'activetoggleButton': 'toggleButton'} mx-2 c-pointer`} onClick={()=>toggleTab("future")} id="future">Futures Chain</button>
                            <button style={{opacity: !!currentTab ? '.7' : 1}} disabled={!!currentTab} className={`${currentTab === 'MarketDepth' ? 'activetoggleButton': 'toggleButton'}  mx-2 c-pointer`} onClick={()=>toggleTab("market")} id="market">Market Depth</button>
                            <button style={{opacity: !!currentTab ? '.7' : 1}} disabled={!!currentTab} className={`${currentTab === 'HighAndLow' ? 'activetoggleButton': 'toggleButton'} toggleButton mx-2 c-pointer`} onClick={()=>toggleTab("highLow")} id="highLow">Highs & Lows</button>
                            {/* <div className="toggleButton mx-2 c-pointer">Futures Chains</div>
                            <div className="toggleButton mx-2 c-pointer">Futures Chains</div> */}
                        </div>
                    </div>
                </div>
            </div>

            {(showMarketDepth ||  currentTab === 'MarketDepth') && getMarketDepthData()}
            {showFutureChain && currentTab !== 'MarketDepth'  && getFutureChainTable()}
            {showHighLow && currentTab !== 'MarketDepth' && getHighLow()}
        </div>
    </Draggable>
  );
}

export default FutureChainModel;