/***
 * [This Component is Common For all the GTT Orders]
 *  */

import React, { useEffect } from "react";
import ToggleButton from "../common/GTTTrigger/ToggleGroup";
import {
  AssetClasses,
  GTTFormState,
  OptionTimeframe,
  OrderType,
  SecurityType,
  TradeDirection,
  TriggerMode,
} from "../gttTypes";

import "./gttbody.scss";
import "../../../../scss/_buttons.scss";

import GenericInput from "../common/GTTGenericInput";
import {
  GttOrderCreateTerms,
  transformGTTObjectToFormState,
} from "./gttHelper";
import { GTTBodyProps } from ".";
import { GTTOrder } from "../../../../models/gttOrders";
import useUpdateGttOrder from "../../../../hooks/api/put/updateGttAlert";
import { BrokerApi } from "../../../../chart-brokerapi/broker";
import { constructGTTUpdatePayload } from "../gtt.helper";
import { Controller, useForm } from "react-hook-form";
import { GTTFormData } from "./CreateGTT";
import { zodResolver } from "@hookform/resolvers/zod";
import { gttSchema } from "./gttSchema";

const limitAlertText: string =
  "Consider using market order instead of limit order to avoid non execution of order.";

export type GTTProbs = {
  setConditionalWidth: () => void;
  CloseGttModal: () => void;
  assetClass: AssetClasses;
  securityType: SecurityType;
  tradeDirection: TradeDirection;
  ltp: string;
  currentGTTTab: OptionTimeframe;
  tick: number;
  lotSize: number;
  isCondtionalWidthNeeded: boolean;
  targatedOrder: GTTOrder;
} & Pick<GTTBodyProps, "token" | "exchnage">;

const EditGTTComp: React.FC<GTTProbs> = ({
  tradeDirection,
  ltp,
  tick,
  lotSize,
  setConditionalWidth,
  targatedOrder,
  CloseGttModal,
}) => {
  const tickBy100 = React.useMemo(() => tick / 100, []);

  const { mutate: updateGttOrder, isLoading } = useUpdateGttOrder();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid, isSubmitting },
  } = useForm<GTTFormData>({
    resolver: zodResolver(gttSchema),
    mode: "onChange",
    defaultValues: {
      tickBy100: 0,
      dynamicLtp: "",
      currentGTTTab: "Intraday", // will be setted by useEffect
      tradeDirection: "Buy",
      triggresType: "OCO",

      stoplossTriggerPrice: "",
      stoplossQuantity: "",
      stoplossPrice: "",
      stopLossOrderType: "Limit",

      targetTriggerPrice: "",
      targetQuantity: "",
      targetPrice: "",
      targetLossOrderType: "Limit",
    },
  });

  // UseEffect to set intial data state
  useEffect(() => {
    if (targatedOrder && targatedOrder.orders) {
      const transformedState = transformGTTObjectToFormState(
        targatedOrder,
        tradeDirection,
        tickBy100
      );

      const isSingle = targatedOrder.orders.length === 1 ? "Single" : "OCO"; // is its Single or ICO

      if (isSingle === "Single") {
        setValue("stoplossTriggerPrice", transformedState.triggerPrice);
        setValue("stoplossPrice", transformedState.price);
        setValue('stoplossQuantity',transformedState.quantity)

      } else {
        if (transformedState.targetQuantity) {
          // Set Qunatites
          setValue("stoplossQuantity", transformedState.quantity);
          setValue("targetQuantity", transformedState.targetQuantity);

          // Set Prices
          setValue("stoplossPrice", transformedState.price);
          setValue("targetPrice", transformedState.targetPrice);

          // Set Trigger Prices
          setValue("stoplossTriggerPrice", transformedState.triggerPrice);
          setValue("targetTriggerPrice", transformedState.targetTriggerPrice);
          setValue("tradeDirection", tradeDirection);
        }
      }

      setValue(
        "triggresType",
        targatedOrder.orders.length === 1 ? "Single" : "OCO"
      );
      setValue("tradeDirection", tradeDirection);
      setValue("tickBy100", tickBy100);
    }
  }, [targatedOrder]);

  // Only update dynamicLtp when ltp changes
  useEffect(() => {
    setValue("dynamicLtp", ltp);
  }, [ltp]);

  useEffect(() => {
    trigger([
      "stoplossPrice",
      "targetPrice",
      "stoplossTriggerPrice",
      "targetTriggerPrice",
      "dynamicLtp",
      "triggresType",
      "tradeDirection",
      'tickBy100',
    ]);
  }, [
    watch("triggresType"),
    watch("stoplossPrice"),
    watch("targetPrice"),
    watch("targetTriggerPrice"),
    watch("stoplossTriggerPrice"),
    watch("tradeDirection"),
    watch('tickBy100'),
    trigger,
    ltp,
  ]);

  const onSubmit = async (data: GTTFormData) => {
    const formState: GTTFormState = {
      orderType: watch("stopLossOrderType"),
      price: watch("stoplossPrice"),
      quantity: watch("stoplossQuantity"),
      triggerPrice: watch("stoplossTriggerPrice"),
      triggerType: watch("triggresType"),
      targetOrderType: watch("targetLossOrderType"),
      targetPrice: watch("targetPrice"),
      targetQuantity: watch("targetQuantity"),
      targetTriggerPrice: watch("targetTriggerPrice"),
    };
    const updatePayload = constructGTTUpdatePayload({
      formState,
      targatedOrder,
      tradeDirection,
    });

    const result = await updateGttOrder(updatePayload, {
      onSuccess: (data) => {
        // console.log("Order updated successfully:", data);
      },
      onError: (errorMessage) => {
        // console.error("Error updating order:", errorMessage);
      },
    });

    if (result?.status === "success") {
      CloseGttModal();
      BrokerApi.instance?._host.showNotification(
        "GTT Order Updated successfully!",
        result.data?.data?.order_id,
        1
      );
    } else {
      BrokerApi.instance?._host.showNotification(
        "Failed to Update GTT Order",
        result?.message || "Unknown error occurred",
        0
      );
    }
  };

  const triggresType = watch("triggresType");
  const stoplossPrice = watch("stoplossPrice");
  const stoplossPriceOrderType = watch("stopLossOrderType");
  const targetLossOrderType = watch("targetLossOrderType");

  const isButtonDisabled =
    !isValid || isSubmitting || isLoading || Object.keys(errors).length > 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mx-4 my-5 justify-content-between">
        {/* <div className="d-flex justify-content-between align-items-center mb-5">
          <div style={{ fontSize: 20 }} className="text-color fw-bolder">
            Trigger type
          </div>

          <Controller
            name="triggresType"
            control={control}
            render={({ field }) => (
              <ToggleButton<TriggerMode>
                options={["Single", "OCO"]}
                selectedOption={field.value}
                onToggle={field.onChange}
              />
            )}
          />
        </div> */}
        <div
          className={`${
            triggresType === "OCO" ? "col-6" : "col-12"
          } text-start pe-4`}
        >
          {triggresType === "OCO" && (
            <div className="text-color fw-bolder">Stop Loss</div>
          )}

          <Controller
            name="stoplossTriggerPrice"
            control={control}
            render={({ field }) => (
              <GenericInput
                id="stoplossTriggerPrice"
                label="Trigger price"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                error={errors.stoplossTriggerPrice?.message}
                type="number"
              />
            )}
          />

          <div className="row">
            <Controller
              name="stoplossQuantity"
              control={control}
              render={({ field }) => (
                <GenericInput
                  id="stoplossQuantity"
                  label="Quantity"
                  containerClassName="col-6"
                  subLable={`1 lot = ${lotSize}`}
                  required
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  error={errors.stoplossQuantity?.message}
                  type="number"
                />
              )}
            />

            <div className="col-6">
              <Controller
                name="stoplossPrice"
                control={control}
                render={({ field }) => (
                  <GenericInput
                    label="Price"
                    id="stoplossPrice"
                    subLable={`Tick: ${tick / 100}`}
                    value={stoplossPrice === "Market" ? "Market" : field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={errors.stoplossPrice?.message}
                    disabled={stoplossPriceOrderType === "Market"}
                    type={
                      stoplossPriceOrderType === "Market" ? "text" : "number"
                    }
                  />
                )}
              />

              <div className="text-end mt-4">
                <Controller
                  name="stopLossOrderType"
                  control={control}
                  render={({ field }) => (
                    <ToggleButton<OrderType>
                      options={["Limit", "Market"]}
                      selectedOption={field.value}
                      onToggle={(value) => {
                        field.onChange(value); // Update the form field value
                        if (value === "Market") {
                          setValue("stoplossPrice", "Market");
                        } else {
                          const stoplossTriggerPrice = watch(
                            "stoplossTriggerPrice"
                          );
                          setValue("stoplossPrice", stoplossTriggerPrice || "");
                        }
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${
            triggresType === "OCO" ? "col-6" : "col-12"
          } text-start ps-4 border-start gtt-border-left`}
        >
          {triggresType === "OCO" && (
            <>
              <div className="text-color fw-bolder">Target</div>
              <Controller
                name="targetTriggerPrice"
                control={control}
                render={({ field }) => (
                  <GenericInput
                    id="targetTriggerPrice"
                    label="Trigger price"
                    value={field.value || ""}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={errors.targetTriggerPrice?.message}
                    type="number"
                  />
                )}
              />

              <div className="row">
                <Controller
                  name="targetQuantity"
                  control={control}
                  render={({ field }) => (
                    <GenericInput
                      id="targetQuantity"
                      required
                      label="Quantity"
                      containerClassName="col-6"
                      subLable={`1 lot = ${lotSize}`}
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.value)}
                      error={errors.targetQuantity?.message}
                      type="number"
                    />
                  )}
                />

                <div className="col-6">
                  <Controller
                    name="targetPrice"
                    control={control}
                    render={({ field }) => (
                      <GenericInput
                        label="Price"
                        subLable={`Tick: ${tick / 100}`}
                        value={
                          stoplossPrice === "Market"
                            ? "Market"
                            : field.value || ""
                        }
                        onChange={(e) => field.onChange(e.target.value)}
                        error={errors.stoplossPrice?.message}
                        disabled={targetLossOrderType === "Market"}
                        type={
                          targetLossOrderType === "Market" ? "text" : "number"
                        }
                      />
                    )}
                  />

                  <div className="text-end mt-4">
                    <Controller
                      name="targetLossOrderType"
                      control={control}
                      render={({ field }) => (
                        <ToggleButton<OrderType>
                          options={["Limit", "Market"]}
                          selectedOption={field.value}
                          onToggle={(value) => {
                            field.onChange(value); // Update the form field value
                            if (value === "Market") {
                              setValue("targetPrice", "Market");
                            } else {
                              const targetTriggerPrice =
                                watch("targetTriggerPrice");
                              setValue("targetPrice", targetTriggerPrice || "");
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div
          className="text-color mt-4"
          style={{ fontSize: 12, lineHeight: "16px", textAlign: "left" }}
        >
          {GttOrderCreateTerms}
          <a
            style={{ color: "#064AD3" }}
            target="_blank"
            href="https://support.rupeezy.in/support/solutions/articles/21000002688-gtt-orders"
          >
            Learn more about GTT
          </a>
        </div>
      </div>
      <div
        style={{ height: 60 }}
        className="px-5 alerts-create-edit-background d-flex justify-content-end gap-3 py-2"
      >
        <button
          type="submit"
          className={`btn-alert border-0 ${
            tradeDirection === "Buy"
              ? "btn tv-btn-success"
              : "btn tv-btn-danger"
          }`}
          style={{
            cursor: isButtonDisabled ? "not-allowed" : "pointer",
          }}
          disabled={isButtonDisabled}
        >
          {isSubmitting || isLoading ? "Updating..." : "Updating GTT"}
        </button>
      </div>
    </form>
  );
};

export default EditGTTComp;
