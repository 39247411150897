import React, {useEffect} from 'react';
import './optionStrategyModel.scss'
import Draggable from 'react-draggable';
import {useDispatch, useSelector} from "react-redux";
import StrategyTools from "./StrategyTools";
import StrategyStore from "./Store/StrategyStore";
import RenderOptionStrategiesList from "./Store/RenderOptionStrategiesList"
import {setRecentSearches,setCurrentMultilier,openOptionStrategy} from "../../slices/optionStrategySlice"
import StrategyDetails from "./Store/StrategyDetails"
import StrategyAnalyst from './Store/StrategyAnalyst';
import ExecutionScreen from './Store/ExecutionScreen';
import BuilderStrategyDetails from "./Builder/BuilderStrategyDetails"
import { RootState } from '../../store';

const OptionStategyModel=()=> {

    const dispatch = useDispatch();
    const {current_strategy_state,current_strategy,builder_input} = useSelector((state: RootState) => state.optionStrategy);

    //This will check the state and it will render accordingly
    useEffect(()=>{
            
    },[current_strategy_state])

    useEffect(()=>{
        //Open option strategy clevertap event
        dispatch(openOptionStrategy())
    },[])

    //This will dispatch the recent searches and if its empty then it will set in localstorage
    useEffect(()=>{
        const data:string[] = []
        const recentdata = JSON.parse(localStorage.getItem('recentSearches') as string);
        if(recentdata==null){
            localStorage.setItem('recentSearches', JSON.stringify(data));
            dispatch(setRecentSearches(data))
        }else{
            dispatch(setRecentSearches(recentdata))
        }

        return ()=>{
            dispatch(setCurrentMultilier(1))
        }
    },[])

    //this will decide to which screen will be shown 
    const currentView=()=>{
        if(current_strategy_state.id==1) return <StrategyTools />
        if(current_strategy_state.id==2) return <StrategyStore /> 
        if(current_strategy_state.id==3) return <RenderOptionStrategiesList/>
        if(current_strategy_state.id==4 && current_strategy) return <StrategyDetails />
        if(current_strategy_state.id==5) return <StrategyAnalyst/>
        if(current_strategy_state.id==6) return <ExecutionScreen/>
        if(current_strategy_state.id==7 && builder_input) return <BuilderStrategyDetails /> 
    }

    return (
        // <div className="dragerParent">
            <Draggable
                handle=".handle_b"
                cancel=".no-drag" 
                scale={1}
                bounds="parent"
                allowAnyClick={true}>
                    <div className='optionStrategyModel'>
                        {currentView()}
                    </div>
            </Draggable>
        // </div>
    );
}

export default OptionStategyModel;


