import * as React from 'react';
import { BrokerApi } from '../../chart-brokerapi/broker';
import Draggable from "react-draggable";
import { ReactComponent as CloserIcon } from "../../assets/images/close-square.svg";
import './index.scss';
import { ChartStateParams } from "../../models/orders"

interface ChargesState {
    isVisible: boolean;
    brokerage?: number;
    exchangeTransactionCharges?: number;
    gst?: number;
    dpCharges?: number;
    clearingCharges?: number;
    stt?: number;
    sebiCharges?: number;
    stampDuty?: number;
    turnover?: number;
    total?: number;
}

// interface Order {
//     qty: number;
//     lot_size: number;
//     market_type: number;
//     limitPrice: number;
//     ltp: number;
// }

export let ChargesInstance: Charges | null = null;

export class Charges extends React.Component<{}, ChargesState> {
    private ref: React.RefObject<HTMLDivElement>;
    constructor(props: {}) {
        super(props);
        this.state = this.defaultStateParams;
        this.ref = React.createRef();
        this.closeChargesDialog = this.closeChargesDialog.bind(this);
        ChargesInstance = this;
    }

    defaultStateParams: ChargesState = {
        isVisible: false,
    };

    closeChargesDialog() {
        this.setState(this.defaultStateParams);
    }

    showChargesDialog(order: ChartStateParams) {
        this._showChargesDialog(order);
    }

    _showChargesDialog(order: ChartStateParams) {
        BrokerApi.instance?.getBrokerage(order).then((result:any) => {
            const turnover =
                Number(order.qty) * Number(order.lot_size) * Number(order.market_type === 1 ? parseFloat(Number(order.limitPrice).toString()) : order.ltp);
            const total =
                (result.brokerage || 0) +
                (result.exchangeTransactionCharges || 0) +
                (result.gst || 0) +
                (result.dpCharges || 0) +
                (result.clearingCharges || 0) +
                (result.stt || 0) +
                (result.sebiCharges || 0) +
                (result.stampDuty || 0);

            this.setState({
                ...this.state,
                isVisible: true,
                ...result,
                turnover: turnover,
                total: total,
            });
        });
    }

    render() {
        if (!this.state.isVisible) {
            return <div ref={this.ref} className={'custom-order-dialog'}></div>;
        } else {
            return (
                <Draggable
                    // handle=".handle_b"
                    cancel=".no-drag" 
                    >
                    <div ref={this.ref} className="charges-container">
                        <div className="title">
                            <span>Charges Summary</span>
                            <div className="close" id="close-button">
                                <div onClick={this.closeChargesDialog} className="c-pointer no-drag" id="">
                                    <CloserIcon />
                                </div>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span>Exchange Turnover</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.turnover || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span>Brokerage</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.brokerage || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span>Exchange Transaction Charges</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.exchangeTransactionCharges || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span>Securities Transaction Tax(STT)</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.stt || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span>GST(on brokerage & exchange charges)</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.gst || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span>SEBI Charges</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.sebiCharges || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span>Clearing Charges</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.clearingCharges || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span>Stamp Duty</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.stampDuty || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span>DP Charges(inclusive of taxes)</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.dpCharges || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>

                        <div className="charges-row">
                            <span className="bold">Total tax & Charges</span>
                            <div className="charges-value">
                                <span>&#8377;</span>
                                <span>{Math.round((this.state.total || 0 + Number.EPSILON) * 10000) / 10000}</span>
                            </div>
                        </div>
                    </div>
                </Draggable>
            );
        }
    }
}
