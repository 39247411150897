import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { OptionTimeframe } from "../components/DashBoard/GTT/gttTypes";

export interface ChargesSummaryVisible {
  buySell: "SELL" | "BUY" ;
  exchange: string ;
  instrumentType: string ;
  price: number ;
  quantity: number ;
  symbol: string;
  tradeType: OptionTimeframe;
  isVisible: boolean;
}
//  state type
export interface OrdersState {
  chargesSummaryVisible: Partial<ChargesSummaryVisible>;
}

// Initial state with all fields as undefined
const initialState: OrdersState = {
  chargesSummaryVisible: {
    buySell: undefined,
    exchange: undefined,
    instrumentType: undefined,
    price: undefined,
    quantity: undefined,
    symbol: undefined,
    tradeType: undefined,
    // isVisible: undefined,
    isVisible: false
  },
};

const OrdersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    // Set the entire state
    setChargesSummaryVisible(state, action: PayloadAction<ChargesSummaryVisible>) {
        state.chargesSummaryVisible = action.payload;
      },
  
      // Reset only the chargesSummaryVisible part to initial state
      resetChargesSummaryVisible(state) {
        state.chargesSummaryVisible = initialState.chargesSummaryVisible;
      },
  },
});

export const { resetChargesSummaryVisible,setChargesSummaryVisible } = OrdersSlice.actions;

export default OrdersSlice.reducer;
