import Draggable from "react-draggable";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import { ReactComponent as CloserIcon } from "../../../../../assets/images/close-square2.svg";

interface CloseChargedSumaryModalProps {
  onClose: () => void; // Renamed to be more conventional
}

const ChargedSumary: React.FC<CloseChargedSumaryModalProps> = ({ onClose }) => {
  // Accessing the state from Redux
  const isSummaryWindowOpen = useSelector(
    (state: RootState) => state.orders.chargesSummaryVisible
  );

  // Check if the modal should be open based on the state
  const isModalOpen = Boolean(isSummaryWindowOpen); // Update as per your state logic

  if (!isModalOpen) {
    return null; // Return null if the modal shouldn't be shown
  }

  return (
    <Draggable handle=".handle_b" bounds="parent" allowAnyClick>
      <div
        style={{ maxWidth: "483px" }}
        className="custom-order-dialog_basket currentBg"
      >
        <div
          style={{ height: 80 }}
          className="container optionStartegyHead handle_b draggable-border"
        >
          <div className="font-18 fw-bold">Charge Summary</div>

          {/* Close Modal Button */}
          <div className="d-flex c-pointer no-drag" onClick={onClose}>
            <CloserIcon />
          </div>
        </div>
        <table className="mx-4 pt-2 w-full">
          <tr className="d-flex justify-content-between">
            <td className="flex-grow-1">Exchange Turnover</td>
            <td>Row 1, Col 2</td>
          </tr>
          <tr className="d-flex justify-content-between">
            <td className="flex-grow-1">Brokerage</td>
            <td>Row 2, Col 2</td>
          </tr>
          <tr className="d-flex justify-content-between">
            <td className="flex-grow-1">Exchange Transaction Charges</td>
            <td>Row 3, Col 2</td>
          </tr>
          <tr className="d-flex justify-content-between">
            <td className="flex-grow-1">Securities Transaction Tax(STT)</td>
            <td>Row 4, Col 2</td>
          </tr>
          <tr className="d-flex justify-content-between">
            <td className="flex-grow-1">
              GST(on brokerage & exchange charges)
            </td>
            <td>Row 5, Col 2</td>
          </tr>
          <tr className="d-flex justify-content-between">
            <td className="flex-grow-1">SEBI Charges</td>
            <td>Row 6, Col 2</td>
          </tr>
          <tr className="d-flex justify-content-between">
            <td className="flex-grow-1">Clearing Charges</td>
            <td>Row 7, Col 2</td>
          </tr>
          <tr className="d-flex justify-content-between">
            <td className="flex-grow-1">Stamp Duty</td>
            <td>Row 8, Col 2</td>
          </tr>
          <tr className="d-flex justify-content-between">
            <td className="flex-grow-1">DP Charges(inclusive of taxes)</td>
            <td>Row 9, Col 2</td>
          </tr>
        </table>
      </div>
    </Draggable>
  );
};

export default ChargedSumary;
