import { z } from "zod";

// Define Zod schema for form validation
export const alertFormSchema = z.object({
    searchQuery: z.string().min(1, "Search query is required"),
    alertName: z.string().min(1, "Alert name is required"),
    property: z.string().min(1, "Property is required"),
    condition: z.string().min(1, "Condition is required"),
    valueInr: z.string().refine((val) => !isNaN(Number(val)) && Number(val) > 0, {
      message: "Value must be a positive number",
    }),
    lastTradedPrice: z.string().refine((val) => !isNaN(Number(val)), {
      message: "Last traded price must be a number",
    }),
    note: z.string().optional(),
    volume: z.number().optional(), // Made optional since it might not always be present
    ltp: z.number().optional(), // Made optional since it might not always be present
  }).superRefine((data, ctx) => {
    // Early return if required values are not present
    if (!data.property || !data.condition || !data.valueInr) {
      return;
    }
  
    const isVolume = data.property === 'VOLUME';
    const baseValue = isVolume ? data.volume : data.ltp;
    const valueInr = Number(data.valueInr);
  
    // Check if baseValue exists
    if (baseValue === undefined || baseValue === null) {
    //   ctx.addIssue({
    //     path: ["valueInr"],
    //     code: z.ZodIssueCode.custom,
    //     message: `${isVolume ? "Volume" : "Last traded price"} is required for validation`,
    //   });
      return;
    }
  
    switch (data.condition) {
      case 'GE': // Greater than or equal to
        if (valueInr <= baseValue) {
          ctx.addIssue({
            path: ["valueInr"],
            code: z.ZodIssueCode.custom,
            message: `Value must be greater than ${baseValue}`,
          });
        }
        break;
  
      case 'LE': // Less than or equal to
        if (valueInr >= baseValue) {
          ctx.addIssue({
            path: ["valueInr"],
            code: z.ZodIssueCode.custom,
            message: `Value must be less than ${baseValue}`,
          });
        }
        break;
  
      case 'EQ': // Equal to
        if (valueInr < 0) {
          ctx.addIssue({
            path: ["valueInr"],
            code: z.ZodIssueCode.custom,
            message: `Value must be positive`,
          });
        }
        break;
    }
  });