import { propertyToStringForEdit } from "../helper/alert/condition";
import { conditionToString } from "../helper/alert/condition";

export const getPropertyOptions = (isnIsIndex: boolean) => {
  if (isnIsIndex) {
    return [
      { value: "", label: propertyToStringForEdit("") },
      { value: "LTP", label: propertyToStringForEdit("LTP") },
    ];
  }
  
  return [
    { value: "", label: propertyToStringForEdit("") },
    { value: "LTP", label: propertyToStringForEdit("LTP") },
    { value: "AVGPRICE", label: propertyToStringForEdit("AVGPRICE") },
    { value: "VOLUME", label: propertyToStringForEdit("VOLUME") },
  ];
};

export const getConditionOptions = (property: string) => {
  const baseOptions = [
    { value: "", label: "Select Condition" },
    { value: "GE", label: conditionToString("GE") },
  ];

  if (property !== "VOLUME") {
    baseOptions.push(
      { value: "EQ", label: conditionToString("EQ") },
      { value: "LE", label: conditionToString("LE") }
    );
  }

  return baseOptions;
};

export const conditionComparitor = (
  condition: string,
  valueInr: number,
  lp: number
): boolean => {
  switch (condition) {
    case "LE":
      return valueInr <= lp;
    case "GE":
      return valueInr >= lp;
    default:
      return false;
  }
};