import React from "react";
import ExchangePill from "../../../ExchangePill";
import { GTTProbs } from "../../GTT";
import { NumberColor } from "../../../common/number_color";
import ToggleButtonDirection, { ToggleButtonDirectionProps } from "../../GTT/ToggleButtonDirection";

type GttHeadersProps = Pick<
  GTTProbs,
   | "exchnage" | "ltp" | "ch" | "chp"
> & ToggleButtonDirectionProps & {
  securityDesc: string
  disabled?:boolean
  scriptName: string
}

const OrderOverviewHeader: React.FC<GttHeadersProps> = ({
  scriptName,
  exchnage,
  ltp,
  ch,
  chp,
  toggleTradeDirection,
  tradeDirection,
  securityDesc,
  disabled
}) => {
  return (
    <div className="flex-grow-1 justify-content-between d-flex text-color fw_700 text-black text-start align-items-center no-drag">
      <div>
      {scriptName}
      <ExchangePill className="mx-1" text={exchnage} />
      <div style={{fontSize:10}}>{securityDesc}</div>
      <div>
        {ltp} <NumberColor number={ch} numFix={true} />
        <NumberColor
          number={+Number(chp).toFixed(2)}
          numFix={true}
          percentageAdd={true}
          isFiniteCheck={true}
          returnBracket={true}
          percentageSymbol={true}
        />
      </div>
      </div>
      <ToggleButtonDirection disabled={disabled} toggleTradeDirection={toggleTradeDirection} tradeDirection={tradeDirection} />
    </div>
  );
};

export default OrderOverviewHeader;
