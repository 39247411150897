import React from "react";
import { ReactComponent as CloserIcon } from "../../assets/images/close-square2.svg";
import { useDispatch, useSelector } from "react-redux";
import { CloseFutureChain, resetCurentTab } from "../../slices/optionChainSlice";

interface HeaderProp {
  data: string;
}

const FutureHeader: React.FC<HeaderProp> = ({ data }) => {
  const dispatch = useDispatch();
  const onModelClose = () => {
    dispatch(CloseFutureChain());
    dispatch(resetCurentTab())
  };

  return (
    <div className="container futureHeader">
      <div className="row">
        <div className="col-11 headerSymbol">{data}</div>
        <div className="col-1" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CloserIcon onClick={onModelClose} className="c-pointer no-drag" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FutureHeader;
