import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector,shallowEqual} from "react-redux";
import OptionStrategyHeader from "../OptionStrategyHeader"
import {ReactComponent as AddFilterImage} from "../../../assets/images/filter-add.svg";
import flowBackEndServices2 from "../../../services/flowBackendServices";
import Buy from "../../../assets/icons/Buy.svg"
import moment from 'moment';
import Sell from "../../../assets/icons/Sell.svg"
import OptionsCombinations from "../Store/OptionsCombinations"
import HeaderSubscribedData from "../Store/HeaderSubscribedData"
import {ReactComponent as UpArrow} from "../../../assets/icons/arrow-square-up.svg"
import {ReactComponent as NoStrategy} from "../../../assets/icons/NoStrategy.svg"
import {ReactComponent as Neutral} from "../../../assets/icons/Neutral.svg"
import {ReactComponent as FundCircle} from "../../../assets/icons/fund-circle.svg"
import {ReactComponent as DownArrow} from "../../../assets/icons/arrow-square-down.svg"
import {optionBuilderAnalyse,setBuilderSortBy,setGlobalStrategyTypeFilters,setGlobalStrategyTypeList,setBuilderDetails,setBuilderStrategyList,setGlobalStrategyNameFilters,setCurrentExpiryDate,setPredictRange,setBuilderStrategyDetails,setStrategyState,setAnalyseData,setCurrentMultilier,setActiveIndexGlobal,setRequiredGlobalMargin,setGlobalFunds,setExecutableOptions,setKeepDefaultindex,setInitialGlobalMargin} from "../../../slices/optionStrategySlice";
import {DOCK_BASE_URL} from "../../../utils/config";
import {currentUser} from "../../../slices/authSlice";
import BuilderFilter from "./BuilderFilter";
import CustomSpinner from "../CustomSpinner"


const BuilderStrategyDetails = ({})=>{

    const dispatch = useDispatch()
    const {strategy_type_filters,builder_sort_by,strategy_name_filters,builder_details,current_strategy_state,current_symbol_details,predict_range,current_multiplier,active_index,required_margin,initial_margin,available_funds,keep_default_index,builder_input,expiryDates,builder_strategy_details,current_expiry_date} = useSelector((state) => state.optionStrategy);
    const user = useSelector((state => state.authUser.user),shallowEqual);
    const user_key = useSelector(currentUser);  
    const [currentStrategyDetails,setCurrentStrategyDetails]=useState(builder_strategy_details)
    const [originalDetails,setOriginalDetails]=useState(builder_details)
    const [currentMultiplier,setCurrentMultiplier]=useState(current_multiplier?current_multiplier:1)
    const [activeIndex, setActiveIndex] = useState(active_index);
    const [marginInputFormat,setMarginInputFormat]=useState(null)
    const [requiredMargin,setRequiredMargin]=useState(required_margin)
    const [initialMargin,setInitialMargin]=useState(initial_margin)
    const [availableFunds,setAvailableFunds]=useState(available_funds)
    const [predictType,setPredictType]=useState(builder_input.prediction.toLowerCase())
    const [predictedInputValueFirst,setPredictedInputValueFirst]=useState(predict_range[0])
    const [predictedInputValueSecond,setPredictedInputValueSecond]=useState(predict_range[1])
    const [builderInput,setBuilderInput]=useState(builder_input)
    const [currentExpiryDate1,setcurrentExpiryDate1]=useState(current_expiry_date)
    const [strategiesList,setStrategiesList]=useState(null)
    const [strategyTypeList,setStrategyTypeList]=useState(null)
    const [strategyNameFilters,setStrategyNameFilters]=useState(strategy_name_filters?strategy_name_filters:[])
    const [strategyTypeFilters,setStrategyTypeFilters]=useState([])
    const [showFilter,setShowFilter]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [sortBy,setSortBy]=useState(builder_sort_by)


    useEffect(()=>{
        setPredictedInputValueFirst(predict_range[0])
        setPredictedInputValueSecond(predict_range[1])
        if(builderInput && currentStrategyDetails==null) getTheBuilderStrategies()
    },[builderInput])

    useEffect(()=>{
        if(sortBy && currentStrategyDetails) sortTheStrategyData()
    },[sortBy,builder_sort_by])

    useEffect(()=>{
        const buttons = document.querySelectorAll('.predictType');

        buttons.forEach((button) => {
            button.classList.remove('active'); 
          });
        const element = document.getElementById(predictType);
        if(element){
            element.classList.add('active');
        }
    },[])


    useEffect(()=>{ 
        if((strategyNameFilters || strategyTypeFilters.length>0) && originalDetails ) filterStrategyList()
    },[strategyNameFilters,strategyTypeFilters])

    useEffect(()=>{
        if(currentStrategyDetails) {
            if(currentStrategyDetails.strategies.length>0){
                if(requiredMargin==null) createMarginInput(currentStrategyDetails.strategies[active_index]["legs"],0)
                if(availableFunds==null) getAvailableBalance()
                let temp={
                    "strategy_name":currentStrategyDetails.strategies[active_index]["strategyName"],
                    "options":currentStrategyDetails.strategies[active_index]["legs"]
                }
                dispatch(setExecutableOptions(temp))
            }
        }
    },[currentStrategyDetails])


    useEffect(()=>{
        if(keep_default_index){
            setActiveIndex(0)
            dispatch(setActiveIndexGlobal(0))
        }
    },[])

    useEffect(()=>{
        if(active_index && active_index!=0){
            let element = document.getElementById("bs-"+(active_index+1))
            if(element) element.scrollIntoView({behavior: 'instant',block: 'end',inline: "nearest"})
        }
    },[])

    useEffect(()=>{
        if(marginInputFormat) getTotalMargin()
    },[marginInputFormat])

    const getAllStrategiesList=(data)=>{
        let strategiesList=[]
        let strategyTypeList=[]
        data.strategies.map(d=>{
            strategiesList.push(d.strategy_name)
        })
        data.strategies.map(d=>{
            strategyTypeList.push(d.strategyType)
        })
        let uniqueArr = [...new Set(strategiesList)];
        let uniqueArr2 = [...new Set(strategyTypeList)];
        setStrategiesList(uniqueArr)
        setStrategyTypeList(uniqueArr2)
        dispatch(setBuilderStrategyList(uniqueArr))
        dispatch(setGlobalStrategyTypeList(uniqueArr2))
    }

    const addStrategyType=(data)=>{
        let finalData={}
        const newData=data.strategies.map(item => {
            return item.trading_opportunities.map(opp=>{
                if (item.strategy_name === "Bull Call Spread" || item.strategy_name === "Bull Put Spread") {
                    return {...opp, strategyType: "Bullish", strategy_name: item.strategy_name};
                  } else if (item.strategy_name === "Bear Call Spread" || item.strategy_name === "Bear Put Spread") {
                    return {...opp, strategyType: "Bearish",strategy_name: item.strategy_name};
                  } else if(item.strategy_name === "Long Strangle" || item.strategy_name === "Long Straddle" || item.strategy_name === "Ratio Call Spread" || item.strategy_name === "Ratio Put Spread") {
                      return {...opp, strategyType: "Others",strategy_name: item.strategy_name};
                  } else if(item.strategy_name === "Short Strangle" || item.strategy_name === "Short Straddle" || item.strategy_name === "Iron Condor" || item.strategy_name === "Iron Butterfly") {
                      return {...opp, strategyType: "Neutral",strategy_name: item.strategy_name};
                  }
            })
            
          });
          finalData["strategies"]=newData.flat()
          return finalData
    }

    

    const getTheBuilderStrategies = () =>{
        resetFilters()
        dispatch(setCurrentMultilier(1))
        setCurrentMultiplier(1)
        setIsLoading(true)
        flowBackEndServices2.getBuilderStrategies(builderInput,user_key.access_token).then(value => {
            return value.data
        }).then((data)=>{
            let newData=addStrategyType(data.data)
            dispatch(setBuilderStrategyDetails(newData))
            setCurrentStrategyDetails(newData)
            setOriginalDetails(newData)
            dispatch(setBuilderDetails(newData))
            getAllStrategiesList(newData)
            setIsLoading(false)
        }).catch(reason => {
            console.trace("Error in builder api data",reason);
            setIsLoading(false)
        })
    }
    const handleChange = (e) => {
        setcurrentExpiryDate1(e.target.value)
    }

    function formatdate(date){
        let now = moment(date, "YYYYMMDD").format('DD MMM YYYY');
        return now
    }

    const getLatestBuilderData=()=>{
        setRequiredMargin(null)
        let arr = []
        setCurrentStrategyDetails(null)

        if(predictType=="between"){
            arr.push(Number(predictedInputValueFirst))
            arr.push(Number(predictedInputValueSecond))
        }else{
            arr.push(Number(predictedInputValueFirst))
        }

        let data={
            "token": current_symbol_details.nToken,
            "symbol": current_symbol_details.sSymbol,
            "expiry_date": currentExpiryDate1.toString(),
            "prediction":predictType.toUpperCase(),
            "price_Range":arr
        }
        dispatch(setCurrentExpiryDate(currentExpiryDate1))
        setBuilderInput(data)
        dispatch(setPredictRange(arr))

    }

    const filterStrategyList=()=>{
        console.log("filtering")
        let currentList={}
        if((strategyNameFilters.length==0) && (strategyTypeFilters.length==0)){
            currentList = {...originalDetails}
            setCurrentStrategyDetails(currentList)
        }else if(strategyNameFilters.length>0 && strategyTypeFilters.length>0){
            currentList = {...originalDetails}
            let list={"strategies":[]}
            let arr = []
            let mergedArray=[]
            let finalArray=[]
            strategyNameFilters.map(d=>{
                const results = currentList.strategies.filter(obj => obj.strategy_name === d);
                mergedArray=[...mergedArray,...results]
            })
            strategyTypeFilters.map(d=>{
                const results = mergedArray.filter(obj => obj.strategyType === d);
                finalArray=[...finalArray,...results]
            })
            list.strategies=finalArray
            setCurrentStrategyDetails(list)
        }else if(strategyNameFilters.length>0 && strategyTypeFilters.length==0){
            currentList = {...originalDetails}
            let list={"strategies":[]}
            let arr = []
            let mergedArray=[]
            strategyNameFilters.map(d=>{
                const results = currentList.strategies.filter(obj => obj.strategy_name === d);
                mergedArray=[...mergedArray,...results]
            })
            list.strategies=mergedArray
            setCurrentStrategyDetails(list)
        }else if(strategyNameFilters.length==0 && strategyTypeFilters?.length>0){
            currentList = {...originalDetails}
            let list={"strategies":[]}
            let arr = []
            let mergedArray=[]
            strategyTypeFilters.map(d=>{
                const results = currentList.strategies.filter(obj => obj.strategyType === d);
                mergedArray=[...mergedArray,...results]
            })
            list.strategies=mergedArray
            setCurrentStrategyDetails(list)
        }
    }


    function getExpiryDates(){
            return(
                <form className="d-flex flex-row w-100 justify-content-start" style={{"marginTop":"9px"}}>
                    <div className={`selectDate`}>Exp: </div>
                    <div className='ml-3' style={{"width":"120px","height":"32px"}}>
                        <select className={`form-select form-select-sm `} onChange={handleChange} value={currentExpiryDate1}>
                        {
                            expiryDates.map(d => {
                                        return(
                                            <option key={d} value={d} className='option'>{formatdate(d)}</option>
                                        )
                                    })
                                }
                        </select>
                    </div>
                </form>
        )
    }
    const changePredictType=(event)=>{
        setPredictedInputValueFirst(predict_range[0])
        setPredictedInputValueSecond(predict_range[1])
        const buttons = document.querySelectorAll('.pred');

        buttons.forEach((button) => {
            button.classList.remove('active');
          });
        const element = document.getElementById(event.currentTarget.id);
        element.classList.add('active');
        setPredictType(event.currentTarget.id)
    }

    const getFormattedValue = (value) =>{
        const formattedValue = value.replace(/[^0-9.]/g, '');
        const decimalIndex = formattedValue.indexOf('.');
        
        if (decimalIndex !== -1) {
          const decimalPlaces = formattedValue.slice(decimalIndex + 1);
          if (decimalPlaces.length > 2) {
            // Limit to two decimal places
            return formattedValue.slice(0, decimalIndex + 3);
          }
        }

        return formattedValue
    }

    const handleInputChangeAB=(event)=>{
        const val = getFormattedValue(event.target.value)
        setPredictedInputValueFirst(val)
    }
    const handleInputChangeBetweenFirst=(event)=>{
        const val = getFormattedValue(event.target.value)
        setPredictedInputValueFirst(val)
    }
    const handleInputChangeBetweenSecond=(event)=>{
        const val = getFormattedValue(event.target.value)
        setPredictedInputValueSecond(val)
    }

    const getTopPredicted = () =>{
        return(
            <div className='outerBox'>
                <div style={{"display":"flex","flexDirection":"row","alignItems":"center"}}>
                    <div className='predict no-drag'>
                        <span className='pred c-pointer' style={{"borderWidth":"1px 0px 1px 1px","borderRadius":"6px 0px 0px 6px"}} onClick={changePredictType} id="above">Above</span>
                        <span className='pred c-pointer' style={{"borderWidth":"1px 1px 1px 1px"}} onClick={changePredictType} id="between">Between</span>
                        <span className='pred c-pointer' style={{"borderWidth":"1px 1px 1px 1px","borderRadius":"0px 6px 6px 0px"}} onClick={changePredictType} id="below">Below</span>
                    </div>
                    {predictType!="between" && (<div style={{"borderRight":"1px solid #E4EAF0","paddingRight":"13px","marginTop":"8px"}}>
                        <div style={{"paddingLeft":"20px"}}> 
                            <input className="secondPredicted" style={{"width":"100%","height":"32px"}} type="number" onChange={handleInputChangeAB} value={predictedInputValueFirst}/>
                        </div>
                    </div>)}
                    {predictType=="between" &&(
                        <React.Fragment>
                            <div style={{"marginTop":"8px"}}>
                                <div style={{"paddingLeft":"10px"}}>
                                    <input placeholder='lower' className="secondPredicted" style={{"width":"100%"}} onChange={handleInputChangeBetweenFirst} type="number" value={predictedInputValueFirst}/>
                                </div>
                            </div>
                            <div style={{"borderRight":"1px solid #E4EAF0","paddingRight":"13px","marginTop":"8px"}}>
                                <div style={{"paddingLeft":"5px"}}>
                                    <input placeholder='upper' className="secondPredicted" style={{"width":"100%"}} type="number" onChange={handleInputChangeBetweenSecond} value={predictedInputValueSecond}/>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div style={{"paddingLeft":"13px"}}>
                        {getExpiryDates()}
                    </div>
                    <div>
                        <button className={`submitButton c-pointer no-drag ${predictType=="between"?predictedInputValueFirst==""||predictedInputValueSecond==""?"":"enable":predictedInputValueFirst==""?"":"enable"}`} onClick={getLatestBuilderData}>Submit</button>
                    </div>
                </div>
            </div>
        )
    }

    const renderStrategyOpportunities=()=>{
        return currentStrategyDetails.strategies.map((data,index)=>{
            return (
                <div className='eachStrategy mb-2' id={"bs-"+index}>
                    <div className='nameQuantity'>
                        <div className='px-3 stratName'>{data.strategy_name}</div>
                        <div className='toggle_button c-pointer no-drag' onClick={() => handleItemClick(index,data,data.strategy_name)}>{activeIndex === index ?<UpArrow /> :<DownArrow />}</div>
                        <div className='type ml-3' style={{"color":`${data.strategyType=="Bullish"?"#26a69a":data.strategyType=="Bearish"?"#FE4141":data.strategyType=="Neutral"?"#064AD3":"#ff8800"}`,
                        "background":`${data.strategyType=="Bullish"?"#E3FEEF":data.strategyType=="Bearish"?"#FFEDED":data.strategyType=="Neutral"?"#DDE8FF":"#FFF4EB"}`}}>
                            {data.strategyType=="Bullish" && (<div style={{"color":"#26a69a"}}><i className="fa-solid fa-caret-up"></i></div>)}
                            {data.strategyType=="Bearish" && (<div style={{"color":"#FE4141"}}><i className="fa-solid fa-caret-down"></i></div>)}
                            {data.strategyType=="Neutral" && (<div><Neutral/></div>)}
                            <div>{data.strategyType}</div>
                        </div>
                        {activeIndex === index && (
                        <div className='multiplier'>
                            <div className='multText'>Multiplier</div>
                            <div className='addSubtractBox ml-4 c-pointer no-drag' onClick={subtractMultiplier}><i className="fa-solid fa-minus"></i></div>
                            <div className='addSubtractBox' style={{"background":"#FFFFFF","width":"32px"}}>{currentMultiplier}</div>
                            <div className='addSubtractBox no-drag c-pointer' onClick={addMultiplier}><i className="fa-solid fa-plus"></i></div>
                        </div>
                        )}
                    </div>
                        <div className='container'>
                            <div className='row my-3'>
                                <div className='col-6' style={{"borderRight":"0.5px solid #D3D3D3"}}>
                                    <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                                        <div style={{"textAlign":"left"}}>
                                            <div className='detailshead'>Max Profit</div>
                                            <div className='profitValue'>{data.is_infinite_profit?"Unlimited":((data.max_profit).toFixed(2))*(activeIndex === index?currentMultiplier:1)}</div>
                                        </div>
                                        <div style={{"textAlign":"center"}}>
                                            <div className='detailshead'>Breakevens</div>
                                            <div className='breakevensValue'>{checkBreakEven(data.breakeven)}</div>
                                        </div>
                                        <div style={{"textAlign":"right"}}>
                                            <div className='detailshead'>Max Loss</div>
                                            <div className='lossValue'>{data.is_infinite_loss?'Unlimited':((data.max_loss).toFixed(2))*(activeIndex === index?currentMultiplier:1)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                {showOptionsCombinations(data,index,data.strategy_name,activeIndex === index)}
                                </div>
                            </div>
                        </div>
                    {activeIndex === index && (
                        <React.Fragment>
                        {initialMargin*currentMultiplier>availableFunds && (<div className='addFunds pl-3'>
                            <div style={{"alignSelf":"center"}}><FundCircle /></div>
                            <div className='fundWarning' style={{"alignSelf":"center"}}>Add more funds to execute this strategy</div>
                            <div className='addFundButton text-nowrap c-pointer no-drag' style={{"alignSelf":"center"}} onClick={openDock}>+Add funds</div>
                        </div>)}
                     <div className='margin pt-4'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-6 p-0 p-0'>
                                    <div className='details'>
                                        <div className='pl-3'>
                                            <div className='detailshead'>Initial</div>
                                            <div className='ltp_change' style={{"textAlign":"right"}}>{initialMargin?(Number(initialMargin)*currentMultiplier).toFixed(2):0}</div>
                                        </div>
                                        <div style={{"paddingLeft":"20%"}}>
                                            <div className='detailshead'>Required</div>
                                            <div className='ltp_change' style={{"textAlign":"left"}}>{requiredMargin?(Number(requiredMargin)*currentMultiplier).toFixed(2):0}</div>
                                        </div>
                                        <div style={{"paddingLeft":"20%"}}>
                                            <div className='detailshead'>Available</div>
                                            <div className='ltp_change' style={{"textAlign":"left"}}>{availableFunds?availableFunds:0}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='Buttons'>
                                        <div className='analyse mr-1 c-pointer no-drag' onClick={()=>openAnalyse(data)}>
                                            Analyse
                                        </div>
                                        <div className='continue ml-1 c-pointer no-drag' onClick={openExecution}>
                                            Continue
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                     </React.Fragment>
                    )}
                </div>
        )
        })
    }

    const addMultiplier=()=>{
        let value=current_multiplier?current_multiplier:currentMultiplier
        value+=1
        setCurrentMultiplier(value)
        dispatch(setCurrentMultilier(value))
    }

    const subtractMultiplier=()=>{
        if(currentMultiplier>1){
            let value=current_multiplier?current_multiplier:currentMultiplier
            value-=1
            setCurrentMultiplier(value)
            dispatch(setCurrentMultilier(value))
        }
    }


    const showOptionsCombinations=(data,index,strategyName,bool)=>{
            return data.legs.map(option=>{
                return(
                    <OptionsCombinations options={option} currentindex={index} currentMultiplier={currentMultiplier} strategyName={strategyName} bool={bool}/>
                )
            })
    }

    const openAnalyse = (analyseData) =>{
        let data = {id:5,url:'builderAnalyse'}
        dispatch(setAnalyseData(analyseData))
        dispatch(setStrategyState(data))
        dispatch(optionBuilderAnalyse())
    }

    const checkBreakEven=(breakevens)=>{
        if(Array.isArray(breakevens)){
            const modifiedArray = breakevens.map((element) => {
                return (element).toFixed(2);
              });
            return modifiedArray.join(',')
        }else{
            (breakevens).toFixed(2)
        }
    }

    const openDock = () =>{
        let doc_url_pay_in = `${DOCK_BASE_URL}dashboard?path=pay-in&sso_auth_token=${user.ssoToken}&version=v2`
        window.open(doc_url_pay_in, '_blank')
    }

    const createMarginInput = (legs,index) =>{
        let input = []
        let elements=document.getElementsByClassName(currentStrategyDetails.strategyName+(index+1))
        const values = [];
        for(var i=0;i<elements.length;i++)
            {
                values.push(Number(elements[i].textContent))
            }
        legs.map((d,index)=>{
            let data = {
                "transaction_type": d.action,
                "exchange": d.option.exchange,
                "variety": "RL",
                "price": values[index],
                "product": "DELIVERY",
                "quantity": d.quantity * currentMultiplier,
                "token":d.option.token
            }
            input.push(data)
        })
        setMarginInputFormat(input)
        
    }

    function getTotalMargin(){
        console.log("margin input",marginInputFormat)
        let marginInput = {"orders":marginInputFormat}
        flowBackEndServices2.getTotalMargin(marginInput,user_key.access_token).then(value => {
            return value.data
        }).then((data)=>{
            setRequiredMargin(data.required_margin)
            setInitialMargin(data.initial_margin)
            dispatch(setInitialGlobalMargin(data.initial_margin))
            dispatch(setRequiredGlobalMargin(data.required_margin))
        }).catch(reason => {
            console.log("Error in margin data",reason);
        })

    }

    function getAvailableBalance(){
        let funds = []
        flowBackEndServices2.getAvailableFunds(user_key.access_token).then(value => {
            return value.data
        }).then((data)=>{
            setAvailableFunds(data.nse.net_available)
            dispatch(setGlobalFunds(data.nse.net_available))
            return  funds
            
        }).catch(reason => {
            console.log("Error in margin data",reason);
        })


    }

    function handleItemClick(index,data,strategy_name) {
        if(activeIndex!=index){
            dispatch(setKeepDefaultindex(false))
            setCurrentMultiplier(1)
            dispatch(setCurrentMultilier(1))
            let temp={
                "strategy_name":strategy_name,
                "options":data.legs
            }
            createMarginInput(data.legs,index)
            dispatch(setExecutableOptions(temp))
            if(availableFunds){

            }else{
                getAvailableBalance()
            }
        }
        setActiveIndex(index === activeIndex ? null : index);
        dispatch(setActiveIndexGlobal(index === activeIndex ? null : index))
      }

      const openExecution = () =>{
        if(current_strategy_state.url=="builderStrategyDetails"){
            let data = {id:6,url:'builderExecution'}
            dispatch(setStrategyState(data))
        }else{
            let data = {id:6,url:'execution'}
            dispatch(setStrategyState(data))
        }
      }

      const openFilter=()=>{
        setShowFilter(true)
      }

      const closeFilter=()=>{
        setShowFilter(false)
      }

      //Filter logic written here

      const filterByStrategyName=(value,checked)=>{
        setActiveIndex(0)
        dispatch(setActiveIndexGlobal(0))
        let array=strategyNameFilters?strategyNameFilters:[]
        if(checked){
            let newArray=[...array,value]
            setStrategyNameFilters(newArray)
            dispatch(setGlobalStrategyNameFilters(newArray))
        }else{
            array =array.filter(item => item !== value);
            setStrategyNameFilters(array)
            dispatch(setGlobalStrategyNameFilters(array))
        }
      }

      const filterByStrategyType=(value,checked)=>{
        setActiveIndex(0)
        dispatch(setActiveIndexGlobal(0))
        let array=strategyTypeFilters?strategyTypeFilters:[]
        if(checked){
            let newArray=[...array,value]
            setStrategyTypeFilters(newArray)
            dispatch(setGlobalStrategyTypeFilters(newArray))
        }else{
            array =array.filter(item => item !== value);
            setStrategyTypeFilters(array)
            dispatch(setGlobalStrategyTypeFilters(array))
        }
      }

      const sortByProfit=(value)=>{
            setSortBy(value)
            dispatch(setBuilderSortBy(value))
      }

      const sortTheStrategyData=()=>{
            let sortedArray=[]
            let list = {"strategies":[]}
            if(sortBy=="maxProfit"){
                const infiniteProfit = currentStrategyDetails.strategies.filter(obj => obj.is_infinite_profit === true);
                const limitedProfit = currentStrategyDetails.strategies.filter(obj => obj.is_infinite_profit !== true);
                limitedProfit.sort((a, b) => b.max_profit - a.max_profit);
                sortedArray = [...infiniteProfit,...limitedProfit]
            }else{
                const infiniteProfit = currentStrategyDetails.strategies.filter(obj => obj.is_infinite_loss === true);
                const limitedProfit = currentStrategyDetails.strategies.filter(obj => obj.is_infinite_loss !== true);
                limitedProfit.sort((a, b) => b.max_loss- a.max_loss);
                sortedArray = [...infiniteProfit,...limitedProfit]
            }
            list.strategies=sortedArray
            setCurrentStrategyDetails(list)
      }

      const renderBlankPage =()=>{
        return(
            <div className='mt-4'>
                <NoStrategy/>
                <div className='mt-3 noStrategyHeader'>No option strategy available</div>
                <div className='noStrategyDesc'>Try changing search parameters</div>
            </div>
        )
      }

      const resetFilters=()=>{
        //Reset Strategy Name Filters
        if(strategy_name_filters){
            strategy_name_filters.map(d=>{
                const element = document.getElementById(d)
                if (element) {
                    element.checked = false;
                  }
            })
        }
        //Reset Strategy Type Filters
        if(strategy_type_filters){
            strategy_type_filters.map(d=>{
                const element = document.getElementById(d)
                if (element) {
                    element.checked = false;
                  }
            })
        }
        //Reset Sorting 
        const buttons = document.querySelectorAll('.sortButton');

        buttons.forEach((button) => {
            button.classList.remove('active');
          });
        setStrategyNameFilters([])
        setStrategyTypeFilters([])
        dispatch(setGlobalStrategyNameFilters([]))
        dispatch(setGlobalStrategyTypeFilters([]))
        dispatch(setBuilderSortBy(null))
        setCurrentStrategyDetails(originalDetails)
        dispatch(setBuilderStrategyDetails(originalDetails))
      }


    return(
        <React.Fragment>
            <OptionStrategyHeader>
                <HeaderSubscribedData token={current_symbol_details.nToken} segmentId={current_symbol_details.nMarketSegmentId} symbol={current_symbol_details.sSymbol} description={current_symbol_details.sSecurityDesc!=""?current_symbol_details.sSecurityDesc:current_symbol_details.sSymbol+" Index"} exchange={current_symbol_details.sExchange}/>
                <div className='c-pointer no-drag no-drag' style={{"position":"absolute","right":"80px"}} onClick={openFilter}><AddFilterImage /></div>
            </OptionStrategyHeader>
            {isLoading && (
               <CustomSpinner />
            )}
            {(<div style={{"paddingLeft":"30px","paddingRight":"30px","marginTop":"20px"}}>
                {getTopPredicted()}
            </div>)}
            {!isLoading && currentStrategyDetails && currentStrategyDetails.strategies.length>0 &&(<div className='builderTradingOpportunies'>
                {renderStrategyOpportunities()}
            </div>)}
            {!isLoading && currentStrategyDetails && currentStrategyDetails.strategies.length==0  && (<div className='builderTradingOpportunies'>
                {renderBlankPage()}
            </div>)}
            {!isLoading && showFilter && (<div className='filterWindow'>
                <BuilderFilter strategiesList={strategiesList} strategyTypeList={strategyTypeList} filterByStrategyName={filterByStrategyName} closeFilter={closeFilter} filterByStrategyType={filterByStrategyType} sortProfit={sortByProfit} resetFilters={resetFilters}/>
            </div> )}
        </React.Fragment>
    )
}

export default BuilderStrategyDetails