import { GTTOrder } from "../../../models/gttOrders";

export type MarketType = "NSE" | "BSE";
export type OptionCategory = "CALL" | "PUT";
export type OptionTimeframe =
  | "Intraday" // INTRADAY
  | "Caryforward" // same | DELIVERY
  | "Pay Later" // MTF
  | "Intraweek" // BTST
  | "Delivery"; // same | DELIVERY
  
export type TradeDirection = "Buy" | "Sell";

export type HasStopLoss = boolean;
export type OrderValidity = "DAY" | "IOC";
export type OrderType = "Limit" | "Market";
export type OrderTrigger = "Cover" | "GTT";

export type TriggerMode = "Single" | "OCO";

export type AssetClasses = "Stocks" | "Index";
export type SecurityType = "Direct" | "Future" | "Options";

export interface GTTFormState {
  // Trigger details
  triggerType: TriggerMode;
  triggerPrice: string;
  quantity: string;
  price: string;
  orderType: OrderType;

  // Target details (for ODC)
  targetTriggerPrice?: string;
  targetQuantity?: string;
  targetPrice?: string;
  targetOrderType?: OrderType;
}

export enum OrderExchangeType {
  NSE_EQ = "NSE_EQ",
  NSE_FO = "NSE_FO",
  MCX_FO = "MCX_FO",
  NCDEX_FO = "NCDEX_FO",
  NSE_COMM = "NSE_COMM",
  NSE_CUR = "NSE_CUR",
  NSE_OTS = "NSE_OTS",
  BSE_EQ = "BSE_EQ",
  BSE_FO = "BSE_FO",
}


export interface StopLossProfitOrder {
  quantity: number;
  price: number;
  trigger_price: number;
  variety: "RL" | "RL-MKT";
}

export interface GTTOrderPayload {
  exchange: string;
  token: number;
  transaction_type: "BUY" | "SELL";
  product: "INTRADAY" | "DELIVERY" | "MTF" | "BTST";
  gtt_trigger_type: "single" | "oco";
  price: number;
  trigger_price: number;
  quantity: number;
  variety: "RL" | "RL-MKT" | "";
  stoploss?: StopLossProfitOrder | null;
  profit?: StopLossProfitOrder | null;
  tag_ids: string[];
}

export interface GTTOrderResponse {
  status: string;
  message?: string;
  data?: {
    order_id: string;
  };
}

export interface ConstructPayloadParams {
  formState: GTTFormState;
  targatedOrder: GTTOrder;
  tradeDirection?: TradeDirection;
}