import { z } from "zod";
import { isDivisor } from "./gttHelper";

export const gttSchema = z
  .object({
    // Sending LTP so that we can add validations
    tickBy100: z.number(),
    dynamicLtp: z.string(), // we will take f
    tradeDirection: z.enum(["Buy", "Sell"]),
    currentGTTTab: z.enum([
      "Intraday",
      "Caryforward",
      "Pay Later",
      "Intraweek",
      "Delivery",
    ]),
    triggresType: z.enum(["Single", "OCO"]),

    stoplossQuantity: z
      .string()
      .min(1, "Stoploss quantity is required")
      .refine((val) => !isNaN(Number(val)) && Number(val) > 0, {
        message: "Please enter a valid positive number for stoploss quantity",
      }),
    stoplossPrice: z.string().min(1, "Stoploss price is required"),
    stoplossTriggerPrice: z
      .string()
      .min(1, "Stoploss trigger price is required")
      .refine((val) => !isNaN(Number(val)) && Number(val) > 0, {
        message:
          "Please enter a valid positive number for stoploss trigger price",
      }),
    stopLossOrderType: z.enum(["Limit", "Market"]),

    targetQuantity: z
      .string()
      .optional()
      .refine((val) => !val || (!isNaN(Number(val)) && Number(val) > 0), {
        message: "Please enter a valid positive number for target quantity",
      }),
    targetPrice: z.string().optional(),
    targetTriggerPrice: z
      .string()
      .optional()
      .refine((val) => !val || (!isNaN(Number(val)) && Number(val) > 0), {
        message:
          "Please enter a valid positive number for target trigger price",
      }),
    targetLossOrderType: z.enum(["Limit", "Market"]),
  })
  .superRefine((data, ctx) => {
    const buy = data.tradeDirection === "Buy"; // its Buy or Sell

    if (buy) {
      // if (isDivisor(data.stoplossPrice, data.tickBy100)) {
      //   ctx.addIssue({
      //     path: ["stoplossPrice"], // Path specifies the field that has the issue
      //     code: z.ZodIssueCode.custom,
      //     message: `Stoploss price should be ${
      //       buy ? "greater" : "less"
      //     } than LTP (${data.dynamicLtp})`,
      //   });
      // }

      if (+data.stoplossTriggerPrice < +data.dynamicLtp) {
        ctx.addIssue({
          path: ["stoplossTriggerPrice"], // Path specifies the field that has the issue
          code: z.ZodIssueCode.custom,
          message: `Stoploss trigger price should be ${
            !buy ? "greater" : "less"
          }  than LTP (${data.dynamicLtp})`,
        });
      }

      if (
        data.targetTriggerPrice &&
        +data.targetTriggerPrice > +data.dynamicLtp
      ) {
        ctx.addIssue({
          path: ["targetTriggerPrice"], // Path specifies the field that has the issue
          code: z.ZodIssueCode.custom,
          message: `Target trigger price should be ${
            !buy ? "greater" : "less"
          }  than LTP (${data.dynamicLtp})`,
        });
      }
    }
    if (!buy) {
      // if (+data.dynamicLtp < +data.stoplossPrice) {
      //   ctx.addIssue({
      //     path: ["stoplossPrice"], // Path specifies the field that has the issue
      //     code: z.ZodIssueCode.custom,
      //     message: `Stoploss price should be ${
      //       buy ? "greater" : "less"
      //     } than LTP (${data.dynamicLtp})`,
      //   });
      // }

      if (
        data.targetTriggerPrice &&
        +data.stoplossTriggerPrice > +data.dynamicLtp
      ) {
        ctx.addIssue({
          path: ["stoplossTriggerPrice"], // Path specifies the field that has the issue
          code: z.ZodIssueCode.custom,
          message: `Target trigger price should be ${
            buy ? "greater" : "less"
          }  than LTP (${data.dynamicLtp})`,
        });
      }

      if (
        data.targetTriggerPrice &&
        +data.targetTriggerPrice < +data.dynamicLtp
      ) {
        ctx.addIssue({
          path: ["targetTriggerPrice"], // Path specifies the field that has the issue
          code: z.ZodIssueCode.custom,
          message: `Target trigger price should be ${
            !buy ? "greater" : "less"
          }  than LTP (${data.dynamicLtp})`,
        });
      }
    }

    // if stoplossTriggerPrice is present
    // if (data.triggresType === 'OCO' && !data.targetTriggerPrice) {
    //   ctx.addIssue({
    //     path: ["targetTriggerPrice"], // Path specifies the field that has the issue
    //     code: z.ZodIssueCode.custom,
    //     message: `Target trigger price is required when stoploss trigger price is present`,
    //   });
    // }

    if (data.triggresType === "OCO") {
      if (!data.targetTriggerPrice) {
        ctx.addIssue({
          path: ["targetTriggerPrice"], // Path specifies the field that has the issue
          code: z.ZodIssueCode.custom,
          message: `Target trigger price is required when stoploss trigger price is present`,
        });
      }

      if (!data.targetQuantity) {
        ctx.addIssue({
          path: ["targetQuantity"], // Path specifies the field that has the issue
          code: z.ZodIssueCode.custom,
          message: `Target quantity is required when stoploss trigger price is present`,
        });
      }

      if (!data.targetPrice) {
        ctx.addIssue({
          path: ["targetPrice"], // Path specifies the field that has the issue
          code: z.ZodIssueCode.custom,
          message: `Target price is required when stoploss trigger price is present`,
        });
      }
    }

    if (!data.stoplossQuantity) {
      ctx.addIssue({
        path: ["stoplossQuantity"], // Path specifies the field that has the issue
        code: z.ZodIssueCode.custom,
        message: `Stoploss quantity is required `,
      });
    }

    if (!data.stoplossPrice) {
      ctx.addIssue({
        path: ["stoplossPrice"], // Path specifies the field that has the issue
        code: z.ZodIssueCode.custom,
        message: `Stoploss stoplossPrice is required `,
      });
    }

    

    if (!isDivisor(data.stoplossPrice, data.tickBy100)) {
      ctx.addIssue({
        path: ["stoplossPrice"], // Path specifies the field that has the issue
        code: z.ZodIssueCode.custom,
        message: `Stoploss must be divisible by ${data.tickBy100}`,
      });
    }

    if (
      data.targetPrice &&
      data.tickBy100 &&
      !isDivisor(data.targetPrice, data.tickBy100)
    ) {
      ctx.addIssue({
        path: ["targetPrice"], // Path specifies the field that has the issue
        code: z.ZodIssueCode.custom,
        message: `Target price must be divisible by ${data.tickBy100}`,
      });
    }
  });
