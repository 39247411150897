import React from "react";
import { Dropdown } from "react-bootstrap";
import "./all-alert.scss";
import type { AlertsType } from "../AlertTools";

interface AllAlertsProps {
  currentSelectedAlerts: AlertsType;
  toggleAlertStatus: (status: AlertsType) => void; // To Handel the Alert List Which we need to show
  activeNumberOfAlerts: number; // The number of active alerts
  inactiveAlerts: number; // The number of inactive alerts
}

const AllAlerts: React.FC<AllAlertsProps> = ({
  currentSelectedAlerts,
  toggleAlertStatus,
  activeNumberOfAlerts,
  inactiveAlerts,
}) => {
  const totalnumberOfAlerts = activeNumberOfAlerts + inactiveAlerts;
  return (
    <Dropdown id='alert-dropdown' className="custom-dropdown no-drag">
      <Dropdown.Toggle id="dropdown-basic" className="custom-toggle p-0 m-0">
        {currentSelectedAlerts === "all"
          ? `All Alerts (${totalnumberOfAlerts})`
          : currentSelectedAlerts === "active"
          ? `Active Alerts (${activeNumberOfAlerts})`
          : `Triggered Alerts (${inactiveAlerts})`}
      </Dropdown.Toggle>

      <Dropdown.Menu className="custom-menu p-0 overflow-hidden">
        <Dropdown.Item
          onClick={() => toggleAlertStatus("all")}
          style={{ cursor: totalnumberOfAlerts ? "pointer" : "not-allowed", opacity: totalnumberOfAlerts ? 1 : 0.7 }}
          className="custom-item"
        >
          All Alerts ({totalnumberOfAlerts})
        </Dropdown.Item>
        <Dropdown.Item
          active
          onClick={() => toggleAlertStatus("active")}
          style={{ cursor: activeNumberOfAlerts ? "pointer" : "not-allowed", opacity: activeNumberOfAlerts ? 1 : 0.7 }}
          className="custom-item"
        >
          Active Alerts ({activeNumberOfAlerts})
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => toggleAlertStatus("inactive")}
          style={{ cursor: inactiveAlerts ? "pointer" : "not-allowed", opacity: inactiveAlerts ? 1 : 0.7 }}
          className="custom-item"
        >
          Triggered Alerts ({inactiveAlerts})
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AllAlerts;
