import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type CurrentTab = "FutureChain" | 'MarketDepth' | 'HighAndLow' | '';

// Define the types for the state
interface OptionChainState {
    model_open: boolean;
    new_model_open: boolean;
    open_future_chain: boolean;
    socket_open: boolean;
    future_key: string | null;
    option_key: string | null;
    sport_data: any; // Type it based on the structure of the sport_data
    maxVolumePE: number;
    maxVolumeCE: number;
    currentTab: CurrentTab;
}

const initialState: OptionChainState = {
    model_open: false,
    new_model_open: false,
    open_future_chain: false,
    socket_open: false,
    future_key: null,
    option_key: null,
    sport_data: null,
    maxVolumePE: 0,
    maxVolumeCE: 0,
    currentTab: ""
};

const optionChainSlice = createSlice({
    name: "optionChain",
    initialState,
    reducers: {
        OpenOptionModel: (state, action: PayloadAction<{ model_open: boolean; option_key: string | null }>) => {
            state.model_open = action.payload.model_open;
            state.option_key = action.payload.option_key;
        },
        OpenNewOptionModel: (state, action: PayloadAction<{ new_model_open: boolean; option_key: string | null }>) => {
            state.new_model_open = action.payload.new_model_open;
            state.option_key = action.payload.option_key;
            state.open_future_chain = false;
            state.future_key = null;
        },
        OpenFutureChain: (state, action: PayloadAction<{ open_future_chain: boolean; future_key: string | null }>) => {
            state.open_future_chain = action.payload.open_future_chain;
            state.future_key = action.payload.future_key;
            state.model_open = false;
            state.new_model_open = false;
            state.option_key = null;
            state.sport_data = null;
            state.maxVolumePE = 0;
            state.maxVolumeCE = 0;
        },
        CloseFutureChain: (state) => {
            state.open_future_chain = false;
            state.future_key = null;
        },
        CloseOptionModel: (state) => {
            state.model_open = false;
            state.new_model_open = false;
            state.option_key = null;
            state.sport_data = null;
            state.maxVolumePE = 0;
            state.maxVolumeCE = 0;
        },
        setSportData: (state, action: PayloadAction<any>) => {
            state.sport_data = action.payload;
        },
        onSocketChange: (state, action: PayloadAction<boolean>) => {
            state.socket_open = action.payload;
        },
        openOptionChain(state) {
            window.GtmPusher({
                event: 'tv-option-chain-opened',
            });
        },
        openFutureChain(state) {
            window.GtmPusher({
                event: 'tv-future-chain-opened',
            });
        },
        openHighLowChain(state) {
            window.GtmPusher({
                event: 'tv-high-low-opened',
            });
        },
        openMarketDepthChain(state) {
            window.GtmPusher({
                event: 'tv-market-depth-opened',
            });
        },
        setCurrentTab(state, action: PayloadAction<CurrentTab>) {
            state.currentTab = action.payload;
        },
        resetCurentTab(state) {
            state.currentTab = '';
        }
    },
});

// Export the selectors and actions
export const MaxVolume = (state: { optionChain: OptionChainState }) => state.optionChain;
export const { 
    OpenOptionModel, 
    OpenNewOptionModel, 
    OpenFutureChain, 
    CloseFutureChain, 
    CloseOptionModel, 
    setSportData, 
    onSocketChange, 
    openOptionChain, 
    openFutureChain, 
    openHighLowChain, 
    openMarketDepthChain,
    setCurrentTab,
    resetCurentTab
} = optionChainSlice.actions;

export default optionChainSlice.reducer;
