import React, {useEffect} from 'react';
import './basketModel.scss'
import Draggable from 'react-draggable';
import BasketFetch from "./basketFetch";
import {shallowEqual,useDispatch, useSelector} from "react-redux";
import {setCurrentBasketState} from "../../slices/basketOrderSlice";
import {BasketState} from "../../models/basketOrders";



const BasketModel=() => {
    const open_public_basket = useSelector(((state:any) => state.basketOrder.open_public_basket), shallowEqual);
    let state:BasketState
    const dispatch = useDispatch();
    if(open_public_basket){
        state =  {id: 4,param: null,url:'sharedBasketDetails'};
    }else{
        state = {id: 1,param: null,url:'basketFetch'};
    }

    useEffect(() => {
        dispatch(setCurrentBasketState(state))
        return () => {
            dispatch(setCurrentBasketState(state))
        }
    }, [])


    return (
        // <div className="dragerParent">
            <Draggable
                handle=".handle_b"
                cancel=".no-drag" 
                scale={1}
                bounds="parent"
                allowAnyClick={true}>
                <div className="custom-order-dialog_basket currentBg handle_b ">
                    <div className="my_box">
                        <BasketFetch/>
                    </div>
                </div>
            </Draggable>
        // </div>
    );
}

export default BasketModel;


