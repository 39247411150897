import { BrokerApi } from '../chart-brokerapi/broker';
import { DataFeedInstance } from '../chart-datafeed/udf-compatible-datafeed';
import { CustomWatchlist,CreateWatchlistPayload,SymbolStockOrder } from "../models/watchlists"
import { TokenInfo } from "../models/symbolData"
export class ChartWatchlists {
  private _watchlists: { [key: string]: CustomWatchlist };
  private _chartTvApi: any;

  constructor(chartTvApi: any) {
    this._watchlists = {};
    this._chartTvApi = chartTvApi; 
  }

  updateWatchlist(listId: string): void {
    if (this._watchlists[listId].readonly) {
      window.alert("Changes made to default watchlist will not be stored to server");
      return;
    }
    
    const list = this._chartTvApi.getAllLists()[listId];
    console.error(list);
    
    this._watchlists[listId].symbols = list;
    
    DataFeedInstance?.getTokensFromSymbol(list.symbols).then((result: any[]) => {
      return result.map((symbol) => {
        return {
          scripToken: parseInt(symbol.token),
          marketSegmentId: parseInt(symbol.market_segment_id),
        };
      });
    }).then((payload_symbols: SymbolStockOrder[]) => {
      if (this._watchlists[listId].id === undefined) {
        let payload:CreateWatchlistPayload={
          watchlistName:list.title,
          stocksOrder:payload_symbols
        }
        BrokerApi.instance?.createWatchlist(payload);
      } else {
        let payload:CreateWatchlistPayload={
          watchlistName:list.title,
          stocksOrder:payload_symbols
        }
        BrokerApi.instance?.updateWatchlist(Number(this._watchlists[listId].id), payload);
      }
    });
  }

  addWatchlist(watchlist: CustomWatchlist): void {
    const list = this._chartTvApi.createList(watchlist.name, watchlist.symbols);
    this._watchlists[list.id] = watchlist;

    if (watchlist.wlPosition === 0) {
      this._chartTvApi.setActiveList(list.id);
    }
  }

  renameWatchlist(listId: string,oldName:string, newName: string): void {
    const list = this._chartTvApi.getList(listId);
    this._watchlists[listId].symbols = list;
    if (this._watchlists[listId].readonly) {
      window.alert("Changes made to default watchlist will not be stored to server");
      return;
    }
    DataFeedInstance?.getTokensFromSymbol(list).then((result: any[]) => {
      return result.map((symbol) => {
        return {
          scripToken: parseInt(symbol.token),
          marketSegmentId: parseInt(symbol.market_segment_id),
        };
      });
    }).then((payload_symbols: SymbolStockOrder[]) => {
        this._watchlists[listId].name = newName;
        BrokerApi.instance?.updateWatchlist(Number(this._watchlists[listId].id), { watchlistName: newName, stocksOrder: payload_symbols });
    });
  }

  deleteWatchlist(listId: string): void {
    if (this._watchlists[listId].readonly) {
      window.alert("Changes made to default watchlist will not be stored to server");
      return;
    }
    BrokerApi.instance?.deleteWatchlist(Number(this._watchlists[listId].id));
    delete this._watchlists[listId];
  }
   
  createWatchlist(listId: string, symbols: string[], name: string): void {
    const list = this._chartTvApi.getAllLists()[listId];
    this._watchlists[listId] = {};
    
    const chartTvApi = this._chartTvApi;
    const watchlists = this._watchlists;
     
    const interval = setInterval(function () {
      if (chartTvApi.getAllLists()[listId] !== undefined) {
        if (watchlists[listId] === undefined || Object.keys(watchlists[listId]).length === 0) {
          const list = chartTvApi.getAllLists()[listId];
          watchlists[listId] = { symbols: [] };

          DataFeedInstance?.getTokensFromSymbol(list.symbols).then((result: any[]) => {
            return result.map((symbol) => {
              return {
                scripToken: parseInt(symbol.token),
                marketSegmentId: parseInt(symbol.market_segment_id),
              };
            });
          }).then((payload_symbols: SymbolStockOrder[]) => {
            BrokerApi.instance?.createWatchlist({ watchlistName: list.title, stocksOrder: payload_symbols }).then((response: any) => {
              watchlists[listId].id = response.response.ID;
            });
          });
        }
        clearInterval(interval);
      }
    }, 200);
  }
}
