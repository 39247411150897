import "./skeleton.scss"; // Ensure you have this SCSS file

interface SkeletonProps {
  className?: string;
  style?: React.CSSProperties;
}

const ReactangleSleton: React.FC<SkeletonProps> = ({
  className = "",
  style,
}) => {
  return <div className={`skeleton ${className}`} style={style}></div>;
};

export default ReactangleSleton;
